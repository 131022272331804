import { useState, useRef, useEffect } from "react";
import style from "./RecommendedEX.module.scss";
import { useTranslation } from "react-i18next";
import { getToday } from "utils/common";
import { ReactComponent as DownSVG } from "assets/icon/ic_nav_arrow_down.svg";
import { ReactComponent as UpSVG } from "assets/icon/ic_nav_arrow_up.svg";
import { stringKeyReplace } from "utils/common";

interface Props {
  data?: any;
  onClick: (url: string) => void;
  isOpen?: boolean;
  children: any;
  liHeight?: number;
  liLength?: number;
}

const RecommendedEX = ({
  data,
  isOpen = true,
  children,
  liHeight = 80,
  liLength = 0,
}: Props) => {
  const { t } = useTranslation();
  const ulRef = useRef<HTMLUListElement>(null);
  const [open, setOpen] = useState(true);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const openheight = (children && liHeight * (liLength + 1)) || 0;
    setHeight(openheight);
  }, [children, data]);

  const handleOpen = () => {
    if (isOpen) {
      setOpen(!open);
      ulRef!.current!.style!.transition = "all 0.3s";
    }
  };

  return (
    <div className={style.recommendedEX}>
      <div className={style.exCate}>
        <div className={style.cateText}>
          <h4 className={style.title}>
            {data?.libraryGroup === 5
              ? t("Studio_Library_Group_Name_01_0")
              : data?.libraryGroup === 6
              ? t("Category_Name_9_0")
              : stringKeyReplace(
                  t("Mobile_Exercise_RecommendationExercise_0"),
                  [t(data?.bodypart)]
                )}
          </h4>
          <p className={style.date} onClick={handleOpen}>
            {t("Mobile_Exercise_RecommendationDate_0")}
            {data?.date ? (
              <span>{getToday(data?.date)}</span>
            ) : (
              <span>YYYY-MM-DD</span>
            )}
            {isOpen ? open ? <UpSVG /> : <DownSVG /> : <></>}
          </p>
        </div>
      </div>
      <ul
        className={style.exList}
        ref={ulRef}
        style={
          isOpen
            ? open
              ? {
                  maxHeight: `${height}px`,
                  height: `auto`,
                  overflow: "overflow: visible ",
                }
              : {
                  maxHeight: `0px`,
                  height: `0px`,
                  overflow: "hidden",
                  padding: "0",
                }
            : {}
        }
      >
        {children}
      </ul>
    </div>
  );
};

export default RecommendedEX;
