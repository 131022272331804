import style from "./DeviationCard.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  data?: MusculResponseType;
}

const DeviationCard = ({ data }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={style.deviationCard}>
      {data ? (
        <>
          <p className={style.icon}>
            <img src={data.icon} alt="  the author" />
          </p>
          <div className={`${style.descArea} `}>
            <h3 className={style.title}>
              {t(data?.title)}
              <span className={style.deviation}>
                {t(data.value?.toString())}
              </span>
            </h3>
            <p className={style.text}>
              {data?.subtitle?.map((text: string, index: number) => (
                <span key={`${style.title}${index}`}>
                  {t(text)}
                  <br />
                </span>
              ))}
            </p>
          </div>
        </>
      ) : (
        <>
          <p className={`${style.icon} loadingBack`}></p>
          <div className={style.descArea}>
            <h3 className={style.title}>
              <span className={`${style.text} loadingBack`}></span>
              <span className={`${style.deviation} loadingBack`}></span>
            </h3>
            <p className={`${style.text} loadingBack`}></p>
          </div>
        </>
      )}
    </div>
  );
};

export default DeviationCard;
