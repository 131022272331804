import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { handleNaitve } from "../../utils/native";
import { languageList } from "../../datas/common";

const ko = require("./ko.json");
const en = require("./en.json");
const zh = require("./ch.json");
const jp = require("./jp.json");

const resource = {
  ko: {
    translation: ko,
  },
  en: {
    translation: en,
  },
  zh: {
    translation: zh,
  },
  jp: {
    translation: jp,
  },
};

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const urlLanguage = urlParams.get("language");
const localLanguage = localStorage.getItem("language");
const userBrowserLang = navigator.language;

let lang = urlLanguage || localLanguage || userBrowserLang || "ko";
lang = lang.toLowerCase();

// let settingLang = languageList.filter((langs) => {
//   if (lang.includes(langs.key)) return langs;
// })[0];
// settingLang = settingLang?.key || "ko";

let settingLang;

if (lang.includes("ko")) {
  settingLang = "ko";
} else if (lang.includes("ja") || lang.includes("jp")) {
  settingLang = "jp";
} else {
  settingLang = "en";
}

localStorage.setItem("language", settingLang);
handleNaitve(`{"cmd":"browserLanguage", "value":"${settingLang.toString()}"}`);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: resource,
    lng: settingLang,
    fallbackLng: settingLang,
    // debug : true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
