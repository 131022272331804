import style from "./AlertModal.module.scss";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "context/store";
import ModalLayout from "../ModalLayout";

const AlertModal = () => {
  const { t } = useTranslation();
  const { alert, setAlert } = useGlobalContext();

  const handleClose = () => {
    setAlert({
      open: false,
      title: "",
      text: "",
      onCheck: () => {},
    });
  };

  return (
    <ModalLayout
      handleClose={handleClose}
      title={alert.title}
      handleSubmit={() => {}}
      type="alert"
    >
      <div className={style.alertModal}>
        <p>{t(alert.text)}</p>
      </div>
    </ModalLayout>
  );
};

export default AlertModal;
