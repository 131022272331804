import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import style from "./Step1.module.scss";
import { useGlobalContext } from "context/store";
import { stringKeyReplace } from "utils/common";
import { useTranslation } from "react-i18next";
import { ReactComponent as UserSvg2 } from "assets/icon/ic_user_verified_lined.svg";
import DefaultBtn from "components/btns/defaultBtn/DefaultBtn";
import PrevBtn from "components/btns/prevBtn/PrevBtn";
import Sms from "components/sms/Sms";
import JoinNavigator from "components/nav/JoinNavigator";
import { useQuery } from "@tanstack/react-query";
import { restfetcher, QueryKeys, getClient } from "utils/queryClient";
import { setErrorCode } from "utils/error";
import { defaultPassData } from "datas/defaultdata";

const Step1 = () => {
  const queryClient = getClient();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { passUserInfo, setPassUserInfo } = useGlobalContext();
  const [isUser, setIsUser] = useState(false);
  const [matchState, setMatchState] = useState(false);

  const isjoinApi = useQuery({
    queryKey: [QueryKeys.ISJOIN],
    queryFn: () =>
      restfetcher({
        method: "POST",
        path: "api/user/isjoin",
        params: {
          authidx: passUserInfo.authidx,
          authkey: passUserInfo.authkey,
          usrphoneno: passUserInfo.usrphoneno,
          countryno: passUserInfo.countryno,
        },
      }),
  });

  useEffect(() => {
    if (passUserInfo?.joindate !== "" && passUserInfo?.authidx !== "") {
      setIsUser(true);
    }
  }, [passUserInfo]);

  useEffect(() => {
    const { isSuccess, data } = isjoinApi;
    if (isSuccess) {
      if (data?.result === "success") {
        const { userInfo } = data;
        if (userInfo?.joindate && userInfo?.usremail) {
          setPassUserInfo({
            ...userInfo,
            usremail: userInfo?.usremail,
          });
        } else {
          navigate("/join/step2");
        }
      } else {
        alert(t(setErrorCode(data?.errcode).toString()));
        setMatchState(false);
        setPassUserInfo(defaultPassData);
      }
      queryClient.removeQueries({ queryKey: [QueryKeys.ISJOIN] });
    }
  }, [isjoinApi.isSuccess, isjoinApi.data]);

  return (
    <div className={style.step1}>
      {isUser ? (
        <section className={style.step1_2}>
          <p className={style.icon}>
            <UserSvg2 />
          </p>
          <p className={style.info}>
            {stringKeyReplace(t("Mobile_SignIn_FindEmail_Pop_Text_1_0"), [
              passUserInfo?.usrname,
              passUserInfo?.joindate,
              passUserInfo?.usremail,
            ])}
          </p>
          <p className={style.btn}>
            <Link to={"/login"}>
              <DefaultBtn text="Mobile_SignIn_SignIn_Button_0" />
            </Link>
          </p>
          <p className={style.btn}>
            <PrevBtn path="/login" />
          </p>
        </section>
      ) : (
        <section className={style.step1_1}>
          <Sms setMatchState={setMatchState} matchState={matchState} />
          <JoinNavigator
            onNext={() => {
              isjoinApi.refetch();
            }}
            nextAction={matchState}
          />
        </section>
      )}
      {/* <SmsModal/> */}
    </div>
  );
};

export default Step1;
