import InfiniteScroll from "react-infinite-scroll-component";

interface Props {
  datas: any[];
  setPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  refetch: () => void;
  rowChildren: (data: any) => any; //JSX.Element;
}

const InfiniteList = ({ datas, setPage, page, rowChildren }: Props) => {
  return (
    <InfiniteScroll
      dataLength={datas.length} //This is important field to render the next data
      next={() => {
        setPage(page + 1);
      }}
      hasMore={true}
      loader={<></>}
    >
      {datas?.map((data: any, index) => (
        <div key={`infinite_${index}`}>{data && rowChildren(data)}</div>
      ))}
    </InfiniteScroll>
  );
};

export default InfiniteList;
