import { useLayoutEffect } from "react";
import style from "./Report.module.scss";
import Header from "components/header/Header";

interface Props {
  reportfileList: string[];
  handleClose: (() => void) | ((select: DefaultKeyValue) => void) | any;
}

const View = ({ reportfileList, handleClose }: Props) => {
  const headerType = localStorage.getItem("headerType");

  useLayoutEffect(() => {
    const viewport = document.getElementById("viewport");
    if (viewport) {
      viewport.setAttribute("content", "width=device-width, initial-scale=1");
    }

    return () => {
      if (viewport) {
        viewport.setAttribute(
          "content",
          "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        );
      }
    };
  }, []);

  return (
    <div
      className={`${style.view} openUp`}
      style={{ paddingTop: `${headerType === "60px" ? "101px" : "61px"}` }}
    >
      <Header
        title={"Mobile_Report_Page_Title_0"}
        isClose={true}
        onClick={handleClose}
        select={undefined}
        isBack={false}
      />
      <div
        className={style.viewArea}
        style={{ height: `calc(100vh - ${headerType})` }}
      >
        {reportfileList?.map((url, index) => (
          <img src={url} alt="report" key={`report_${index}`} />
        ))}
      </div>
    </div>
  );
};

export default View;
