import style from "./SectionTitle.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  title: string;
  children: React.ReactNode;
}
const SectionTitle = ({ title, children }: Props) => {
  const { t } = useTranslation();
  return (
    <h3 className={style.sectionTitle}>
      <p className={style.icon}>{children}</p>
      {t(title)}
    </h3>
  );
};

export default SectionTitle;
