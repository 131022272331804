import ModalLayout from "../ModalLayout";
import style from "./SmsModal.module.scss";
import Sms from "components/sms/Sms";

interface Props {
  rightBtnLabel: string;
  setMatchState: React.Dispatch<React.SetStateAction<boolean>>;
  matchState: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  type?: string;
}

const SmsModal = ({
  setMatchState,
  matchState,
  handleClose,
  handleSubmit,
  type,
}: Props) => {
  return (
    <ModalLayout
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      rightBtnLabel="Common_Button_Next_0"
      rightAction={matchState}
      title="Mobile_Join_PhoneVerification_0"
    >
      {
        <div className={style.smsModal}>
          <Sms
            setMatchState={setMatchState}
            type={type}
            parentStyle={{
              backgroundColor: "rgba(0,0,0,0)",
              height: "36px",
            }}
            matchState={matchState}
          />
        </div>
      }
    </ModalLayout>
  );
};

export default SmsModal;
