import { useLocation } from "react-router-dom";
import style from "./Step.module.scss";
import { useTranslation } from "react-i18next";

const stepArray = [
  {
    key: "step1",
    value: "Mobile_Join_PhoneVerification_0",
    text: "Mobile_Join_IdentityVerification_Text_0",
  },
  {
    key: "step2",
    value: "Mobile_Join_Terms_Title_0",
    text: "Mobile_Join_Terms_Text_0",
  },
  {
    key: "step3",
    value: "Mobile_Join_UserInfo_Title_0",
    text: "Mobile_Join_UserInfo_Text_0",
  },
  {
    key: "step4",
    value: "Mobile_Join_Complete_Title_0",
    text: "Mobile_Join_Complete_Text_0",
  },
];

const Step = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const stepTitle =
    stepArray.filter((data) => pathname.includes(data.key))[0] || stepArray[0];

  return (
    <div className={style.step}>
      <h1>{t("Mobile_Join_Page_Title_0")}</h1>
      <ul className={style.barArea}>
        {stepArray.map((data) => (
          <li
            key={data.key}
            className={
              stepTitle.key === data.key
                ? `${style.bar} ${style.on}`
                : style.bar
            }
          ></li>
        ))}
      </ul>
      {/* <p className={style.stpe}><span>{stepTitle.key.replace("step", "stpe ")}</span><b>{stepTitle.value}</b></p> */}
      <p
        className={style.stpe}
        dangerouslySetInnerHTML={{ __html: t(stepTitle.value) }}
      ></p>
      <p className={style.text}>{t(stepTitle.text)}</p>
    </div>
  );
};

export default Step;
