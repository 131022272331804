import style from "./Footer.module.scss";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <>
      <footer className={style.footer}>
        <p className={style.number}>
          {t("Mobile_Common_CS_Title_0")} {t("Mobile_Common_CS_Number_0")}
        </p>
        <p className={style.time}>{t("Mobile_Common_CS_OperatingTime_0")}</p>
        <p className={style.copyright}>{t("Mobile_Common_Footer_Text_0")}</p>
      </footer>
    </>
  );
};

export default Footer;
