import style from "./Home.module.scss";
import { ReactComponent as LogoExbodySvg } from "assets/icon/logo_exbody.svg";
import EquipmentInfo from "components/equipmentInfo/EquipmentInfo";
import { useTranslation } from "react-i18next";

interface Props {
  isLoading: boolean;
  datas?: null | any;
}

const NoData = ({ isLoading, datas }: Props) => {
  const { t } = useTranslation();

  if (isLoading || !datas) {
    return (
      <div className={style.nodata}>
        <section className={style.section1}>
          <article>
            <h1>
              <LogoExbodySvg />
            </h1>
            <h2>
              <p className={`${style.item1} loadingBack`}></p>
              <p className={`${style.item2} loadingBack`}></p>
            </h2>
          </article>
          <div className={style.infoArea}>
            <h3 className={`${style.sectionTitle} loadingBack `}></h3>
            <p className={`${style.sectionDesce} loadingBack `}></p>
            <div className={style.imageArea}>
              <p className={`${style.item} loadingBack`}></p>
              <p className={`${style.item} loadingBack`}></p>
              <p className={`${style.item} loadingBack`}></p>
            </div>
          </div>
        </section>
        <section className={style.section2}>
          <h3 className={`${style.sectionTitle} loadingBack `}></h3>
          <p className={`${style.sectionDesce} loadingBack `}></p>
          <div className={style.imageArea}>
            <p className={`${style.item} loadingBack`}></p>
          </div>
        </section>
        <section className={style.section3}>
          <h3 className={`${style.sectionTitle} loadingBack `}></h3>
          <p className={`${style.sectionDesce} loadingBack `}></p>
          <div className={style.equipmentArea}>
            <EquipmentInfo data={null} />
            <EquipmentInfo data={null} />
            <EquipmentInfo data={null} />
            <EquipmentInfo data={null} />
          </div>
        </section>
        <section className={style.section4}>
          <h3 className={`${style.sectionTitle} loadingBack `}></h3>
          <p className={`${style.sectionDesce} loadingBack `}></p>
          <div className={style.imageArea}>
            <p className={`${style.item} loadingBack`}></p>
            <p className={`${style.item} loadingBack`}></p>
            <p className={`${style.item} loadingBack`}></p>
          </div>
        </section>
      </div>
    );
  }

  return (
    <div className={style.nodata}>
      <section
        className={style.section1}
        style={{ backgroundImage: `url(${datas?.mainTitleImg})` }}
      >
        <article>
          <h1>
            <LogoExbodySvg />
          </h1>
          <h2>
            <pre>{t(datas?.mainTitle)}</pre>
          </h2>
        </article>
        <div className={style.infoArea}>
          <h3 className={`${style.sectionTitle} `}>
            {t(datas?.musculResponse?.[0]?.mainTitle)}
          </h3>
          <p className={`${style.sectionDesce} `}>
            {t(datas?.musculResponse?.[0]?.subTitle)}
          </p>
          <div className={style.imageArea}>
            {datas?.musculResponse?.[0]?.measureType?.map(
              (data: any, index: number) => (
                <p className={style.item} key={`musculResponse1_${index}`}>
                  <img src={data?.img} alt="" loading="lazy" />
                </p>
              )
            )}
          </div>
        </div>
      </section>
      <section className={style.section2}>
        <h3 className={`${style.sectionTitle} `}>
          {t(datas?.musculResponse?.[1]?.mainTitle)}
        </h3>
        <p className={`${style.sectionDesce} `}>
          {t(datas?.musculResponse?.[1]?.subTitle)}
        </p>
        <div className={style.imageArea}>
          {datas?.musculResponse?.[1]?.measureType?.map(
            (data: any, index: number) => (
              <p className={style.item} key={`musculResponse2_${index}`}>
                <img src={data?.img} alt="" loading="lazy" />
              </p>
            )
          )}
        </div>
      </section>
      <section className={style.section3}>
        <h3 className={`${style.sectionTitle} `}>
          {t(datas?.measureResponse?.[0]?.mainTitle)}
        </h3>
        <p className={`${style.sectionDesce} `}>
          {t(datas?.measureResponse?.[0]?.subTitle)}
        </p>
        <div className={style.equipmentArea}>
          {datas?.measureResponse?.[0]?.measureType?.map(
            (data: any, index: number) => (
              <div key={index}>
                {t(data.title) !== "RMT" &&
                  t(data.title) !== "GA" &&
                  t(data.title) !== "AF" && (
                    <EquipmentInfo data={data} key={`${t(data.title)}_icon`} />
                  )}
              </div>
            )
          )}
        </div>
      </section>
      <section className={style.section4}>
        <h3 className={`${style.sectionTitle} `}>
          <pre>{t(datas?.recommendResponse?.[0]?.mainTitle)}</pre>
        </h3>
        <p className={`${style.sectionDesce} `}>
          {t(datas?.recommendResponse?.[0]?.subTitle)}
        </p>
        <div className={style.imageArea}>
          {datas?.recommendResponse?.[0]?.measureType?.map((data: any) => (
            <p className={`${style.item}`} key={`${data.title}_img`}>
              <img src={data?.img} alt="" loading="lazy" />
              <span className={style.imgTitle}>{t(data.title)}</span>
            </p>
          ))}
        </div>
      </section>
    </div>
  );
};

export default NoData;
