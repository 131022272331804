import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useGlobalContext } from "context/store";
import DefaultBtn from "components/btns/defaultBtn/DefaultBtn";
import { useTranslation } from "react-i18next";
import style from "./Navigator.module.scss";

interface Props {
  onPrev?: () => void;
  onNext: () => void;
  nextAction?: boolean;
}

const JoinNavigator = ({ onPrev, onNext, nextAction = true }: Props) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { passUserInfo } = useGlobalContext();
  const navigate = useNavigate();

  // 개발
  useEffect(() => {
    if (!passUserInfo.authidx && !pathname.includes("step1")) {
      alert(t("Mobile_Join_IdentityVerification_Err_Pop_2_0"));
      navigate("/join/step1");
    }
  }, [passUserInfo]);

  const handlePrev = () => {
    onPrev && onPrev();
    navigate(-1);
  };

  return (
    <p className={style.joinNavigator}>
      <DefaultBtn text="Mobile_Common_Back_Button_0" onClick={handlePrev} />
      <DefaultBtn
        text="Mobile_Common_Next_Button_0"
        onClick={() => nextAction && onNext()}
        activate={nextAction}
      />
    </p>
  );
};

export default JoinNavigator;
