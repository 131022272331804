import { useState, useEffect } from "react";
import style from "./Step2.module.scss";
import { useNavigate } from "react-router-dom";
import CheckInput from "components/inputs/checkInput/CheckInput";
import JoinNavigator from "components/nav/JoinNavigator";
import { selectDatasFunc } from "utils/common";
import { useGlobalContext } from "context/store";
import { defaultPassData } from "datas/defaultdata";
import { useTranslation } from "react-i18next";

import Terms1 from "components/terms/Terms1";
import Terms2 from "components/terms/Terms2";
import Terms3 from "components/terms/Terms3";
import Terms4 from "components/terms/Terms4";

import Terms1En from "components/terms/Terms1En";
import Terms2En from "components/terms/Terms2En";
import Terms3En from "components/terms/Terms3En";
import Terms4En from "components/terms/Terms4En";
import Terms1Jp from "components/terms/Terms1Jp";
import Terms3Jp from "components/terms/Terms3Jp";
import Terms4Jp from "components/terms/Terms4Jp";
import Terms2Jp from "components/terms/Terms2Jp";

interface Term {
  key: number;
  title: any;
  essential: boolean;
  info: any;
  infoEN: any;
  infoJP: any;
}

const termsData: Term[] = [
  {
    key: 2,
    title: "Mobile_Join_Terms_2_Title_0",
    essential: true,
    info: <Terms1 />,
    infoEN: <Terms1En />,
    infoJP: <Terms1Jp />,
  },
  {
    key: 3,
    title: "Mobile_Join_Terms_5_Title_0",
    essential: false,
    info: <Terms3 />,
    infoEN: <Terms3En />,
    infoJP: <Terms3Jp />,
  },
  {
    key: 4,
    title: "Mobile_Join_Terms_3_Title_0",
    essential: true,
    info: <Terms2 />,
    infoEN: <Terms2En />,
    infoJP: <Terms2Jp />,
  },
  {
    key: 5,
    title: "Mobile_Join_Terms_4_Title_0",
    essential: false,
    info: <Terms4 />,
    infoEN: <Terms4En />,
    infoJP: <Terms4Jp />,
  },
];

const Step2 = () => {
  const { t, i18n } = useTranslation();
  const allTems = [1, 2, 3, 4, 5];
  const navigate = useNavigate();
  const { setStroreTerms, setPassUserInfo } = useGlobalContext();
  const [terms, setTerms] = useState<number[]>([]);

  useEffect(() => {
    setStroreTerms(terms);
  }, [terms]);

  const handleAllCheckboxChange = (index: number) => {
    let selectData = allTems;
    if (index > 0) {
      selectData = selectDatasFunc(index, terms);
    }
    if (terms.length === allTems.length && index === 0) {
      selectData = [];
    }
    setTerms(selectData);
  };

  const handleNextBtn = () => {
    if (
      terms.includes(1) &&
      terms.includes(2) &&
      terms.includes(3) &&
      terms.includes(4)
    ) {
      navigate("/join/step3");
    } else {
      alert(t("Mobile_Join_Terms_Err_Pop_1_0"));
    }
  };

  const handlePrevBtn = () => {
    setPassUserInfo(defaultPassData);
  };

  return (
    <div className={style.step2}>
      <section className={style.section1}>
        <CheckInput
          checked={terms.length === allTems.length}
          onChange={() => handleAllCheckboxChange(0)}
        >
          <p className={style.label}>{t("Mobile_Join_Terms_CheckBox_All_0")}</p>
        </CheckInput>
        <p className={style.desc}>{t("Mobile_Join_Terms_0_Description_0")}</p>
      </section>
      <section className={style.section2}>
        <CheckInput
          checked={terms.includes(1)}
          onChange={() => handleAllCheckboxChange(1)}
        >
          <p
            className={style.label}
            dangerouslySetInnerHTML={{
              __html: t("Mobile_Join_Terms_1_Title_0"),
            }}
          ></p>
        </CheckInput>
      </section>
      {termsData.map((data) => (
        <section key={data.key}>
          <p
            className={style.label}
            dangerouslySetInnerHTML={{ __html: t(data.title) }}
          ></p>
          <div className={style.info}>
            {i18n.language === "en"
              ? data.infoEN
              : i18n.language === "jp"
              ? data.infoJP
              : data.info}
          </div>
          <CheckInput
            checked={terms.includes(data.key)}
            onChange={() => handleAllCheckboxChange(data.key)}
          >
            <p className={style.label}>
              {t("Mobile_Join_Terms_CheckBox_Text_1_0")}
            </p>
          </CheckInput>
        </section>
      ))}
      <JoinNavigator onNext={handleNextBtn} onPrev={handlePrevBtn} />
    </div>
  );
};

export default Step2;
