import { useState, useEffect } from "react";
import style from "./MyPage.module.scss";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import { handleNaitve } from "utils/native";
import { restfetcher, QueryKeys, getClient } from "utils/queryClient";
import { birthFunc, getGender } from "utils/common";
import { useGlobalContext } from "context/store";
import toast, { Toaster } from "react-hot-toast";
import { languageList } from "datas/common";
import { onlyNumberFunc, validateBirth, validatename } from "utils/validate";
import { ReactComponent as LogoutSVG } from "assets/icon/log-out.svg";

//component
import DefaultBtn from "components/btns/defaultBtn/DefaultBtn";
import ChangeEmailModal from "components/modal/changeEmailModal/ChangeEmailModal";
import ChangePWModal from "components/modal/changePWModal/ChangePWModal";
import ChangeNicknameModal from "components/modal/changeNicknameModal/ChangeNicknameModal";
import SecessionModal from "components/modal/secessionModal/SecessionModal";
import Header from "components/header/Header";
import OnoffBtn from "components/btns/onoffBtn/OnoffBtn";
import Footer from "components/footer/Footer";
import Select from "components/selects/Select";
import ChangeInputModal from "components/modal/changeInputModal/ChangeInputModal";
import ChangeGenderModal from "components/modal/changeGenderModal/ChangeGenderModal";
import SmsModal from "components/modal/smsModal/SmsModal";
import PushOnoffBtn from "components/btns/onoffBtn/PushOnoffBtn";

const MyPage = () => {
  const queryClient = getClient();

  const { t, i18n } = useTranslation();
  const headerType = localStorage.getItem("headerType");
  const navigate = useNavigate();
  const token =
    typeof sessionStorage !== "undefined"
      ? sessionStorage?.getItem("token")
      : null;
  const { passUserInfo, userInfo, setUserInfo, fcmPermission } =
    useGlobalContext();

  const [openModal, setOpenModal] = useState({
    usremail: false,
    pw: false,
    usrnickname: false,
    secession: false,
  });
  const [autoLogin, setAutoLogin] = useState<null | boolean>(null);

  const [selectLanguage, setSelectLanguage] = useState<DefaultKeyValue>(
    languageList[0]
  );
  const [changeGenderModal, setChangeGenderModal] = useState(false);
  const [inputChangeModal, setInputChangeModal] = useState({
    open: false,
    label: "",
    changeLabel: "",
    errorState: {
      type: "",
      text: "",
    },
    value: "",
    changeValue: "",
    title: "",
    placeholder: "",
    onSubmit: (value: string) => {},
    name: "",
  });
  const [matchState, setMatchState] = useState(false);
  const [smsModalOpen, setSmsModalOpen] = useState({
    open: false,
    type: "",
  });

  const userprivacyApi = useQuery({
    queryKey: [QueryKeys.USERPRIVACY],
    queryFn: () =>
      restfetcher({
        method: "POST",
        path: "v1/user/privacy",
        params: {},
      }),
  });

  const userupdateloginApi = useMutation({
    mutationFn: () =>
      restfetcher({
        method: "POST",
        path: "v1/user/updatelogin",
        params: {
          isauto: autoLogin,
        },
      }),

    onSuccess: (data: any) => {
      if (data?.result === "success") {
        const { isauto } = data;
        if (userInfo.isauto !== isauto) {
          const text = t("Mobile_UserInfo_AutoSignIn_Pop_0").replaceAll(
            "{0}",
            isauto ? "ON" : "OFF"
          );
          toast(text);
        }
        setUserInfo({ ...userInfo, isauto: isauto });
      }
    },
  });

  const logoutApi = useMutation({
    mutationFn: () =>
      restfetcher({
        method: "POST",
        path: "v1/auth/logout",
        params: {},
      }),
    onSuccess: (data: any) => {
      if (data?.result === "success") {
        alert(t("Mobile_UserInfo_SignOut_Pop_2_0"));

        setTimeout(() => {
          if (typeof sessionStorage !== "undefined") {
            sessionStorage?.removeItem("token");
            localStorage?.removeItem("refresh");
          }
          navigate("/");
        }, 300);
      }
    },
  });

  const updatephoneApi = useMutation({
    mutationFn: () =>
      restfetcher({
        method: "POST",
        path: "api/user/updatephone",
        params: {
          authidx: passUserInfo?.authidx,
          authkey: passUserInfo?.authkey,
          countryno: passUserInfo?.countryno,
          usrphoneno: passUserInfo?.usrphoneno,
        },
      }),
    onSuccess: (data: any) => {
      if (data?.result === "success") {
        alert(t("Mobile_Common_Save_Pop_0"));
        setUserInfo({ ...userInfo, usrphoneno: data?.usrphoneno });
        setSmsModalOpen({
          type: "",
          open: false,
        });
      }
    },
  });

  const updatenameApi = useMutation({
    mutationFn: () =>
      restfetcher({
        method: "POST",
        path: "api/user/updatename",
        params: {
          usrname: inputChangeModal.changeValue,
        },
      }),
    onSuccess: (data: any) => {
      if (data?.result === "success") {
        const { usrname } = data;
        setUserInfo({ ...userInfo, usrname: usrname });
        alert(t("Mobile_Common_Save_Pop_0"));
        handleInputChangeModalClose();
      }
    },
  });

  const updatebirthApi = useMutation({
    mutationFn: () =>
      restfetcher({
        method: "POST",
        path: "api/user/updatebirth",
        params: {
          usrbirth: birthFunc(inputChangeModal.changeValue),
        },
      }),
    onSuccess: (data: any) => {
      if (data?.result === "success") {
        const { usrbirth } = data;
        setUserInfo({ ...userInfo, usrbirth: usrbirth });
        alert(t("Mobile_Common_Save_Pop_0"));
        handleInputChangeModalClose();
      }
    },
  });

  useEffect(() => {
    userprivacyApi.refetch();
  }, [token]);

  useEffect(() => {
    if (autoLogin !== null && userInfo?.isauto !== autoLogin) {
      userupdateloginApi.mutate();
    }
  }, [autoLogin]);

  useEffect(() => {
    const language = languageList.filter(
      (lang) => lang.key === i18n.language
    )[0];
    setSelectLanguage(language);
  }, [i18n.language]);

  useEffect(() => {
    const { isSuccess, data } = userprivacyApi;
    if (isSuccess && data?.result === "success") {
      const { userinfo } = data;
      setUserInfo(userinfo);
      setAutoLogin(userinfo.isauto);
      queryClient.removeQueries({ queryKey: [QueryKeys.USERPRIVACY] });
    }
  }, [userprivacyApi.isSuccess, userprivacyApi.data]);

  const handleSuccess = (type: string, value: string) => {
    setUserInfo({ ...userInfo, [type]: value });
  };

  const handleLogout = () => {
    if (
      typeof window !== "undefined" &&
      window?.confirm(t("Mobile_UserInfo_SignOut_Pop_1_0"))
    ) {
      logoutApi.mutate();
    }
  };

  const handlePhoneno = () => {
    setSmsModalOpen({
      type: "phoneno",
      open: true,
    });
  };

  const handlePW = () => {
    setSmsModalOpen({
      type: "pw",
      open: true,
    });
  };

  const handleInputChangeModal = (
    name: string,
    value: string,
    label: string,
    changeLabel: string,
    title: string,
    placeholder: string,
    onSubmit: (value: string) => void
  ) => {
    queryClient.removeQueries({ queryKey: [QueryKeys.UPDATENAME] });

    setInputChangeModal({
      ...inputChangeModal,
      open: true,
      value: value,
      label: label,
      changeLabel: changeLabel,
      errorState: {
        type: "",
        text: "",
      },
      title: title,
      placeholder: placeholder,
      onSubmit: (value: string) => onSubmit(value),
      name: name,
    });
  };

  const handleInputChangeModalClose = () => {
    setInputChangeModal({
      open: false,
      label: "",
      changeLabel: "",
      errorState: {
        type: "",
        text: "",
      },
      value: "",
      changeValue: "",
      title: "",
      placeholder: "",
      onSubmit: (value: string) => {},
      name: "",
    });
  };

  const handleChangeNameSubmit = (value: string) => {
    if (validatename(value)) {
      window?.alert(t("특수문자를 사용할 수 없습니다."));
    } else {
      if (window?.confirm(t("Mobile_UserInfo_Common_Edit_Pop_0"))) {
        updatenameApi.mutate();
      }
    }
  };

  const handleChangeBirthSubmit = (value: string) => {
    if (validateBirth(value)) {
      if (window?.confirm(t("Mobile_UserInfo_Common_Edit_Pop_0"))) {
        updatebirthApi.mutate();
      }
    } else {
      window?.alert(t("YYYY-MM-DD 확인해주세요"));
    }
  };

  const handleChangeInput = (value: string) => {
    let newValue = value;
    if (inputChangeModal.name === "usrbirth") {
      if (value.length < 11) {
        newValue = onlyNumberFunc(value);
      } else {
        return;
      }
    }
    setInputChangeModal({ ...inputChangeModal, changeValue: newValue });
  };

  const sendPhone = () => {
    updatephoneApi.mutate();
  };

  const sendPW = () => {
    setSmsModalOpen({
      open: false,
      type: "",
    });
    setOpenModal({ ...openModal, pw: true });
  };

  const handleClickDeleteCache = () => {
    if (
      window.confirm(
        `${t("Mobile_UserInfo_DeleteCache_Info_0")}\n${t(
          "Mobile_UserInfo_DeleteCache_Warning_0"
        )}`
      )
    ) {
      handleNaitve(`{"cmd":"cache", "value":"clear"}`);
    }
  };

  useEffect(() => {
    localStorage.setItem("autoChecked", JSON.stringify(autoLogin));
  }, [autoLogin]);

  return (
    <>
      <div
        className={style.my}
        style={{ paddingTop: `${headerType === "60px" ? "101px" : "61px"}` }}
      >
        <Header
          title="Mobile_UserInfo_Page_Title_0"
          isSelect={false}
          select={undefined}
        />
        <div className={style.myInfo}>
          <section>
            <div className={style.item}>
              <p className={style.key}>{t("Mobile_UserInfo_UserName_0")}</p>
              <p className={style.value}>
                {userInfo?.usrname}
                <DefaultBtn
                  text="Mobile_Common_Change_Button_0"
                  onClick={() =>
                    handleInputChangeModal(
                      "usrname",
                      userInfo?.usrname,
                      "Mobile_UserInfo_UserName_0",
                      "Mobile_UserInfo_NewName_0",
                      "Mobile_UserInfo_NewName_Pop_Title_0",
                      "Common_TextBox_Announcement_1_0",
                      (value: string) => handleChangeNameSubmit(value)
                    )
                  }
                />
              </p>
            </div>
            <div className={style.item}>
              <p className={style.key}>{t("Mobile_UserInfo_BOD_0")}</p>
              <p className={style.value}>
                {userInfo?.usrbirth}
                <DefaultBtn
                  text="Mobile_Common_Change_Button_0"
                  onClick={() =>
                    handleInputChangeModal(
                      "usrbirth",
                      userInfo?.usrbirth,
                      "Mobile_UserInfo_BOD_0",
                      "Mobile_UserInfo_NewBOD_0",
                      "Mobile_UserInfo_NewBOD_Pop_Title_0",
                      "YYYY-MM-DD",
                      (value: string) => handleChangeBirthSubmit(value)
                    )
                  }
                />
              </p>
            </div>
            <div className={`${style.item} ${style.isChange}`}>
              <p className={style.key}>{t("Mobile_UserInfo_Phone_0")}</p>
              <p className={style.value}>
                {userInfo?.usrphoneno}{" "}
                <DefaultBtn
                  text="Mobile_Common_Change_Button_0"
                  onClick={handlePhoneno}
                />
              </p>
            </div>
            <div className={style.item}>
              <p className={style.key}>{t("Mobile_UserInfo_Sex_0")}</p>
              <p className={style.value}>
                {userInfo?.usrgender ? t(getGender(userInfo?.usrgender)) : ""}
                <DefaultBtn
                  text="Mobile_Common_Change_Button_0"
                  onClick={() => setChangeGenderModal(true)}
                />
              </p>
            </div>
          </section>
          <section>
            <div className={`${style.item} ${style.isChange}`}>
              <p className={style.key}>{t("Mobile_UserInfo_Email_0")}</p>
              <p className={style.value}>
                {userInfo?.usremail}{" "}
                <DefaultBtn
                  text="Mobile_Common_Change_Button_0"
                  onClick={() => {
                    setOpenModal({ ...openModal, usremail: true });
                  }}
                />
              </p>
            </div>
            <div
              className={`${style.item} ${style.isChange} ${style.onlyBtnItem}`}
            >
              <p className={style.key}>{t("Mobile_UserInfo_Password_0")}</p>
              <p className={style.value}>
                <DefaultBtn
                  text="Mobile_Common_Change_Button_0"
                  onClick={handlePW}
                />
              </p>
            </div>
            <div className={`${style.item} ${style.isChange}`}>
              <p className={style.key}>{t("Mobile_UserInfo_Nickname_0")}</p>
              <p className={style.value}>
                {userInfo?.usrnickname}{" "}
                <DefaultBtn
                  text="Mobile_Common_Change_Button_0"
                  onClick={() => {
                    setOpenModal({ ...openModal, usrnickname: true });
                  }}
                />
              </p>
            </div>
          </section>
          <section>
            <div className={`${style.item}  ${style.onlyBtnItem}`}>
              <p className={style.key}>{t("Mobile_UserInfo_Push_0")}</p>
              <PushOnoffBtn />
              <Toaster
                containerStyle={{
                  top: 50,
                }}
              />
            </div>

            <div className={`${style.item}  ${style.onlyBtnItem}`}>
              <p className={style.key}>{t("Mobile_UserInfo_AutoSignIn_0")}</p>
              <OnoffBtn
                state={autoLogin ? autoLogin : false}
                setState={setAutoLogin}
              />
              <Toaster
                containerStyle={{
                  top: 50,
                }}
              />
            </div>
            <div className={style.item}>
              <p className={style.key}>{t("Mobile_UserInfo_Language_0")}</p>
              <div className={`selectBackWhite ${style.select}`}>
                <Select
                  menus={languageList}
                  select={selectLanguage}
                  onClick={(select: DefaultKeyValue) => {
                    setSelectLanguage(select);
                    i18next.changeLanguage(select.key.toString());
                    localStorage.setItem("language", select.key.toString());
                    handleNaitve(
                      `{"cmd":"language", "value":"${select.key.toString()}"}`
                    );
                  }}
                />
              </div>
            </div>
            <div className={`${style.item}  ${style.onlyBtnItem}`}>
              <p className={style.key}>{t("Mobile_UserInfo_DeleteCache_0")}</p>
              <p className={style.value}>
                <DefaultBtn
                  text={t("Mobile_UserInfo_DeleteCache_Btn_0")}
                  onClick={handleClickDeleteCache}
                />
              </p>
            </div>
            <div
              className={`${style.item} ${style.secession}  ${style.onlyBtnItem}`}
            >
              <p className={style.key}>{t("Mobile_UserInfo_Withdrawal_0")}</p>
              <p className={style.value}>
                <DefaultBtn
                  text={t("Mobile_UserInfo_Withdrawal_Button_0")}
                  onClick={() => {
                    setOpenModal({ ...openModal, secession: true });
                  }}
                />
              </p>
            </div>
            <p className={style.logout}>
              <button onClick={handleLogout}>
                {t("Mobile_UserInfo_SignOut_Button_0")} <LogoutSVG />
              </button>
            </p>
          </section>
        </div>
        {openModal.usremail && (
          <ChangeEmailModal
            email={userInfo.usremail}
            handleClose={() => setOpenModal({ ...openModal, usremail: false })}
            onChange={handleSuccess}
          />
        )}
        {openModal.pw && (
          <ChangePWModal
            handleClose={() => {
              setOpenModal({ ...openModal, pw: false });
            }}
          />
        )}
        {openModal.usrnickname && (
          <ChangeNicknameModal
            nickname={userInfo.usrnickname}
            handleClose={() =>
              setOpenModal({ ...openModal, usrnickname: false })
            }
            onChange={handleSuccess}
          />
        )}
        {openModal.secession && (
          <SecessionModal
            handleClose={() => setOpenModal({ ...openModal, secession: false })}
          />
        )}
        {inputChangeModal.open && (
          <ChangeInputModal
            type={inputChangeModal.name === "usrbirth" ? "tel" : undefined}
            placeholder={inputChangeModal.placeholder}
            title={inputChangeModal.title}
            value={inputChangeModal.value}
            handleClose={handleInputChangeModalClose}
            onChange={(value) => {
              handleChangeInput(value);
            }}
            label={inputChangeModal.label}
            changeLabel={inputChangeModal.changeLabel}
            errorState={inputChangeModal.errorState}
            changeValue={
              inputChangeModal.name === "usrbirth"
                ? birthFunc(inputChangeModal.changeValue)
                : inputChangeModal.changeValue
            }
            onSubmit={inputChangeModal.onSubmit}
          />
        )}
        {changeGenderModal && (
          <ChangeGenderModal
            gender={getGender(userInfo?.usrgender)}
            handleClose={() => {
              setChangeGenderModal(false);
            }}
            onChange={handleSuccess}
          />
        )}
        {smsModalOpen.open && (
          <SmsModal
            setMatchState={setMatchState}
            matchState={matchState}
            rightBtnLabel={
              smsModalOpen.type === "pw"
                ? "Common_Button_Next_0"
                : "Mobile_Common_Change_Button_0"
            }
            handleClose={() =>
              setSmsModalOpen({
                type: "",
                open: false,
              })
            }
            handleSubmit={smsModalOpen.type === "pw" ? sendPW : sendPhone}
          />
        )}
      </div>
      <Footer />
    </>
  );
};

export default MyPage;
