import { useTranslation } from "react-i18next";
import style from "./OnoffBtn.module.scss";

interface Props {
  state: boolean;
  setState: React.Dispatch<React.SetStateAction<boolean | null>>;
}

const OnoffBtn = ({ state = false, setState }: Props) => {
  const { t } = useTranslation();

  return (
    <p className={state ? `${style.onoffBtn} ${style.on}` : style.onoffBtn}>
      <button className={style.offbtn} onClick={() => setState(false)}>
        {t("Mobile_Common_Toggle_Off_0")}
      </button>
      <button className={style.onbtn} onClick={() => setState(true)}>
        {t("Mobile_Common_Toggle_On_0")}
      </button>
    </p>
  );
};

export default OnoffBtn;
