import style from "./ConfirmModal.module.scss";
import { useGlobalContext } from "context/store";
import ModalLayout from "../ModalLayout";
import { useTranslation } from "react-i18next";

const ConfirmModal = () => {
  const { t } = useTranslation();
  const { confirm, setConfirm } = useGlobalContext();

  const handleClose = () => {
    setConfirm({
      open: false,
      title: "",
      text: "",
      onCheck: () => {},
      onCancel: () => {},
    });
  };

  const handleCheck = () => {
    confirm.onCheck();
    handleClose();
  };

  return (
    <ModalLayout
      title={confirm.title}
      handleClose={handleClose}
      handleSubmit={handleCheck}
      leftBtnLabel="Common_Text_Cancel_0"
      rightBtnLabel="Common_Button_Okay_0"
    >
      <div className={style.confirmModal}>
        <p>{t(confirm.text)}</p>
      </div>
    </ModalLayout>
  );
};

export default ConfirmModal;
