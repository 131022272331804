import React, { useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import style from "./TextInput.module.scss";

//icon
import { ReactComponent as SuccessSVG } from "assets/icon/ic_chevron_check.svg";
import { ReactComponent as FailSVG } from "assets/icon/ic_warning_circle_lined.svg";
import { ReactComponent as EyeSVG } from "assets/icon/eye-alt.svg";
import { ReactComponent as EyeOffSVG } from "assets/icon/eye-close.svg";

interface Props {
  label?: string;
  name: string;
  value: string | number;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  state?: string;
  msg?: string;
  type?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  parentStyle?: object;
  maxLength?: number;
}

const TextInput = ({
  label,
  value,
  handleChange,
  name,
  placeholder = "Mobile_Common_InsertBox_Text_1_0",
  state = "",
  msg,
  type = "text",
  onBlur,
  parentStyle,
  maxLength,
}: Props) => {
  const { t } = useTranslation();
  const [pwLook, setPwLook] = useState(false);
  const [onFocus, setOnFocuse] = useState(false);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleChange(event);
  };

  const onBlurFunc = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur(e);
    setOnFocuse(false);
  };

  return (
    <div className={`${style.textInput}`}>
      {label && (
        <label
          className={
            state === "fail" ? `${style.label} ${style.failtext}` : style.label
          }
        >
          {t(label)}
        </label>
      )}
      <div
        className={
          onFocus ? `${style.inputBox} ${style.focus}` : style.inputBox
        }
        style={{
          ...parentStyle,
          borderColor: onFocus
            ? "rgba(0,0,0,0)"
            : value?.toString()?.length > 0
            ? "rgba(0, 0, 0, 0.4)"
            : "rgba(0,0,0,0.1)",
        }}
      >
        <p className={style.input}>
          <input
            placeholder={t(placeholder)}
            value={t(value?.toString())}
            onChange={onChange}
            aria-label={name}
            name={name}
            type={pwLook ? "text" : type}
            pattern={type === "tel" ? "d*" : undefined}
            onBlur={onBlurFunc}
            onFocus={() => setOnFocuse(true)}
            maxLength={maxLength}
          />
          {type === "password" && value?.toString()?.length > 0 && (
            <>
              {!pwLook ? (
                <EyeSVG onClick={() => setPwLook(!pwLook)} />
              ) : (
                <EyeOffSVG onClick={() => setPwLook(!pwLook)} />
              )}
            </>
          )}
        </p>
      </div>

      {state === "success" && msg && (
        <p className={`${style.state} ${style.success}`}>
          <SuccessSVG /> {t(msg)}
        </p>
      )}
      {state === "fail" && msg && (
        <p className={`${style.state} ${style.fail}`}>
          <FailSVG /> {t(msg)}
        </p>
      )}
      {state === "" && msg && msg?.toString()?.length > 0 && (
        <p className={`${style.state}`}>{t(msg)}</p>
      )}
    </div>
  );
};

export default TextInput;
