import style from "./EmailFindModal.module.scss";
import { useTranslation } from "react-i18next";
import ModalLayout from "../ModalLayout";
import { stringKeyReplace } from "utils/common";

interface Props {
  info: {
    name: string;
    joinDate: string;
    email: string;
  };
  handleClose: () => void;
}

const EmailFindModal = ({ info, handleClose }: Props) => {
  const { t } = useTranslation();
  const joinDate = info?.joinDate?.toString().split("-");

  return (
    <ModalLayout
      handleClose={handleClose}
      title="Mobile_SignIn_FindEmail_Pop_Title_0"
      handleSubmit={() => {}}
      type="alert"
      rightBtnLabel="찾기"
    >
      <div
        className={style.emailFindModal}
        dangerouslySetInnerHTML={{
          __html: stringKeyReplace(t("Mobile_SignIn_FindEmail_Pop_Text_2_0"), [
            info?.name,
            joinDate[0],
            joinDate[1],
            joinDate[2],
            info?.email,
          ]),
        }}
      ></div>
    </ModalLayout>
  );
};

export default EmailFindModal;
