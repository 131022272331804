import { useLocation } from "react-router-dom";
import style from "./Link.module.scss";

const Link = () => {
  const location = useLocation();
  const eblink = location.state;

  if (!eblink) {
    return <div className={style.link}>No link provided</div>;
  }

  return (
    <div className={style.link}>
      <iframe src={eblink} title="push link" />
    </div>
  );
};

export default Link;
