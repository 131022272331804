import style from "./Header.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as XSVG } from "assets/icon/ic_x.svg";
import { ReactComponent as BackIcon } from "assets/icon/ic_arrow_left.svg";
import Select from "components/selects/Select";

interface Props {
  pages?: DefaultKeyValue[];
  menus?: DefaultKeyValue[];
  select?: DefaultKeyValue | null;
  onClick?: (() => void) | ((select: DefaultKeyValue) => void) | any;
  isSelect?: boolean | null;
  isClose?: boolean;
  isBack?: boolean;
}

const HeaderNav = ({
  menus,
  select,
  onClick,
  isSelect = true,
  isClose = false,
  pages,
  isBack = true,
}: Props) => {
  const { t } = useTranslation();
  const headerType = localStorage.getItem("headerType");
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const backButton = () => {
    navigate(-1);
  };

  return (
    <header
      className={
        isClose
          ? `${style.header} ${style.closeHeader} ${style.i18n.language}`
          : `${style.header} ${style.language}`
      }
      style={{ paddingTop: `${headerType}` }}
    >
      <div className={`${style.pageNav}`}>
        {isBack && <BackIcon onClick={backButton} />}
        {pages?.map((page, index) => (
          <h2
            className={`${
              pathname === page.key.toString() ? style.on : style.off
            } `}
            key={index}
          >
            <Link to={page.key.toString()}>{t(page.value)}</Link>
          </h2>
        ))}
      </div>
      {isSelect && menus && select && onClick && (
        <div className={style.select}>
          <Select menus={menus} select={select && select} onClick={onClick} />
        </div>
      )}
      {isClose && onClick && (
        <p className={style.close} onClick={onClick}>
          <XSVG />
        </p>
      )}
    </header>
  );
};

export default HeaderNav;
