import style from "./ModalLayout.module.scss";
import { useTranslation } from "react-i18next";
import { ReactComponent as XSVG } from "assets/icon/ic_x.svg";

interface Props {
  children: React.ReactNode;
  handleClose?: () => void;
  title?: string;
  handleSubmit: () => void;
  leftBtnLabel?: string;
  rightBtnLabel?: string;
  rightAction?: boolean;
  type?: string;
}

const ModalLayout = ({
  children,
  handleClose,
  title = "Common_Name_Announcement_0",
  handleSubmit,
  leftBtnLabel = "Mobile_Common_Close_Button_0",
  rightBtnLabel = "Mobile_Common_Change_Button_0",
  rightAction = true,
  type = "confirm",
}: Props) => {
  const { t } = useTranslation();

  const onSubmit = () => {
    if (rightAction) {
      handleSubmit();
    }
  };

  const onClose = () => {
    handleClose && handleClose();
  };

  return (
    <>
      <p className={style.dimmed}></p>
      <div className={style.modalLayout}>
        <p className={style.top} onClick={onClose}>
          <b className={style.title}>{t(title)}</b>
          <i className={style.closeBtn}>
            <XSVG />
          </i>
        </p>
        <div className={style.children}>{children}</div>
        {type === "confirm" ? (
          <p className={style.btns}>
            <button className={style.left} onClick={onClose}>
              {t(leftBtnLabel)}
            </button>
            <i></i>
            <button
              className={style.right}
              onClick={onSubmit}
              style={{ opacity: rightAction ? "1" : "0.2" }}
            >
              {t(rightBtnLabel)}
            </button>
          </p>
        ) : (
          <p className={style.btns}>
            <button className={style.right} onClick={onClose}>
              {t("Common_Button_Okay_0")}
            </button>
          </p>
        )}
      </div>
    </>
  );
};

export default ModalLayout;
