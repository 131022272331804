import style from "./ChangeInputModal.module.scss";
import { useTranslation } from "react-i18next";
import ModalLayout from "../ModalLayout";
import TextInput from "components/inputs/textInput/TextInput";

interface Props {
  type?: string;
  value: string;
  handleClose: () => void;
  onChange: (value: string) => void;
  label: string;
  changeLabel: string;
  changeValue: string;
  errorState: {
    type: string;
    text: string;
  };
  title: string;
  placeholder: string;
  onSubmit: (value: string) => void;
}

const ChangeInputModal = ({
  type,
  value,
  handleClose,
  onChange,
  label,
  changeLabel,
  errorState,
  changeValue,
  title,
  placeholder,
  onSubmit,
}: Props) => {
  const { t } = useTranslation();

  const handleSubmit = () => {
    onSubmit(changeValue);
  };

  const handleChange = (value: string) => {
    onChange(value);
  };

  return (
    <ModalLayout
      handleClose={handleClose}
      title={title}
      handleSubmit={handleSubmit}
      rightAction={changeValue.length > 0}
    >
      <div className={style.changeInputModal}>
        <div className={style.section}>
          <div className={style.changeitem}>
            <p className={style.itemTitle}>{t(label)}</p>
            <p className={style.value}>{value}</p>
          </div>
          <div className={style.changeitem}>
            <TextInput
              type={type}
              label={`${t(changeLabel)} *`}
              value={changeValue}
              name="changeValue"
              handleChange={(event) => {
                handleChange(event.target.value);
              }}
              msg={errorState.text}
              state={errorState.type}
              placeholder={placeholder}
              parentStyle={{
                backgroundColor: "rgba(0,0,0,0)",
                height: "36px",
              }}
            />
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default ChangeInputModal;
