import { useState, useEffect } from "react";
import style from "./ChangePWModal.module.scss";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { restfetcher, QueryKeys, getClient } from "utils/queryClient";
import { validatePassword } from "utils/validate";
import ModalLayout from "../ModalLayout";
import { useGlobalContext } from "context/store";
import { defaultPassData } from "datas/defaultdata";
import TextInput from "components/inputs/textInput/TextInput";
import { setErrorCode } from "utils/error";

interface Props {
  handleClose: () => void;
}

const ChangePWModal = ({ handleClose }: Props) => {
  const queryClient = getClient();

  const { t } = useTranslation();
  const { passUserInfo, setPassUserInfo } = useGlobalContext();
  const error = {
    type: "none",
    text: "",
  };
  const [passwords, setPasswords] = useState({
    nowPW: "",
    pw: "",
    rePW: "",
  });
  const [errorState, setErrorState] = useState({
    nowPW: error,
    pw: error,
    rePW: error,
  });

  const updateusrpwApi = useQuery({
    queryKey: [QueryKeys.CHECKUPDATEPW],
    queryFn: () =>
      restfetcher({
        method: "POST",
        path: "api/user/checkupdatepw",
        params: {
          authidx: Number(passUserInfo?.authidx),
          authkey: passUserInfo?.authkey,
          countryno: passUserInfo?.countryno,
          usrphoneno: passUserInfo?.usrphoneno,
          usrpw: passwords.nowPW,
          usrpw2: passwords.rePW,
        },
      }),
  });

  useEffect(() => {
    const { isSuccess, data } = updateusrpwApi;
    if (isSuccess) {
      if (data?.result === "success") {
        alert(t("Mobile_Common_Save_Pop_0"));
        handleClose();
        setPassUserInfo(defaultPassData);
      } else {
        alert(t(setErrorCode(data?.errcode).toString()));
      }
      setErrorState({
        nowPW: error,
        pw: error,
        rePW: error,
      });

      queryClient.removeQueries({ queryKey: [QueryKeys.CHECKUPDATEPW] });
    }
  }, [updateusrpwApi.isSuccess, updateusrpwApi.data]);

  useEffect(() => {
    if (
      errorState.pw.type === "success" &&
      errorState.rePW.type === "success"
    ) {
      if (window.confirm(t("Mobile_UserInfo_Common_Edit_Pop_0"))) {
        handleValidatePW();
      }
    }
  }, [errorState]);

  const handleValidatePW = () => {
    updateusrpwApi.refetch();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPasswords({ ...passwords, [name]: value });
    setErrorState({ ...errorState, pw: error, rePW: error });
  };

  const handleSubmit = () => {
    let errorValidate = {
      type: "fail",
      text: "Mobile_UserInfo_NewPassword_Validation_5_Text_0",
    };
    if (passwords.nowPW !== passwords.pw && passwords.pw === passwords.rePW) {
      for (let [key, value] of Object.entries(passwords)) {
        const validate = validatePassword(value);
        if (!validate) {
          setErrorState({ ...errorState, [key]: errorValidate });
          return false;
        }
      }

      errorValidate = {
        type: "success",
        text: "Mobile_UserInfo_Password_Validation_1_Text_0",
      };
      setErrorState({ ...errorState, pw: errorValidate, rePW: errorValidate });
      return true;
    } else {
      setErrorState({ ...errorState, pw: errorValidate, rePW: errorValidate });
      return false;
    }
  };

  return (
    <ModalLayout
      handleClose={handleClose}
      title="Mobile_UserInfo_NewPassword_Pop_Title_0"
      handleSubmit={handleSubmit}
    >
      <div className={style.changePWModal}>
        <div className={style.item}>
          <TextInput
            label="Mobile_UserInfo_CurrentPassword_0"
            value={passwords.nowPW}
            name="nowPW"
            handleChange={handleChange}
            msg={errorState.nowPW.text}
            placeholder="Mobile_Common_InsertBox_Text_1_0"
            type="password"
            state={errorState.nowPW.type}
            parentStyle={{
              backgroundColor: "rgba(0,0,0,0)",
              height: "36px",
            }}
          />
        </div>
        <div className={style.item}>
          <TextInput
            label={`${t("Mobile_UserInfo_NewPassword_0")} *`} //"변경 비밀번호 *"
            value={passwords.pw}
            name="pw"
            handleChange={handleChange}
            msg={errorState.pw.text}
            placeholder="Mobile_UserInfo_NewPassword_InsertBox_Text_0"
            type="password"
            state={errorState.pw.type}
            parentStyle={{
              backgroundColor: "rgba(0,0,0,0)",
              height: "36px",
            }}
          />
        </div>
        <div className={style.item}>
          <TextInput
            label={`${t("Mobile_UserInfo_NewPasswordReconfirm_0")} *`} //"변경 비밀번호 재확인 *"
            value={passwords.rePW}
            name="rePW"
            handleChange={handleChange}
            msg={errorState.rePW.text}
            placeholder="Mobile_UserInfo_NewPasswordReconfirm_InsertBox_Text_0"
            state={errorState.rePW.type}
            type="password"
            parentStyle={{
              backgroundColor: "rgba(0,0,0,0)",
              height: "36px",
            }}
          />
        </div>
      </div>
    </ModalLayout>
  );
};

export default ChangePWModal;
