import style from "./Join.module.scss";
import { Routes, Route } from "react-router-dom";
import Step from "components/step/Step";
import Step1 from "./step1/Step1";
import Step2 from "./step2/Step2";
import Step3 from "./step3/Step3";
import Step4 from "./step4/Step4";

const Join = () => {
  return (
    <div className={style.join}>
      <Step />
      <Routes>
        <Route path="/step1" element={<Step1 />}></Route>
        <Route path="/step2" element={<Step2 />}></Route>
        <Route path="/step3" element={<Step3 />}></Route>
        <Route path="/step4" element={<Step4 />}></Route>
      </Routes>
    </div>
  );
};

export default Join;
