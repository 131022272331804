import Router from "./Router";
import { BrowserRouter } from "react-router-dom";
import { useGlobalContext } from "context/store";
import ConfirmModal from "components/modal/confirmModal/ConfirmModal";
import AlertModal from "components/modal/alertModal/AlertModal";
import { useTranslation } from "react-i18next";

const App = () => {
  const { i18n } = useTranslation();
  const { alert, confirm } = useGlobalContext();

  return (
    <div className={`App ${i18n.language}`}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
      {alert.open && <AlertModal />}
      {confirm.open && <ConfirmModal />}
    </div>
  );
};

export default App;
