import style from "./Nodata.module.scss";
import { ReactComponent as NodataSVG } from "assets/icon/ic_area_search_lined.svg";
import { useTranslation } from "react-i18next";

interface Props {
  text: string;
}

const Nodata = ({ text = "Mobile_Report_Details_0_Title_0" }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={style.nodata}>
      <div className={style.box}>
        <p className={style.icon}>
          <NodataSVG />
        </p>
        <h2 className={style.title}>{t("Mobile_Report_Details_0_Title_0")}</h2>
        <p className={style.text}>{t(text)}</p>
        <p className={style.desc}>
          {t("Mobile_Report_Details_0_Announcement_2_0")}
          <br />
          {t("Mobile_Report_Details_0_Announcement_3_0")}
        </p>
      </div>
    </div>
  );
};

export default Nodata;
