import style from "./CheckInput.module.scss";
import { ReactComponent as CheckSVG } from "assets/icon/ic_chevron_check.svg";

interface Props {
  children?: React.ReactNode;
  checked: boolean;
  onChange: () => void;
}

const CheckInput = ({ children, checked, onChange }: Props) => {
  return (
    <label className={style.checkInput}>
      <div className={style.checkInputBox}>
        <input
          type="checkbox"
          title="checkbox"
          alt="checkbox"
          checked={checked}
          onChange={onChange}
        />
        <span>{checked && <CheckSVG />}</span>
      </div>
      {children}
    </label>
  );
};
export default CheckInput;
