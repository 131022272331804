import style from "./Navigator.module.scss";
import { useTranslation } from "react-i18next";
import { navList, navListLogin } from "datas/nav";
import { useLocation, useNavigate, Outlet } from "react-router-dom";

const Navigator = () => {
  const { t } = useTranslation();

  const token =
    typeof sessionStorage !== "undefined"
      ? sessionStorage?.getItem("token")
      : null;
  const list = token ? navList : navListLogin;
  let { pathname } = useLocation();
  const navigate = useNavigate();
  pathname = pathname === "/" ? "/home" : pathname;

  const onClick = (path: string) => {
    if (!token && (path === "/report" || path === "/exercise")) {
      alert(t("Mobile_Need_SignIn_Alert_Text_0"));
      return navigate("/login");
    }
    navigate(path);
  };

  return (
    <>
      <Outlet />
      <nav className={style.nav}>
        <ul className={style.navList}>
          {list.map((data) => (
            <li
              key={data.key}
              className={
                pathname.includes(data.key)
                  ? `${style.li} ${style.on}`
                  : `${style.li}`
              }
            >
              <div
                key={data.path}
                onClick={() => {
                  onClick(data.path);
                }}
              >
                <p className={style.icon}>{data.icon}</p>
                <p className={style.value}>{t(data.value)}</p>
              </div>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default Navigator;
