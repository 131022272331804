import { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { restfetcher, QueryKeys } from "utils/queryClient";

import { sortMenus } from "datas/common";
import style from "./Exercise.module.scss";
import HeaderNav from "components/header/HeaderNav";
import Recommend from "./Recommend";
import VideoEx from "./VideoEx";

const Exercise = () => {
  const { pathname } = useLocation();
  const token =
    typeof sessionStorage !== "undefined"
      ? sessionStorage?.getItem("token")
      : null;
  const [select, setSelect] = useState(sortMenus[0]);
  const headerType = localStorage.getItem("headerType");

  const { data, refetch } = useQuery({
    queryKey: [QueryKeys.KEMAMOVIETAB],
    queryFn: () =>
      restfetcher({
        method: "POST",
        path: "v1/app/movietab",
        params: {},
      }),
  });

  useEffect(() => {
    if (token) refetch();
    setSelect(sortMenus[0]);
  }, [pathname]);

  return (
    <div
      className={style.exercise}
      style={{ paddingTop: `${headerType === "60px" ? "101px" : "61px"}` }}
    >
      <HeaderNav
        menus={sortMenus}
        select={select}
        onClick={setSelect}
        isSelect={token !== null}
        pages={
          data?.tab
            ? [
                { key: "/exercise", value: "Mobile_Exercise_Page_Title_0" },
                {
                  key: "/exercise/videoEx",
                  value: "Studio_Library_Group_Name_01_0",
                },
              ]
            : [{ key: "/exercise", value: "Mobile_Exercise_Page_Title_0" }]
        }
      />
      <Routes>
        <Route path="/" element={<Recommend select={select.key} />}></Route>
        <Route path="/videoEx" element={<VideoEx />}></Route>
      </Routes>
    </div>
  );
};

export default Exercise;
