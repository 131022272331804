import style from "./TabSlider.module.scss";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { postureState } from "datas/common";
import { isPastDate } from "utils/common";
interface Props {
  data?: {
    [key: string]: string;
  };
  imgDate?: string;
}

let touchStart = 0;

const TabSlider = ({ data, imgDate }: Props) => {
  const { t } = useTranslation();
  const sliderRef = useRef<HTMLUListElement>(null);
  const slirderBoxRef = useRef<HTMLDivElement>(null);
  const imfRef = useRef<HTMLLIElement>(null);
  const [idx, setIndex] = useState(0);
  const [datas] = useState(postureState);

  const imgWidth = imfRef?.current?.offsetWidth;
  const slirderBoxWidth = slirderBoxRef?.current?.offsetWidth;
  const defaultMarin = Number(slirderBoxWidth) * 0.115;
  const [marginLeft, setMarginLeft] = useState<string | number>(defaultMarin);

  useEffect(() => {
    if (idx === 0) {
      setMarginLeft(defaultMarin);
    } else if (idx + 1 === datas.length) {
      setMarginLeft(`-${Number(imgWidth) * idx}`);
    } else {
      setMarginLeft(`-${Number(imgWidth) * idx - defaultMarin / 2}`);
    }
  }, [defaultMarin, idx]);

  const onTouchStart = (e: React.TouchEvent) => {
    touchStart = e.touches[0].pageX;
  };

  const onTouchEnd = (e: React.TouchEvent) => {
    const touchEnd = e.changedTouches[0].pageX;
    const gapSE = touchStart - touchEnd;
    const gap = 80;

    if (gapSE > gap && idx < 2) {
      setIndex(idx + 1);
    } else if (gapSE < -gap && idx > 0) {
      setIndex(idx - 1);
    }
  };

  const imgRender = (img: string) => {
    const date = imgDate && isPastDate(imgDate, "2024-01-26T16:00:00"); //2024-01-23T09:46:09.18

    const style: any = date
      ? {
          width: "105%",
          height: "105%",
        }
      : {
          width: "100%",
          height: "100%",
        };

    return <img src={img} style={{ ...style }} />;
  };

  return (
    <div className={style.tabSlier}>
      <ul className={style.tabBar}>
        {postureState.map((state, index) => (
          <li
            className={idx === index ? `${style.tab} ${style.on}` : style.tab}
            onClick={() => setIndex(index)}
            key={state.key}
          >
            {t(state.value)}
            <i className={style.bar}></i>
          </li>
        ))}
      </ul>
      <div
        className={style.imgSlider}
        ref={slirderBoxRef}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
      >
        <ul
          className={style.slider}
          ref={sliderRef}
          style={{ marginLeft: `${marginLeft}px` }}
        >
          {data
            ? postureState.map((state, index) => (
                <li
                  className={`${style.img}`}
                  ref={imfRef}
                  key={`imgSlider_${index}`}
                >
                  {imgRender(data[state.key])}
                </li>
              ))
            : postureState.map((state, index) => (
                <li
                  className={`${style.img} loadingBack`}
                  ref={imfRef}
                  key={`imgSlider_${index}`}
                ></li>
              ))}
        </ul>
      </div>
    </div>
  );
};

export default TabSlider;
