import Home from "pages/home/Home";
import Dormant from "pages/dormant/Dormant";
import Exercise from "pages/exercise/Exercise";
import Report from "pages/report/Report";
import My from "pages/mypage/Mypage";
import Login from "pages/login/Login";
import Join from "pages/join/Join";

import { ReactComponent as ArchiveSVG } from "assets/icon/archive.svg";
import { ReactComponent as GymSVG } from "assets/icon/gym.svg";
import { ReactComponent as HomeSimpleSVG } from "assets/icon/home-simple.svg";
import { ReactComponent as UserCircleAltSVG } from "assets/icon/user-circle-alt.svg";

export const navList = [
  {
    key: "home",
    value: "Mobile_Navigation_Button_Home_0",
    path: "/",
    icon: <HomeSimpleSVG />,
  },
  {
    key: "report",
    value: "Mobile_Report_Page_Title_0",
    path: "/report",
    icon: <ArchiveSVG />,
  },
  {
    key: "exercise",
    value: "Mobile_Exercise_Page_Title_0",
    path: "/exercise",
    icon: <GymSVG />,
  },
  {
    key: "my",
    value: "Mobile_UserInfo_Page_Title_0",
    path: "/my",
    icon: <UserCircleAltSVG />,
  },
];

export const navListLogin = [
  {
    key: "home",
    value: "Mobile_Navigation_Button_Home_0",
    path: "/",
    icon: <HomeSimpleSVG />,
  },
  {
    key: "report",
    value: "Mobile_Report_Page_Title_0",
    path: "/report",
    icon: <ArchiveSVG />,
  },
  {
    key: "exercise",
    value: "Mobile_Exercise_Page_Title_0",
    path: "/exercise",
    icon: <GymSVG />,
  },
  {
    key: "login",
    value: "Common_Text_SignIn_0",
    path: "/login",
    icon: <UserCircleAltSVG />,
  },
];

export const routerData = [
  {
    key: "home",
    value: "Mobile_Navigation_Button_Home_0",
    path: "/",
    element: <Home />,
  },
  {
    key: "Mobile_Report_Page_Title_0",
    value: "Mobile_Report_Page_Title_0",
    path: "/report",
    element: <Report />,
  },
  {
    key: "exercise",
    value: "Mobile_Exercise_Page_Title_0",
    path: "/exercise/*",
    element: <Exercise />,
  },
  {
    key: "my",
    value: "Mobile_UserInfo_Page_Title_0",
    path: "/my",
    element: <My />,
  },
  {
    key: "login",
    value: "Mobile_SignIn_Page_Title_0",
    path: "/login",
    element: <Login />,
  },
];

export const noNavRouterData = [
  {
    key: "join",
    value: "join",
    path: "/join/*",
    element: <Join />,
  },
  {
    key: "dormant",
    value: "dormant",
    path: "/dormant",
    element: <Dormant />,
  },
];
