import style from "./Terms.module.scss";

export default function Terms1En() {
  return (
    <div className={style.terms}>
      <h2>Chapter 1 General Rules</h2>
      <br />
      <h3>Article 1 (Purpose)</h3> <br /> The purpose of these Terms and
      Conditions is to stipulate the rights, obligations and responsibilities
      and other necessary matters between the Company and the Member regarding
      the terms of use of the mobile app service (hereinafter referred to as the
      "Service") provided by exbody (hereinafter referred to as the "Company").
      <br />
      <br />
      <h3>Article 2 (Definition of Terms) </h3> <br />
      ① Definitions used in this Agreement are as follows. <br />
      1. "Company" refers to a business that provides mobile app services.
      <br />
      2. "User" refers to members and non-members who access the Service and use
      the services provided by the Company in accordance with these Terms.
      <br />
      3. "Member" refers to a person who agrees to these Terms and Conditions
      and is authorized to use the Service. <br />
      4. "Service" refers to all services provided by the Company to Members
      through the Mobile App. <br />
      5. "Account (ID)" refers to a combination of letters, numbers, or special
      characters selected by the Member and assigned by the Company to identify
      the Member and use the Service. <br />
      6. "Account Information" refers to general information provided by the
      Member to the Company, such as the Member's account, password, and name,
      and generated information such as usage information for using the Service
      and payment status for the Service. <br />
      8. "Password" refers to a combination of letters, numbers, or special
      characters selected by the member to confirm that the member is the member
      who matches the account granted to the member and to protect the member's
      information and rights and interests. <br />
      9. "Points" refers to virtual currency that the Member has come to possess
      without paying a separate purchase fee and can be granted by fulfilling
      the conditions of the event organized by the Company. <br />
      10. "Paid Content" refers to online content purchased for a fee that users
      can use or possess within the Service to enjoy certain effects or
      benefits. <br />
      11. "Posting" refers to any information in the form of text, documents,
      pictures, voice, video, or any combination thereof, posted on the Service
      by a Member while using the Service. <br />
      12. "Affiliate Service" refers to a service that is linked to the
      Company's "Service" and is operated by a third party in partnership with
      the Company, such as an individual service provided by a "Provider."
      <br />
      Terms used in these Terms that are not defined in Paragraph 1 shall be
      governed by applicable laws and general practices. <br /> <br />
      <h3>Article 3 (Clarification and Amendment of Terms) </h3> <br />
      ① The Company shall post the contents of these Terms, the name of the
      company and representative, the address of the business location
      (including the address of the place where consumer complaints can be
      handled), telephone number, fax number, e-mail address, business
      registration number, e-commerce business registration number, and privacy
      policy on the service initialization page so that members can easily
      recognize them. However, the contents of the Terms and Conditions and the
      Privacy Policy may be viewed by the Member through the connection screen.
      <br />
      ② The Company shall take measures to enable members to inquire and respond
      to the Company and the contents of these Terms. <br />
      ③ Before the user agrees to the Terms, the Company shall process important
      contents such as withdrawal of contract, refund of overpayment,
      cancellation and termination of contract, disclaimer of the Company and
      compensation for damages to the member in bold text, etc. so that the user
      can easily understand them, or provide a separate connection screen or
      pop-up screen to obtain the user's consent. <br />
      ④ The Company may revise these Terms and Conditions to the extent that
      they do not violate relevant laws and regulations, such as the Act on
      Consumer Protection in Electronic Commerce, the Act on Regulation of Terms
      and Conditions, the Act on Promotion of Information and Communications
      Network Utilization and Information Protection, the Content Industry
      Promotion Act, and the Consumer Electronics Act. <br />
      ⑤ If the Company revises the Terms and Conditions, it shall specify the
      date of application, the contents of the revision, and the reason for the
      revision, and notify it through the initial screen or the screen connected
      to the initial screen at least 7 days before the date of application (30
      days before in the case of changes that are unfavorable to the member or
      significant) until a significant period of time has elapsed after the date
      of application. In this case, the Company shall clearly compare the
      contents before the revision and the contents after the revision and
      display them so that members can easily understand them. <br />
      ⑥ If the Company revises the Terms, the Company shall confirm whether the
      Member agrees to the application of the revised Terms after the notice of
      the revised Terms. If the notice of the revised terms also states that the
      member will be deemed to have accepted the revised terms if the member
      does not express his/her consent or refusal, the member may be deemed to
      have agreed to the revised terms if the member does not express his/her
      refusal by the effective date of the revised terms. <br />
      ⑦ If the member does not agree to the application of the revised Terms,
      the company or the member may terminate the service use contract at any
      time. <br /> <br />
      <h3>Article 4 (Rules other than Terms and Conditions) </h3> <br />
      Matters not specified in these Terms and Conditions and the interpretation
      of these Terms and Conditions shall be governed by relevant laws and
      regulations such as the Act on Consumer Protection in Electronic Commerce,
      the Act on Regulation of Terms and Conditions, the Act on Promotion of the
      Game Industry, the Act on Promotion of Information and Communication
      Network Utilization and Information Protection, and the Content Industry
      Promotion Act.
      <br /> <br />
      <h3>Article 5 (Operation Policy) </h3> <br />
      ① to apply the Terms and Conditions, protect the rights and interests of
      members, and maintain service order, the Company may establish a service
      operation policy (hereinafter referred to as the "Operation Policy") that
      defines the specific scope and delegated matters in the Terms and
      Conditions. <br />
      ② The Company shall notify members of the contents of the Operating Policy
      by posting it on the initial screen or service homepage or providing a
      connected screen so that members can know the contents of the Operating
      Policy. <br />
      ③ In the case of revising the Operating Policy, which results in a
      significant change in the rights or obligations of the Member or has the
      same effect as changing the contents of the Terms and Conditions, the
      procedure in Article 3 shall be followed. However, if the revision of the
      operating policy falls under any of the following items, it shall be
      notified in advance by the method in paragraph 2. <br />
      1. revising matters specifically scoped and delegated in the Terms of
      Service <br />
      2. in the case of revising matters unrelated to the rights and obligations
      of members <br />
      3. If the contents of the operating policy are not fundamentally different
      from the contents stipulated in the Terms of Service and the operating
      policy is revised within the foreseeable range of the member.
      <br /> <br />
      <h2>Chapter 2 Conclusion of Use Agreement </h2>
      <br />
      <h3>Article 6 (Application and Method of Use) </h3> <br />
      ① the user shall apply for use by filling out the application form in
      accordance with the form prescribed by the company. <br />
      ② The user shall provide other information deemed necessary by the
      Company, such as mobile phone number, name, e-mail address, etc. when
      applying for use. <br />
      ③ The user shall provide his/her real name and actual information when
      applying for use in Paragraph 1. If the user falsely states his/her real
      name or identification information or steals the name of another person,
      the member's rights under these Terms and Conditions cannot be claimed,
      and the company may cancel or terminate the use contract. <br />
      ④ If a juvenile (including a person under the age of 18 and a student
      enrolled in a high school pursuant to Article 2 of the Elementary and
      Secondary Education Act) applies for use, the consent of a legal
      representative must be obtained, and the specific consent procedure shall
      be in accordance with the method provided by the Company in accordance
      with relevant laws and regulations. <br />
      <br />
      <h3>Article 7 (Acceptance and Restriction of Application for Use)</h3>
      <br />
      ① The Company shall accept the application for use unless there is a
      substantial reason for not accepting the application, provided that the
      user accurately states the real name and actual information regarding the
      information required by the Company.
      <br />
      ② The Company may not approve an application for use that falls under any
      of the following subparagraphs. <br />
      1. in case of application for use in violation of Article 6 <br />
      2. if the user fee is not paid or cannot be verified due to incorrect
      payment <br />
      3. if a juvenile (the same as a juvenile in Article 7, Paragraph 4) does
      not obtain the consent of a legal representative or cannot confirm that
      consent has been obtained <br />
      4. If a user with a history of usage restrictions within the last 3 months
      applies for use. <br />
      5. if the user is a member who has already signed a usage contract <br />
      6. in case of unauthorized use or theft of a third party's credit card,
      wired/wireless phone, bank account, etc. to pay for the service. <br />
      7. in the case of using the Service in a country other than the Republic
      of Korea where the Company has not yet decided to provide the Service, and
      it is necessary to restrict the provision of the Service in connection
      with a contract signed by the Company with an overseas service provider or
      the provision of the Service to members accessing the Service from a
      specific country <br />
      8. When applying for use for the purpose of committing illegal acts
      prohibited by the Act on the Promotion of the Game Industry, the Act on
      the Promotion of Information and Communication Network Utilization and
      Information Protection, and other related laws and regulations. <br />
      9. If approval is deemed inappropriate for any other reason under items 1
      through 7. <br />
      ③ The Company may suspend approval in any of the following cases until the
      reason is resolved. <br />
      1. If there is no room in the company's facilities or is a technical
      failure <br />
      2. If there is a failure in the service or a failure in the means of
      payment for the service use fee <br />
      3. If it is difficult to approve the application for use for any other
      reason under each of the preceding paragraphs. <br />
      ④ The time of establishment of the use contract shall be the time when the
      company's consent reaches the member. <br /> <br />
      <h3>Article 8 (Member Account and Password)</h3> <br />
      ① The Company shall assign a certain combination of letters, numbers, or
      special characters selected by the member as an account for the
      convenience of protecting the member's information and guiding the use of
      the service.
      <br />
      ② The Company shall perform all member management tasks such as whether
      the member can use the service through the account information.
      <br />
      ③ The member shall manage his/her account information with the duty of
      care of a good administrator. The member shall be responsible for any
      damage caused by negligently managing his/her account information or
      authorizing a third party to use it.
      <br />
      ④ The member is responsible for managing his/her password and can change
      it at any time if he/she wishes, including for security reasons.
      <br />
      ⑤ The member shall change the password regularly.
      <br /> <br />
      <h3>Article 9 (Provision and Change of Member Information)</h3> <br />
      ① When a member is required to provide information to the Company under
      these Terms and Conditions, the member shall provide truthful information,
      and shall not be protected against any disadvantages caused by the
      provision of false information.
      <br />
      ② Members may view and modify their personal information at any time
      through the personal information management screen. However, the real
      name, account (ID), etc. required for service management cannot be
      modified unless the company agrees.
      <br />
      ③ If there is a change in the information provided when applying for
      membership, the member shall modify it online or notify the company of the
      change in other ways.
      <br />
      ④ The Company shall not be liable for any disadvantages arising from the
      failure to notify the Company of changes in Paragraph 2.
      <br /> <br />
      <h3>Article 10 (Protection and Management of Personal Information)</h3>
      <br />
      ① The Company shall endeavor to protect the personal information of
      members in accordance with relevant laws and regulations, including the
      Personal Information Protection Act. The protection and use of members'
      personal information shall be governed by the relevant laws and
      regulations and the privacy policy separately notified by the Company.
      <br />
      ② Except for individual services provided as part of the Service, the
      Company's privacy policy does not apply to services provided by third
      parties that are simply linked from the homepage and service-specific
      websites.
      <br />
      ③ The Company shall not be liable for any information, including the
      member's account information, that is exposed due to reasons attributable
      to the member.
      <br /> <br />
      <h2>Chapter 3 Obligations of the Contracting Parties </h2>
      <br />
      <h3>Article 11 (Obligations of the Company) </h3> <br />
      ① The Company shall comply with relevant laws and regulations and shall
      exercise its rights and fulfill its obligations under these Terms and
      Conditions in good faith.
      <br />
      ② The Company shall have a security system to protect personal information
      (including credit information) so that members can use the service safely,
      and shall disclose and comply with the privacy policy. The Company shall
      not disclose or provide members' personal information to third parties
      except as provided in these Terms and Conditions and the Privacy Policy.
      <br />
      ③ In order to provide continuous and stable services, if there is a
      failure of facilities or loss of data while improving services, the
      Company shall make every effort to repair or restore them without delay
      unless there are unavoidable reasons such as natural disasters,
      emergencies, or defects and failures that cannot be solved with current
      technology.
      <br /> <br />
      <h3>Article 12 (Obligations of Members) </h3> <br />
      ① Members shall not engage in the following acts. If a member commits any
      of the following acts, the Company may take appropriate measures in
      accordance with these Terms and Conditions and laws and regulations.
      <br /> 1. providing false information when applying or changing the
      information
      <br /> 2. misappropriation of information of others
      <br /> 3. impersonating an employee, operator, or other related person of
      the Company
      <br /> 4. altering information posted by the Company
      <br /> 5. transmitting or posting information (such as computer programs)
      prohibited by the Company
      <br /> 6. creating, distributing, using, or advertising computer programs
      or devices or devices not provided or approved by the Company
      <br /> 7. infringement of intellectual property rights such as copyrights
      of the Company and other third parties
      <br /> 8. damage the reputation of the Company and other third parties or
      interfere with their work
      <br /> 9. disclosing or posting obscene or violent words, images, sounds,
      or other information contrary to public order and morals
      <br /> 10. use the Service for purposes of profit, sales, advertising,
      political activities, etc. without the consent of the Company
      <br /> 11. other acts prohibited by relevant laws or not permitted by good
      customs or other social conventions
      <br /> ② Members are obligated to check and comply with the provisions of
      these Terms and Conditions, the User Guide, and the precautions notified
      in connection with the Service, and the matters notified by the Company.
      <br /> ③ The Company may define specific types of acts falling under
      Paragraphs 1 and 2 and any of the following in its operating policy, and
      members are obligated to comply with them.
      <br /> 1. Restrictions on the member's account name and character name
      <br /> 2. Restrictions on chat contents and methods
      <br /> 3. Restrictions on the use of bulletin boards
      <br /> 4. restrictions on how to use the service
      <br /> 5. other matters deemed necessary by the Company for the operation
      of the Service, without prejudice to the Member's inherent right to use
      the Service.
      <br /> <br />
      <h2>Chapter 4 Use of the Service </h2>
      <br />
      <h3>Article 13 (Change and Modification of Services) </h3>
      <br />
      ① Members may use the services provided by the Company in accordance with
      these Terms and Conditions, the Operating Policy, and the rules set by the
      Company.
      <br />
      ② The Company shall have the comprehensive authority to create, change,
      maintain, and repair the contents of the Service.
      <br />
      ③ The Company may modify (patch) the Service according to operational and
      technical needs if there is a substantial reason, and if the Service is
      modified (patched), it will be notified through the relevant site after
      the change.
      <br />
      <br />
      <h3>Article 14 (Provision and Suspension of Services) </h3>
      <br />
      ① The Service shall be provided for a fixed period in accordance with the
      Company's business policy. The Company shall provide the service hours in
      an appropriate manner on the service homepage.
      <br />
      ② Notwithstanding Paragraph (1), the Service may not be provided for a
      certain period of time in any of the following cases, and the Company
      shall not be obligated to provide the Service during such time.
      <br />
      1. When necessary for maintenance, replacement, regular inspection, or
      service modification of information and communication facilities such as
      computers, etc.
      <br />
      2. When necessary to respond to electronic infringement incidents such as
      hacking, communication accidents, abnormal service usage behavior of
      members, or unexpected service instability.
      <br />
      3. when relevant laws prohibit the provision of services at a certain time
      or method
      <br />
      4. when it is impossible to provide normal services due to natural
      disasters, emergencies, power outages, failures of service facilities, or
      excessive use of services
      <br />
      5. in the event of the Company's division, merger, transfer of business,
      abolition of business, deterioration of the profit of the service, etc.
      <br />
      ③ In the case of Paragraph 2, Item 1, the Company may suspend the Service
      by setting a certain time on a weekly or biweekly basis. In this case, the
      Company shall notify the Member of the fact on the initial screen at least
      24 hours in advance.
      <br />
      ④ In the case of Paragraph 2, Item 2, the Company may suspend the Service
      without prior notice. In such case, the Company may notify the fact on the
      service homepage afterward.
      <br />
      ⑤ The Company shall not be liable for any damages incurred by users in
      connection with the use of free services provided by the Company, except
      in the case of damages caused by the Company's intentional or gross
      negligence.
      <br />
      ⑥ In the event that the service is suspended or disabled for more than 4
      hours (cumulative time) per day without prior notice due to reasons
      attributable to the Company in connection with the use of the paid service
      provided by the Company, the Company shall extend the usage time equal to
      3 times the service suspension/disability time free of charge only for the
      continuous use contract account, and the user shall not claim separate
      damages against the Company. However, if the Company notifies the service
      suspension/disability in advance for reasons such as server inspection,
      but the service suspension/disability time exceeds 10 hours, the usage
      time shall be extended free of charge by the excess time, and the user
      shall not claim separate damages against the Company.
      <br />
      ⑦ In the case of Paragraph 2, Items 3 through 5, the Company may suspend
      all services due to technical and operational needs, and may suspend the
      provision of services by posting a notice on the homepage 30 days in
      advance. If there are unavoidable circumstances that cannot be notified in
      advance, notice may be given afterward.
      <br />
      ⑧ If the Company terminates the Service pursuant to Paragraph 7, the
      Member may not claim damages for free services and paid services with no
      remaining period of use, continuous paid use contracts, and periodic paid
      items.
      <br />
      <br />
      <h3>Article 15 (Provision of Information) </h3>
      <br />
      ① The Company shall display the following items on the initial screen of
      the service or the service homepage, which is an Internet website, in an
      easy-to-understand manner for members.
      <br />
      1. business name
      <br />
      2. usage class
      <br />
      3. classification number
      <br />
      4. production date
      <br />
      5. other matters deemed necessary by the Company
      <br />
      <br />
      <h3>Article 16 (Collection of Information, etc.) </h3>
      <br />
      ① The Company may store and archive chat contents between members within
      the Service with the consent of the member. The Company shall only allow
      access to this information if the Company deems it necessary for dispute
      resolution between members, handling of complaints, or maintenance of
      service order, and this information shall be held only by the Company and
      shall not be viewed by any third party not authorized by law.
      <br />
      ② The Company shall notify the individual in advance of the reason for the
      need to view the chat information and the scope of viewing before viewing
      the information.
      <br />
      ③ Notwithstanding Paragraph 2, if it is necessary to view a member's chat
      information in connection with the investigation, processing,
      confirmation, and remedy of a member's account theft, cash transactions,
      verbal abuse, fraudulent behavior such as deception, bug abuse, other
      violations of current laws and regulations, and serious violations of
      these Terms and Conditions stipulated in Article 12 of these Terms and
      Conditions, the Company shall notify the individual whose chat information
      was viewed of the reason for the viewing and the part of the viewed
      information related to the individual afterwards.
      <br />
      ④ The Company may collect and utilize terminal settings and specification
      information such as member PCs to improve service quality, including
      service operation and program stabilization.
      <br />
      <br />
      <h3>Article 17 (Products)</h3>
      <br />
      ① The Company may provide services that members can use without paying a
      separate fee (hereinafter referred to as "free services") and services
      that members can use by paying a fee predetermined by the Company
      (hereinafter referred to as "paid services"), and members can select and
      use the services.
      <br />
      ② The method of payment for the use of the service may be made through the
      method predetermined by the company.
      <br />
      ③ In connection with the use of the paid service in Paragraph 1, after
      applying for the use of the paid service selected by the member, the
      member shall comply with the following items.
      <br />
      1. The member who applied for the use of the paid service shall pay the
      service use fee in good faith.
      <br />
      2. The member who applied for the use of the paid service shall pay the
      service use fee in the method provided by the company after applying.
      <br />
      ④ If a member who is a minor wants to use a paid service that requires
      payment, he/she must obtain the consent of a legal representative such as
      a parent, and the company shall notify the legal representative that the
      use of paid services made without consent may be canceled before payment
      for the use of paid services.
      <br />
      <br />
      <h3>Article 18 (Free Points) </h3>
      <br />
      ① The Company may provide free points according to the member's content
      usage performance, and free points that are not used within the validity
      period set by the Company may be canceled.
      <br />
      ② If there is an error in the accumulation of free points, the member may
      apply for correction within a reasonable period of time, and the Company
      may notify the result after the verification procedure and take corrective
      measures for free points if necessary.
      <br />
      ③ The Company may terminate the free point service after prior notice for
      management or technical reasons, and the Company must provide at least one
      month's notice in advance. In this case, unused free points that are not
      used by the date of termination of the service notified in advance will be
      canceled.
      <br />
      <br />
      <h3>
        Article 19 (Attribution of Intellectual Property Rights and Restrictions
        on Use)
      </h3>
      <br />
      ① Copyright and other intellectual property rights for the contents
      created by the Company in the Service are owned by the Company.
      <br />
      ② Members shall not reproduce, transmit, publish, distribute, broadcast,
      or otherwise use for commercial purposes any information obtained by using
      the Service that belongs to the Company or the Provider, or have a third
      party use it, without the prior consent of the Company or the Provider.
      <br />
      ③ Members authorize the Company to use communications, images, sounds, and
      all materials and information, including conversation texts, uploaded or
      transmitted by members or other users through the Service in connection
      with the Service (hereinafter referred to as "User Content") in the
      following ways and under the following conditions.
      <br />
      1. to use, edit and otherwise transform such User Content (including, but
      not limited to, publishing, reproducing, performing, transmitting,
      distributing, broadcasting, creating derivative works, etc. in any form
      and for any period of time and region)
      <br />
      2. not to sell, rent, or transfer User Content for the purpose of trading
      without the prior consent of the user who created the User Content
      <br />
      ④ The Company shall not commercially use the User Content of the Member
      that is not integrated with the Service (e.g., posts on general bulletin
      boards, etc.) without the express consent of the Member, and the Member
      may delete such User Content at any time.
      <br />
      ⑤ The Company may delete, move, or refuse to register any postings within
      the Service that a Member posts or registers, without prior notice, if the
      Company determines that the postings fall under the prohibited activities
      stipulated in Article 12.
      <br />
      ⑥ A member whose legal interests are infringed by information posted on a
      bulletin board operated by the Company may request the Company to delete
      the information or post a refutation. In this case, the Company shall
      promptly take the necessary measures and notify the applicant.
      <br />
      ⑦ Paragraph 3 shall be valid while the Company operates the Service and
      shall continue to apply even after withdrawal of membership.
      <br />
      <br />
      <h2>
        Chapter 5 Withdrawal of Contract, Contract Cancellation and Termination,
        and Restriction of Use
      </h2>
      <br />
      <h3>Article 20 (Withdrawal of Contract) </h3>
      <br />
      ① A member who has concluded a contract with the Company to use the paid
      service may withdraw his/her subscription within 7 days from the date of
      purchase or the date of use of the paid service.
      <br />
      ② A member may not withdraw his/her subscription pursuant to Paragraph 1
      against the will of the Company if any of the following applies. <br />
      1. if the goods are lost or damaged for reasons for which the member is
      responsible <br />
      2. the member has used or partially consumed the goods <br />
      3. if it is difficult to sell again after a period of time <br />
      4. if the packaging of reproducible goods is damaged <br />
      5. in other cases prescribed by law for the safety of transactions <br />
      ③ In the case of goods, etc. that cannot be withdrawn pursuant to the
      provisions of Paragraph 2, Items 2 through 4, the Company shall take
      measures to ensure that the exercise of the right to withdraw the contract
      is not hindered by clearly stating the fact on the packaging of the goods
      or other places where the member can easily recognize it, or by providing
      trial products. If the Company fails to take such measures, the Member may
      withdraw the contract notwithstanding the reasons for the restriction of
      subscription withdrawal in Paragraph 2, Items 2 through 4.
      <br />
      ④ Notwithstanding the provisions of Paragraphs 1 to 3, if the contents of
      the paid service are different from the contents of the display and
      advertisement or are performed differently from the contract, the member
      may withdraw the contract within 3 months from the date of purchase or the
      date of use of the paid service, or within 30 days from the date of
      knowing or being able to know the fact.
      <br />
      ⑤ Members may withdraw their contract orally or in writing (including
      electronic documents) or by e-mail.
      <br />
      <br />
      <h3>Article 21 (Effect of withdrawal of contract, etc.) </h3>
      <br />
      ① If a member withdraws his/her contract, the Company shall immediately
      cancel or delete the member's paid service and refund the payment received
      within 3 business days from the date of canceling or deleting the paid
      service.
      <br />
      ② In this case, if the company delays the refund to the member, the
      delayed interest calculated by multiplying the delay period by the rate
      prescribed by the Act on Consumer Protection in Electronic Commerce and
      Enforcement Decree shall be paid.
      <br />
      ③ When the Company refunds the above payment, if the Member paid the
      payment by credit card or other payment method prescribed by the
      Enforcement Decree of the Act on Consumer Protection in Electronic
      Commerce, the Company shall request the business that provided the payment
      method to suspend or cancel the payment without delay. However, if the
      Company has already received payment from the payment provider, it shall
      refund the payment to the payment provider and notify the consumer.
      <br />
      ④ If the goods, etc. have already been partially used or partially
      consumed, the company may charge the member an amount equivalent to the
      profit earned by the member by partially using or partially consuming the
      goods, etc. or the cost of supplying the goods, etc.
      <br />
      ⑤ If the member withdraws the contract, the member shall bear the cost of
      returning the goods, etc. and the company shall not claim penalties or
      damages from the member for withdrawing the subscription. However, if the
      member withdraws the subscription because the contents of the goods, etc.
      are different from the contents of the display and advertisement or are
      performed differently from the contract, the company shall bear the cost
      of returning the goods, etc.
      <br />
      <br />
      <h3>Article 22 (Cancellation and Termination of Membership) </h3>
      <br />
      ① A member may terminate the service use contract (hereinafter referred to
      as "membership withdrawal"). If a member applies for membership
      withdrawal, the Company may verify the identity of the member, and if the
      member is verified as the member, the Company shall take measures in
      accordance with the member's application.
      <br />
      ② If the Member wishes to withdraw his/her membership, he/she may do so
      through the membership withdrawal procedure in the customer center and
      service.
      <br />
      <br />
      <h3>Article 23 (Cancellation and Termination by the Company) </h3>
      <br />
      ① The Company may terminate the contract after prior notice to the Member
      if the Member violates the Member's obligations set forth in these Terms
      and Conditions. However, if the Member violates the current laws and
      causes damage to the Company through intentional or gross negligence, the
      Company may terminate the use contract without prior notice.
      <br />
      If the Company terminates the Use Agreement, the Company shall notify the
      Member of the following items in writing, by e-mail or equivalent method.
      <br />
      1. reason for termination <br />
      2. the date of termination <br />
      ③ In the case of Paragraph 1, the member loses the right to use the paid
      service and cannot claim refund or compensation for damages. <br />
      <br />
      <h3>Article 24 (Restriction of Service Use to Members) </h3>
      <br />
      <br />① The Company may restrict the use of the Service to Members in
      accordance with the following subparagraphs. The specific reasons for
      violation of the member's obligations for which the use of the service is
      restricted shall be determined in the individual operating policy in
      accordance with Article 27. 1. Account restriction: Restricting the use of
      the member's account for a certain period of time or permanently <br />
      2. Restriction of member use: Restriction of member's service use for a
      certain period of time or permanently <br />
      ② If the Company's restriction of use is justified, the Company shall not
      compensate for any damages suffered by the Member due to the restriction
      of use.
      <br />
      <br />
      <h3>Article 25 (Restriction of use as a provisional measure) </h3>
      <br />
      ① The Company may suspend the account until the investigation of the
      matter falling under each of the following items is completed. <br />
      1. when a legitimate report is received that the account has been hacked
      or stolen <br />
      2. when there is a reasonable suspicion of illegal program users,
      workshops, etc. <br />
      3. if provisional suspension of the account is necessary for any other
      reason falling under each of the above paragraphs. <br />
      ② In the case of Paragraph (1), after the investigation is completed, the
      Company shall pay a certain amount of money in proportion to the period of
      service use to the member and extend the member's service use period by
      the period of suspension. However, this shall not apply if the member is
      found to be an offender under Paragraph 1.
      <br />
      <br />
      <h3>Article 26 (Reasons and Procedures for Restriction of Use) </h3>
      <br />
      ① The Company shall establish specific reasons and procedures for the
      restriction of use as an operating policy, considering all circumstances
      such as the content, degree, number of times, and consequences of the
      violation.
      <br />
      ② If the Company restricts the use specified in Article 25, it shall
      notify the member of the following items in writing or by e-mail or by
      posting on the initial screen or service homepage.
      <br />
      1. the reason for the use restriction
      <br />
      2. Type and period of use restriction
      <br />
      3. How to appeal the restriction of use <br />
      <br />
      <h3>Article 27 (Appeal Procedure for Restriction of Use) </h3>
      <br />
      ① If a member wishes to appeal against the Company's restriction of use,
      he/she shall submit an appeal form stating the reasons for appealing
      against the Company's restriction of use to the Company in writing, by
      e-mail or equivalent method within 15 days from the date of receiving the
      notice.
      <br />
      ② The company that receives the appeal form in Paragraph 1 shall respond
      to the member's reason for dissatisfaction in writing, e-mail, or
      equivalent method within 15 days from the date of receipt. However, if the
      Company has difficulty responding within 15 days, the Company shall notify
      the member of the reason and processing schedule.
      <br />
      ③ The Company shall take corresponding measures based on the above
      response.
      <br />
      <br />
      <h2>Chapter 6 Compensation for Damages and Refunds </h2>
      <br />
      <h3>Article 28 (Compensation for Damages) </h3>
      <br />
      ① If the Company intentionally or grossly negligently causes damage to the
      Member, it shall be liable to compensate for the damage.
      <br />
      ② If the Member causes damage to the Company in violation of this
      Agreement and related laws and regulations, the Member shall be liable to
      compensate the Company for such damage.
      <br />
      <br />
      <h3>Article 29 (Refunds) </h3>
      <br />
      ① Refunds are limited to the purchase cost of paid services purchased
      directly by the member, except in cases where they are provided free of
      charge.
      <br />
      ② If a member requests a refund for a paid service purchased directly by
      the member, the member may receive a refund. In the event of a refund, the
      remaining amount will be refunded after deducting a certain amount for
      reasons such as bank transfer and payment agency fees, or 10% of the
      remaining balance if the balance is within 10,000 won. In the case of
      deducting a certain amount, if the remaining amount after deduction is
      less than the deducted amount, it cannot be refunded.
      <br />
      ③ In the case of a continuous use contract, we will refund the remaining
      amount after deducting the amount corresponding to the portion of the
      product value that has already been used by the user (the calculation
      method for each product is notified separately at the time of purchase)
      from the remaining amount, or if the remaining amount is within 10% of the
      product value, or if the remaining amount is within KRW 10,000, we will
      refund the remaining amount after deducting a certain amount. If we deduct
      a certain amount, we cannot refund the remaining amount if the remaining
      amount is less than the deducted amount.
      <br />
      ④ Refunds may be limited in the event of termination of the use contract
      for reasons attributable to the member, such as violation of current laws
      and material terms and conditions.
      <br />
      <br />
      <h3>Article 30 (Indemnification of the Company) </h3>
      <br />
      ① The Company shall be exempted from liability if it is unable to provide
      the Service due to wars, events, natural disasters, emergencies, technical
      defects that cannot be resolved with current technology, and other force
      majeure reasons equivalent thereto.
      <br />
      ② The Company shall be exempted from liability for suspension of the
      Service, disruption of use, and termination of the Contract due to reasons
      attributable to the Member.
      <br />
      ③ The Company shall be exempted from liability in the event that the
      telecommunications service provider stops or does not provide
      telecommunications services normally, causing damage to the member, unless
      there is no intentional or gross negligence of the Company.
      <br />
      ④ The Company shall be exempted from liability in the event that the
      service is suspended or impaired due to unavoidable reasons such as
      repair, replacement, regular inspection, or construction of facilities for
      the service announced in advance, unless there is no intentional or gross
      negligence of the Company.
      <br />
      ⑤ The Company shall be exempted from liability for any problems caused by
      the member's computer environment or problems caused by the network
      environment without intentional or gross negligence of the Company.
      <br />
      ⑥ The Company shall be exempted from liability for the reliability,
      accuracy, etc. of information, materials, facts, etc. posted or
      transmitted by members or third parties within the service or on the
      website, unless there is no intentional or gross negligence of the
      Company.
      <br />
      ⑦ The Company shall not be obligated to intervene in any disputes that
      arise between members or between members and third parties through the
      Service, nor shall the Company be liable for any damages resulting
      therefrom.
      <br />
      ⑧ In the case of free services among the services provided by the Company,
      the Company shall not compensate for damages unless there is intentional
      or gross negligence of the Company.
      <br />
      ⑨ Some of the Services may be provided through services provided by other
      operators, and the Company shall be exempted from liability for damages
      caused by services provided by other operators unless there is intentional
      or gross negligence on the part of the Company.
      <br />
      ⑩ The Company shall not be liable for any loss or failure to obtain the
      results expected by the Member in using the Service, and shall be exempted
      from liability for any damages arising from the selection or use of the
      Service unless the Company is intentionally or grossly negligent.
      <br />
      ⑪ The Company shall be exempted from liability for the loss of the
      Member's cyber assets (paid services or free points) unless there is
      intentional or gross negligence on the part of the Company.
      <br />
      ⑫ The Company shall be exempted from liability in the event of damage
      caused by the Member's computer error, or in the event of damage caused by
      the Member's inaccurate or omission of personal information and e-mail
      address, unless there is no intentional or gross negligence on the part of
      the Company.
      <br />
      ⑬ The Company may restrict the service or the time of use of the service
      depending on the member in accordance with relevant laws, government
      policies, etc. and shall be exempted from liability for all matters
      related to the use of the service arising from such restrictions and
      limitations.
      <br />
      ⑭ In the event of compensation for damages incurred by the Member due to
      the Company's intentional or grossly negligent default or illegal act, the
      amount held by the Member shall be the upper limit.
      <br />
      <br />
      <h3>Article 31 (Member's Grievance Handling and Dispute Resolution) </h3>
      <br />
      ① The Company shall provide information on how to present member's
      opinions or complaints on the initial screen or service homepage in
      consideration of member's convenience. The Company shall operate a
      dedicated organization to handle such opinions or complaints.
      <br />
      ② If the opinions or complaints raised by members are objectively
      recognized as justified, the Company shall promptly process them within a
      reasonable period of time. However, if the processing takes a long time,
      the member shall be notified of the reason and processing schedule on the
      service homepage or by e-mail, telephone, or in writing.
      <br />
      ③ If a dispute arises between the Company and a member and is mediated by
      a third-party dispute mediation organization, the Company shall certify in
      good faith the measures taken to the member, such as restriction of use,
      and may comply with the mediation of the mediation organization.
      <br />
      <br />
      <h3>Article 32 (Notification to Members) </h3>
      <br />
      ① When the Company notifies a member, it may do so by e-mail address,
      electronic memo, etc. designated by the member.
      <br />
      ② If the Company notifies all members, it may replace the notice in
      Paragraph 1 by posting it on the initialization screen of the Company's
      website or presenting a pop-up screen for more than 7 days.
      <br />
      <br />
      <h3>Article 33 (Jurisdiction and Governing Law) </h3>
      <br />
      ① These Terms shall be governed and interpreted in accordance with the
      laws of the Republic of Korea, and if a lawsuit is filed due to a dispute
      between the Company and the Member, the jurisdiction of the Member or the
      Company's address shall be the court of first instance. However, if the
      address or residence of the member is not clear at the time of filing, or
      if the member is a foreign resident, the case shall be filed with the
      competent court under the Civil Procedure Act.
      <br />
    </div>
  );
}
