import style from "./Terms.module.scss";

const Terms2 = () => {
  return (
    <div className={style.terms}>
      ㈜엑스바디가 제공하는 서비스를 이용하도록 하기 위해서 그 수집 및 이용이
      필요한 최소한의 개인정보이므로 해당 항목에 동의를 해 주셔야 서비스 이용이
      가능합니다.
      <br />
      본인은 다음과 같이 본인의 개인정보를 수집/이용하고 본 동의서에서 정하는
      경우에 한하여 제 3자에게 제공하는 것을 동의합니다.
      <br />
      <br />
      <h2>■ [필수] 개인정보 수집 및 이용 동의 </h2>
      <br />
      <table>
        <tr>
          <th>수집·이용 목적</th>
          <th>수집·이용하는 개인정보 항목</th>
          <th>보유기간</th>
        </tr>
        <tr>
          <td>피검사자 등록 및 관리 </td>
          <td>
            성명, 생년월일, 이메일 주소, 휴대폰번호, 성별, 연령,
            중복가입확인정보(DI),{" "}
          </td>
          <td>
            1. 원칙: 피검사자 파기 요청시까지
            <br />
            2. 다만, 다음의 사유에 해당하는 경우에는 그 해당 기간 종료시까지
            <br />- 소비자의 불만 또는 분쟁처리에 관한 기록: 3년(전자상거래
            등에서의 소비자 보호에 관한 법률){" "}
          </td>
        </tr>
        <tr>
          <td>개인맞춤형 서비스 제공 </td>
          <td>검사회차, 최초검사일, 검사결과 수치, 검사결과 이미지</td>
          <td>
            1. 원칙: 피검사자 탈퇴시까지
            <br />
            2. 다만, 다음의 사유에 해당하는 경우에는 그 해당 기간 종료시까지
            <br />- 소비자의 불만 또는 분쟁처리에 관한 기록: 3년(전자상거래
            등에서의 소비자 보호에 관한 법률){" "}
          </td>
        </tr>
        <tr>
          <td>컨테스트 이벤트 및 프로모션 이용시 </td>
          <td>
            [필수] <br />
            성명, 휴대폰 번호, 이메일, 거주지, 계좌정보(은행명, 예금주명,
            계좌번호){" "}
          </td>
          <td>이벤트 당첨 및 컨테스트 수상시 상품 배송 </td>
        </tr>
      </table>
    </div>
  );
};

export default Terms2;
