import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { restfetcher, QueryKeys, getClient } from "utils/queryClient";
import style from "./Report.module.scss";
import { sortMenus } from "datas/common";
import useBodyScrollLock from "hooks/useBodyScrollLock";

import View from "./View";
import InfiniteScroll from "components/infiniteScroll/InfiniteScroll";
import ReportCard from "components/resultCards/reportCard/ReportCard";
import Header from "components/header/Header";
import UpdownBtn from "components/btns/upBtn/UpBtn";
import Nodata from "components/nodata/Nodata";
import DeleteReportModal from "components/modal/deleteReportModal/DeleteReportModal";

const Report = () => {
  const queryClient = getClient();

  const token =
    typeof sessionStorage !== "undefined"
      ? sessionStorage?.getItem("token")
      : null;
  const [select, setSelect] = useState(sortMenus[0]);
  const [reports, setReports] = useState<ReportType[] | null>(null);
  const [page, setPage] = useState(1);
  const [totalpage, setTotalpage] = useState(0);
  const [viewReport, setViewReport] = useState<string[]>([]);
  const [reportRemoveMode, setReportRemoveMode] = useState(() => {
    const savedMode = sessionStorage.getItem("reportRemoveMode");
    return savedMode ? JSON.parse(savedMode) : false;
  });
  const [reportDeleteModalOpen, setReportDeleteModal] = useState(false);
  const [selectedReportIdx, setSelectedReportIdx] = useState("");

  const headerType = localStorage.getItem("headerType");
  const { lockScroll, openScroll } = useBodyScrollLock();

  const handleClickOpenDeleteModal = (reportId: string) => {
    setReportDeleteModal(true);
    setSelectedReportIdx(reportId);
  };

  const handleClickCloseDeleteModal = () => {
    setReportDeleteModal(false);
  };

  const reportallApi = useQuery({
    queryKey: [QueryKeys.REPORTALL],
    queryFn: () =>
      restfetcher({
        method: "POST",
        path: "v1/app/reportall",
        params: {
          sort: select.key,
          page: page,
        },
      }),
  });

  const handleClickGetReportList = () => {
    reportallApi.refetch();
  };

  useEffect(() => {
    return () => {
      setPage(1);
      setReports(null);
      queryClient.removeQueries({ queryKey: [QueryKeys.REPORTALL] });
    };
  }, []);

  useEffect(() => {
    if (
      page > 0 &&
      !reportallApi.isLoading &&
      (totalpage > page || totalpage === page) &&
      token
    ) {
      handleClickGetReportList();
    }
  }, [page]);

  useEffect(() => {
    if (page === 1) {
      if (token) handleClickGetReportList();
    } else {
      setPage(1);
    }

    setReports(null);
  }, [select]);

  useEffect(() => {
    sessionStorage.setItem(
      "reportRemoveMode",
      JSON.stringify(reportRemoveMode)
    );
  }, [reportRemoveMode]);

  useEffect(() => {
    const { isSuccess, data } = reportallApi;
    if (isSuccess && data?.result === "success") {
      const { report, totalpage } = data;
      const reportArray = reports ? reports : [];
      const newReport = reportArray?.concat(report);
      setReports(newReport);
      setTotalpage(totalpage);
      queryClient.removeQueries({ queryKey: [QueryKeys.REPORTALL] });
    }
  }, [reportallApi.isSuccess, reportallApi.data]);

  useEffect(() => {
    if (viewReport.length > 0) {
      lockScroll();
    } else {
      openScroll();
    }
  }, [viewReport]);

  const rowChildren = (data: any) => {
    return (
      <ReportCard
        data={data}
        onClickMoreReport={(reportfileList) => setViewReport(reportfileList)}
        onClickOpenModal={() => handleClickOpenDeleteModal(data._id)}
        key={data?._id}
        icons={reportallApi?.data?.icon}
        reportRemoveMode={reportRemoveMode}
      />
    );
  };

  return (
    <div
      className={style.report}
      style={{ paddingTop: `${headerType === "60px" ? "101px" : "61px"}` }}
    >
      <Header
        title={`Mobile_Report_Page_Title_0`}
        menus={sortMenus}
        select={select}
        onClick={setSelect}
        rightIcon
        isSelect={reports && reports?.length > 0 && token !== null}
        setMode={setReportRemoveMode}
        mode={reportRemoveMode}
      />
      {token ? (
        reports ? (
          reports?.length > 0 ? (
            <>
              <section>
                <InfiniteScroll
                  datas={reports} //reports
                  page={page}
                  setPage={setPage}
                  refetch={() => reportallApi.refetch()}
                  rowChildren={rowChildren}
                />
              </section>
              {viewReport?.length > 0 && (
                <View
                  reportfileList={viewReport}
                  handleClose={() => setViewReport([])}
                />
              )}
              <UpdownBtn />
            </>
          ) : (
            <Nodata text="Mobile_Report_Details_0_Announcement_1_0" />
          )
        ) : (
          <section></section>
        )
      ) : (
        <Nodata text="Mobile_Report_Details_0_Announcement_1_0" />
      )}
      {reportDeleteModalOpen && (
        <DeleteReportModal
          handleClose={handleClickCloseDeleteModal}
          reportId={selectedReportIdx}
        />
      )}
    </div>
  );
};

export default Report;
