import axios from "axios";

const DEV_URL = "https://metagymdev.exbody.com:443/";
const QA_URL = "https://metagymdev.exbody.com:443/";
const LIVE_URL = "https://api.push.metafamily.world:443/";

export const BASE_URL = //"https://app.exbody.qa.metafamily.world/v1/"
  typeof window !== "undefined" &&
  window?.location.origin.includes("exbody.metafamily.world")
    ? LIVE_URL
    : window?.location.origin.includes("qa")
    ? QA_URL
    : DEV_URL;

export const requestFcmUser = async (param: any) => {
  const { data } = await axios.post(
    `${BASE_URL}api/v1/messaging/ggm/registeruser`,
    {
      ...param,
    }
  );
  return data;
};

export const requestDeviceId = async (param: any) => {
  const { data } = await axios.post(
    `${BASE_URL}api/v1/messaging/ggm/registertoken`,
    {
      ...param,
    }
  );
  return data;
};

export const requestCheckToken = async (param: any) => {
  const { data } = await axios.post(
    `${BASE_URL}api/v1/messaging/ggm/checktoken`,
    {
      ...param,
    }
  );
  return data;
};
