import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import style from "./OnoffBtn.module.scss";
import toast from "react-hot-toast";

const PushOnoffBtn = () => {
  const { t } = useTranslation();

  const [isFcmPermission, setIsFcmPermission] = useState(() => {
    return localStorage.getItem("fcmPermission") === "allowed";
  });

  const handleClickMessageToUnity = () => {
    const text = t("Mobile_UserInfo_Push_Alert_0");

    toast(text);
  };

  useEffect(() => {
    const handleStorageChange = () => {
      setIsFcmPermission(localStorage.getItem("fcmPermission") === "allowed");
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <p
      className={
        isFcmPermission ? `${style.onoffBtn} ${style.on}` : style.onoffBtn
      }
    >
      <button
        className={style.offbtn}
        onClick={() => handleClickMessageToUnity()}
      >
        {t("Mobile_Common_Toggle_Off_0")}
      </button>
      <button
        className={style.onbtn}
        onClick={() => handleClickMessageToUnity()}
      >
        {t("Mobile_Common_Toggle_On_0")}
      </button>
    </p>
  );
};

export default PushOnoffBtn;
