import { useState, useRef } from "react";
import style from "./Home.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { measuretype } from "datas/common";
import { stringKeyReplace } from "utils/common";

import { ReactComponent as NotesSvg } from "assets/icon/notes.svg";
import { ReactComponent as PoseSvg } from "assets/icon/ic_body_pose_lined.svg";
import { ReactComponent as SearchSvg } from "assets/icon/ic_page_search_lined.svg";
import { ReactComponent as ThumbUpSVG } from "assets/icon/ic_thumb_up_lined.svg";
import { ReactComponent as LogoExbodySvg } from "assets/icon/logo_exbody.svg";

//component
import DeviationCard from "components/resultCards/deviationCard/DeviationCard";
import RecommendedEX from "components/resultCards/recommendedEX/RecommendedEX";
import TabSlier from "components/tabSlier/TabSlider";
import GradeCard from "components/resultCards/gradeCard/GradeCard";
import GradeCard2 from "components/resultCards/gradeCard/GradeCard2";
import SectionTitle from "components/sectionTitle/SectionTitle";
import DefaultBtn from "components/btns/defaultBtn/DefaultBtn";
import View from "pages/exercise/View";
import RecommendedEXCard from "components/resultCards/recommendedEX/RecommendedEXCard";

interface Props {
  isLoading: boolean;
  datas?: null | any;
}

const IsData = ({ isLoading, datas }: Props) => {
  const { t } = useTranslation();
  const listRef = useRef<HTMLLIElement>(null);
  const [video, setVideo] = useState<null | string>(null);

  if (isLoading || !datas) {
    return (
      <div className={style.isdata}>
        <section className={style.section1}>
          <article>
            <h1>
              <LogoExbodySvg />
            </h1>
            <h2>
              <p className={`${style.item1} loadingBack`}></p>
              <p className={`${style.item2} loadingBack`}></p>
            </h2>
          </article>
          <div className={style.dataArea}>
            <SectionTitle title="Mobile_Main_Index_Title_0">
              <NotesSvg />
            </SectionTitle>
            <div className={style.resultList}>
              {new Array(4).fill("").map((data, index) => (
                <DeviationCard key={`deviationCard${index}`} />
              ))}
            </div>
          </div>
        </section>
        <section className={style.section2}>
          <div className={style.sctionTitle}>
            <SectionTitle title="Mobile_Main_MyResult_Title_0">
              <PoseSvg />
            </SectionTitle>
          </div>
          <TabSlier />
        </section>
        <section className={style.section3}>
          <SectionTitle title="Mobile_Report_Details_InspectionItem_0">
            <PoseSvg />
          </SectionTitle>
          <div className={style.resultList}>
            <div className={style.gradeArea}>
              {measuretype?.map((data: any, index: number) => (
                <GradeCard title={data?.value} key={`measuretype${index}`} />
              ))}
            </div>
            <GradeCard2 title="Mobile_Main_InspectionResult_7_Name_0" />
            <GradeCard2 title="Mobile_Main_InspectionResult_8_Name_0" />
          </div>
          <Link to="/report" className={style.moreBtn}>
            <DefaultBtn text="Mobile_Main_Report_Button_0" />
          </Link>
        </section>
        <section className={style.section4}>
          <SectionTitle title="Mobile_Main_Exercise_Title_0">
            <ThumbUpSVG />
          </SectionTitle>
          <RecommendedEX onClick={() => {}} isOpen={false}>
            <RecommendedEXCard onClick={() => {}} data={null} />
          </RecommendedEX>
          <Link to="/exercise" className={style.moreBtn}>
            <DefaultBtn text="Mobile_Main_Exercise_Recommendation_Button_0" />
          </Link>
        </section>
      </div>
    );
  }

  return (
    <div className={style.isdata}>
      <section
        className={style.section1}
        style={{ backgroundImage: `url(${datas?.mainImg})` }}
      >
        <article>
          <h1>
            <LogoExbodySvg />
          </h1>
          <h2>
            <pre>{stringKeyReplace(t(datas?.mainTitle), [datas?.usrname])}</pre>
          </h2>
        </article>
        <div className={style.dataArea}>
          <SectionTitle title="Mobile_Main_Index_Title_0">
            <NotesSvg />
          </SectionTitle>
          <div className={style.resultList}>
            {datas?.musculResponse?.map((data: any, index: number) => (
              <DeviationCard data={data} key={`deviationCard${index}`} />
            ))}
          </div>
        </div>
      </section>
      <section className={style.section2}>
        <div className={style.sctionTitle}>
          <SectionTitle title="Mobile_Main_MyResult_Title_0">
            <PoseSvg />
          </SectionTitle>
        </div>
        <TabSlier data={datas?.bodyImg} imgDate={datas?.recommend?.date} />
      </section>
      <section className={style.section3}>
        <SectionTitle title="Mobile_Report_Details_InspectionItem_0">
          <SearchSvg />
        </SectionTitle>
        <div className={style.resultList}>
          <div className={style.gradeArea}>
            {measuretype?.map((data: any) => (
              <GradeCard
                key={data?.key}
                data={datas?.measuretype?.[data?.key]}
                title={data?.value}
                states={datas?.measurestatement}
              />
            ))}
          </div>
          <GradeCard2
            title="Mobile_Main_InspectionResult_7_Name_0"
            data={[
              datas?.measuretype?.oxleftleg,
              datas?.measuretype?.oxrightleg,
            ]}
            states={datas?.measurestatement}
          />
          <GradeCard2
            title="Mobile_Main_InspectionResult_8_Name_0"
            data={[datas?.measuretype?.leftfoot, datas?.measuretype?.rightfoot]}
            states={datas?.measurestatement}
          />
        </div>
        <Link to="/report" className={style.moreBtn}>
          <DefaultBtn text="Mobile_Main_Report_Button_0" />
        </Link>
      </section>
      <section className={style.section4}>
        <SectionTitle title="Mobile_Main_Exercise_Title_0">
          <ThumbUpSVG />
        </SectionTitle>
        <RecommendedEX
          onClick={setVideo}
          isOpen={false}
          data={datas?.recommend}
        >
          {datas?.recommend?.movieList?.map((exercise: any) => (
            <li className={style.list} key={`${exercise?.idx}`} ref={listRef}>
              <RecommendedEXCard
                onClick={() => exercise?.url && setVideo(exercise?.url)}
                data={exercise}
              />
            </li>
          ))}
        </RecommendedEX>
        <Link to="/exercise" className={style.moreBtn}>
          <DefaultBtn text="Mobile_Main_Exercise_Recommendation_Button_0" />
        </Link>
      </section>
      {video && <View video={video} handleClose={() => setVideo(null)} />}
    </div>
  );
};

export default IsData;
