import { useState, useRef, useEffect } from "react";
import style from "./Sms.module.scss";
import { ReactComponent as CheckSVG } from "assets/icon/ic_nav_arrow_down.svg";
import { useTranslation } from "react-i18next";
import TextInput from "components/inputs/textInput/TextInput";
import DefaultBtn from "components/btns/defaultBtn/DefaultBtn";
import { onlyNumberFunc } from "utils/validate";
import { useMutation, useQuery } from "@tanstack/react-query";
import { restfetcher, QueryKeys, getClient } from "utils/queryClient";
import { secondsToHMS } from "utils/common";
import { setErrorCode } from "utils/error";
import { useGlobalContext } from "context/store";

interface Props {
  setMatchState: React.Dispatch<React.SetStateAction<boolean>>;
  type?: string;
  parentStyle?: object;
  matchState: boolean;
}

let timer: any = null;

const Sms = ({
  setMatchState,
  matchState,
  parentStyle = {
    backgroundColor: "rgba(0,0,0,0.05)",
    height: "44px",
  },
}: Props) => {
  const queryClient = getClient();

  const { passUserInfo, setPassUserInfo } = useGlobalContext();
  const userBrowserLang = navigator.language;
  const { t } = useTranslation();
  const selectRef = useRef<HTMLDivElement>(null);
  const matchRef = useRef<HTMLDivElement>(null);
  const [tell, setTell] = useState("");
  const [sendErrors, setSendErrors] = useState({
    text: "",
    type: "",
  });
  const [errors, setErrors] = useState({
    text: "",
    type: "",
  });
  const [authData, setAuthData] = useState({
    authidx: "",
    authkey: "",
    expiredin: 0,
  });
  const [count, setCount] = useState(authData.expiredin);

  const serviceNationCode = useQuery({
    queryKey: [QueryKeys.NATION_LIST],
    queryFn: () =>
      restfetcher({
        method: "POST",
        path: "api/code/nation/service",
        params: {},
      }),
  });

  const serviceNationCodeList: NationList[] = serviceNationCode?.data?.data;

  const [nationCodeState, setNationCodeState] = useState<NationList>({
    nationCode: "",
    nationName: "",
    nationNums: [""],
    stringKey: "",
  });

  const smsSend = useMutation({
    mutationFn: () =>
      restfetcher({
        method: "POST",
        path: "api/auth/sms/send",
        params: {
          usrphoneno: tell,
          countryno: nationCodeState?.nationNums[0],
          countrycode: nationCodeState?.nationCode,
        },
      }),

    onSuccess: (data: any) => {
      if (data?.result === "success") {
        const { authidx, expiredin } = data;
        setAuthData({
          ...authData,
          authidx: authidx,
          expiredin: expiredin,
        });
        if (expiredin > 0) handleTimer(expiredin);
      } else {
        setSendErrors({
          text: t(setErrorCode(data?.errcode)?.toString()),
          type: "fail",
        });
      }
    },
  });

  const smsMatch = useMutation({
    mutationFn: () =>
      restfetcher({
        method: "POST",
        path: "api/auth/sms/match",
        params: {
          authidx: authData.authidx,
          authkey: authData.authkey,
          usrphoneno: tell,
          countryno: nationCodeState?.nationNums[0],
        },
      }),
    onSuccess: (data: any) => {
      if (data?.result === "success") {
        setErrors({
          text: "Mobile_Join_IdentityVerification_Success_Text_0",
          type: "success",
        });
        setMatchState(true);
        setPassUserInfo({
          ...passUserInfo,
          authidx: authData.authidx,
          usrphoneno: tell,
          authkey: authData.authkey,
          countryno: nationCodeState?.nationNums[0],
        });
        clearInterval(timer);
      } else {
        setErrors({
          text: t(setErrorCode(data?.errcode).toString()),
          type: "fail",
        });
      }
    },
  });

  const onSelect = (value: string) => {
    const selectCode = serviceNationCodeList.filter(
      (m) => m.stringKey === value
    )[0];

    setNationCodeState(selectCode);
  };

  const handlePhonenumber = (value: string) => {
    setSendErrors({
      text: "",
      type: "",
    });
    const phonenumber = onlyNumberFunc(value);
    setTell(phonenumber);
    queryClient.removeQueries({ queryKey: [QueryKeys.SMSSEND] });

    setCount(0);
    setAuthData({
      authidx: "",
      authkey: "",
      expiredin: 0,
    });
    clearInterval(timer);
  };

  const handleTimer = (expiredin: number) => {
    let time = expiredin;
    timer = setInterval(() => {
      if (time > 0) {
        time = time - 1;
      } else {
        clearInterval(timer);
      }
      setCount(time);
    }, 1000);
  };

  const handleClickMatch = () => {
    smsMatch.mutate();
  };

  const handleChangeAuthkey = (value: string) => {
    setAuthData({ ...authData, authkey: value });
    setErrors({
      text: "",
      type: "",
    });
  };

  useEffect(() => {
    serviceNationCode.refetch();
  }, []);

  useEffect(() => {
    const { isSuccess, data } = serviceNationCode;
    if (isSuccess) {
      if (data?.result === "success") {
        setNationCodeState(data?.data);
      }
    }
  }, [serviceNationCode.data]);

  useEffect(() => {
    if (!matchState && tell.length > 0) {
      setTell("");
      setAuthData({
        authidx: "",
        authkey: "",
        expiredin: 0,
      });
    }
  }, [matchState]);

  useEffect(() => {
    if (userBrowserLang) {
      const selectCode = serviceNationCodeList?.filter((item) =>
        userBrowserLang.includes(item.nationCode)
      )[0];

      setNationCodeState(selectCode);
    }
  }, [userBrowserLang, serviceNationCodeList]);

  useEffect(() => {
    if (matchRef.current) {
      matchRef.current.querySelector("input")?.focus();
    }
  }, [matchRef.current]);

  return (
    <div className={style.Sms}>
      <section className={style.section}>
        <p className={style.title}>{t("Excel_Column_Basic_Column_4_4")}</p>
        <div className={style.select} ref={selectRef} style={parentStyle}>
          <CheckSVG />
          <select
            placeholder={t("Mobile_Common_Select_Text_0")}
            value={nationCodeState?.stringKey}
            onChange={(event) => onSelect(event.target.value)}
          >
            {serviceNationCodeList?.map((data, index) => (
              <option key={`${data.stringKey}_${index}`} value={data.stringKey}>
                {`${t(`${data.stringKey}_0`)}  ${data.nationNums[0]}`}
              </option>
            ))}
          </select>
        </div>
        <TextInput
          value={tell}
          type="number"
          name="tell"
          handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handlePhonenumber(event?.target?.value);
          }}
          state={sendErrors.type}
          msg={sendErrors.text}
          parentStyle={parentStyle}
        />
        <DefaultBtn
          text="Mobile_Join_PhoneVerification_SendNumber_0"
          activate={tell.length > 0}
          onClick={() => smsSend.mutate()}
        />
      </section>
      {count > 0 && (
        <div className={`${style.section} ${style.match}`} ref={matchRef}>
          <p className={`${style.title} ${style.countTitle}`}>
            {t("Mobile_Join_PhoneVerification_EnterNumber_0")}
            <span className={style.count}>{secondsToHMS(count)}</span>
          </p>
          <TextInput
            value={authData.authkey}
            type="number"
            name="email"
            handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleChangeAuthkey(event?.target?.value);
            }}
            state={errors.type}
            msg={errors.text}
            parentStyle={parentStyle}
          />
          <DefaultBtn
            text="Mobile_Join_Verification_0"
            onClick={handleClickMatch}
            activate={authData.authkey.length > 0}
          />
        </div>
      )}
    </div>
  );
};

export default Sms;
