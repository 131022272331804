import React, { useState } from "react";
import { useTranslation } from "react-i18next";
//style
import style from "./LineInput.module.scss";

//icon
import { ReactComponent as IconCheck } from "assets/icon/ic_chevron_check.svg";
import { ReactComponent as IconWarning } from "assets/icon/ic_warning_circle_lined.svg";

type PropsType = {
  label?: string;
  state?: string;
  undertext?: string;
  name?: string;
  value: number | string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: () => void;
  type?: string;
  onKeyDown?: (event: React.KeyboardEvent) => void;
};

const LineInput = ({
  label = "Mobile_Common_InsertBox_Text_1_0",
  state = "",
  undertext,
  name,
  value,
  handleChange,
  handleBlur,
  type = "text",
  onKeyDown,
}: PropsType) => {
  const { t } = useTranslation();
  const [isFocus, setFocus] = useState(false);

  const onBlur = () => {
    setFocus(false);
    handleBlur && handleBlur();
  };

  return (
    <div
      className={
        state === "fail" ? `${style.joinInput} ${style.fail}` : style.joinInput
      }
    >
      <div
        className={
          isFocus
            ? `${style.isfocus} ${style.joinBox}`
            : value?.toString().length > 0
            ? `${style.isValue} ${style.joinBox}`
            : style.joinBox
        }
      >
        <p className={style.label}>{t(label)}</p>
        <p className={style.inputArea}>
          <input
            className={style.input}
            name={name}
            onFocus={() => setFocus(true)}
            onBlur={onBlur}
            onChange={handleChange}
            type={type}
            value={t(value?.toString())}
            onKeyDown={onKeyDown && onKeyDown}
          />
          {state === "success" && <IconCheck />}
          {state === "fail" && <IconWarning />}
        </p>
      </div>
      {undertext && <p className={style.underText}>{t(undertext)}</p>}
    </div>
  );
};

export default React.memo(LineInput);
