import { useRef, useEffect } from "react";
import style from "./Exercise.module.scss";
import Header from "components/header/Header";
import { handleNaitve } from "utils/native";

interface Props {
  video: string;
  handleClose: () => void;
}

const View = ({ video, handleClose }: Props) => {
  let isFullScreen = false;
  const headerType = localStorage.getItem("headerType");
  const vidRef = useRef<any>(null);
  const mobileType = navigator.userAgent.toLowerCase();

  useEffect(() => {
    if (mobileType.indexOf("android") > -1) {
      document.addEventListener("fullscreenchange", onfullscreenchange, false);
      return () => {
        document.removeEventListener(
          "fullscreenchange",
          onfullscreenchange,
          false
        );
        handleNaitve(`{"cmd":"orientation", "value":"portrait"}`);
      };
    }
  }, []);

  const onfullscreenchange = (e: any) => {
    if (isFullScreen) {
      handleNaitve(`{"cmd":"orientation", "value":"portrait"}`);
    } else {
      handleNaitve(`{"cmd":"orientation", "value":"landscape"}`);
    }
    isFullScreen = !isFullScreen;
  };

  return (
    <div
      className={`${style.view} openUp`}
      style={{ paddingTop: `${headerType === "60px" ? "101px" : "61px"}` }}
    >
      <Header
        title="Mobile_Exercise_Page_Title_0"
        isClose={true}
        onClick={handleClose}
        select={undefined}
        isBack={false}
      />
      <div
        className={style.viewArea}
        style={{ height: `calc(100vh - ${headerType})` }}
      >
        <video src={video} controls autoPlay muted={true} ref={vidRef} />
      </div>
    </div>
  );
};

export default View;
