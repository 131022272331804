import { useState, useEffect } from "react";
import style from "./ChangeGenderModal.module.scss";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { restfetcher, QueryKeys, getClient } from "utils/queryClient";
import { genderDatas } from "datas/common";
import ModalLayout from "../ModalLayout";
import Select from "components/selects/Select";

interface Props {
  gender: string;
  handleClose: () => void;
  onChange: (type: string, value: string) => void;
}
const ChangeGenderModal = ({ gender, handleClose, onChange }: Props) => {
  const queryClient = getClient();

  const { t } = useTranslation();
  const error = {
    type: "none",
    text: "",
  };
  const [selectGender, setSelectGender] = useState<DefaultKeyValue>(
    genderDatas[0]
  );
  const [errorState, setErrorState] = useState(error);

  const updategenderApi = useQuery({
    queryKey: [QueryKeys.UPDATEGENDER],
    queryFn: () =>
      restfetcher({
        method: "POST",
        path: "api/user/updategender",
        params: {
          usrgender: selectGender.key,
        },
      }),
  });

  useEffect(() => {
    const { isSuccess, data } = updategenderApi;
    if (isSuccess && data?.result === "success") {
      const { usrgender } = data;
      queryClient.removeQueries({ queryKey: [QueryKeys.UPDATEGENDER] });
      setErrorState(error);
      onChange("usrgender", selectGender.key.toString());
      alert(t("Mobile_Common_Save_Pop_0"));
      handleClose();
    }
  }, [updategenderApi.isSuccess, updategenderApi.data]);

  useEffect(() => {
    setErrorState(error);
  }, [selectGender]);

  const handleSubmit = () => {
    if (
      typeof window !== "undefined" &&
      window?.confirm(t("Mobile_UserInfo_Common_Edit_Pop_0"))
    ) {
      updategenderApi.refetch();
    }
  };

  return (
    <ModalLayout
      handleClose={handleClose}
      title="Select_Sex_Title_0"
      handleSubmit={handleSubmit}
      // rightAction={}
    >
      <div className={style.changeGenderModal}>
        <div className={style.section}>
          <div className={style.item}>
            <p className={style.itemTitle}>{t("Mobile_UserInfo_Sex_0")}</p>
            <p className={style.nowGender}>{t(gender)}</p>
          </div>
          <div>
            <div className={style.item}>
              <p
                className={
                  errorState.type === "fail"
                    ? `${style.itemTitle} ${style.failtext}`
                    : style.itemTitle
                }
              >
                {t("Select_Sex_Text_0")} *
              </p>
              <div className={style.select}>
                <Select
                  menus={genderDatas}
                  select={selectGender}
                  onClick={(select: DefaultKeyValue) => {
                    setSelectGender(select);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default ChangeGenderModal;
