import style from "./Terms.module.scss";

const Terms3 = () => {
  return (
    <div className={style.terms}>
      주식회사 엑스바디(이하 “회사”라 한다)는 개인정보보호법 등 관련 법령을
      준수하며, 관련 법령에 의거한 개인정보∙민감정보처리방침을 정하여 개인정보
      보호에 최선을 다하고 있습니다.
      <br />
      개인정보처리방침은 이용자가 언제나 쉽게 열람할 수 있도록 서비스 초기화면을
      통해 공개하고 있으며 관련 법령, 지침, 고시 및 회사 서비스 정책의 변경에
      따라 달라질 수 있습니다. 해당 내용을 자세히 읽은 후 이에 동의하시는
      경우에만 검사를 진행해 주시기 바랍니다.
      <br />
      <br />
      1. 처리하는 개인정보 항목 및 목적
      <br />
      회사는 사업운영, 제품 및 서비스의 제공 및 개선, 사용자에 대한 안내,
      효과적인 광고 개발 및 아래의 각 목적을 위하여 개인정보·민감정보 항목을
      수집하여 처리하고 있습니다. 회사가 모든 이용자에게 서비스를 제공하기
      위해서는 아래 항목의 개인정보가 필요하며, 이는 가입신청자의 동의 또는
      법령에 근거하여 수집됩니다. 피검사자는 본 동의에 대해 거부하실 권리가
      있으나 필수항목에 대한 수집 및 이용동의를 거부하실 경우 서비스 이용에
      제한이 있을 수 있습니다.
      <br />
      <table>
        <tr>
          <th>수집시기</th>
          <th>수집항목</th>
          <th>수집목적</th>
        </tr>
        <tr>
          <td>피검사자 등록 시</td>
          <td>
            [필수] <br />
            성명, 생년월일, 이메일 주소, 휴대폰번호, 성별, 연령,
            중복가입확인정보(DI),
            <br />
            [미성년자의 경우 추가 필수 수집 개인정보] <br />
            법정대리인의 성명, 이메일 주소, 휴대전화번호, 중복가입확인정보(DI)
          </td>
          <td>
            가입 의사 확인, 서비스 가입/변경/해지, <br />
            서비스 제공여부 결정(연령확인 등) 및 중복가입여부 확인, <br />
            공지 사항 전달, <br />
            피검사자 참여 공간 운영, <br />
            피검사자 설문 조사, <br />
            개인 맞춤형 서비스 제공, <br />
            광고성 정보∙제휴 서비스 제공 및 안내, <br />
            상품 배송지 주소 및 연락처 확인,
          </td>
        </tr>
        <tr>
          <td>피검사자 측정 시 </td>
          <td>[필수] 검사회차, 최초검사일, 검사결과 수치, 검사결과 이미지 </td>
          <td>개인 맞춤형 서비스 제공</td>
        </tr>
        <tr>
          <td>컨테스트 이벤트 및 프로모션 이용시 </td>
          <td>
            [필수] <br />
            성명, 휴대폰 번호, 이메일, 거주지, 계좌정보(은행명, 예금주명,
            계좌번호)
          </td>
          <td>이벤트 당첨 및 컨테스트 수상시 상품 배송 </td>
        </tr>
      </table>
      <br />
      2. 개인정보의 처리 및 보유기간
      <br />
      <br />
      1. 회사는 피검사자의 개인정보 동의 일로부터 피검사자가 개인정보 파기를
      요청할 때 까지 보유하며, 피검사자가 개인정보 파기를 요청할 경우
      개인정보보호법 제39조의6에 따라 피검사자의 개인정보를 지체없이 파기하거나
      별도로 분리하여 보관합니다. 단, 관계법령에 따라 보관되는 정보는 예외로
      합니다.
      <br />
      <br />
      ㆍ관련 법령에 의거한 개인정보의 보유 및 이용기간
      <br />
      1) 상업장부와 영업에 관한 중요서류 및 전표 : 중요서류-10년/ 전표-5년(상법)
      <br />
      2) 거래에 관한 장부 및 증빙서류 : 5 년(국세기본법, 법인세법, 부가가치세법
      등)
      <br />
      3) 통신사실확인자료 제공 시 필요한 로그기록자료, IP 주소 등 : 3
      개월(통신비밀보호법)
      <br />
      4) 표시/광고에 관한 기록 : 6 개월(전자상거래 등에서의 소비자보호에 관한
      법률)
      <br />
      5) 계약 또는 청약철회 등에 관한 기록 : 5 년(전자상거래 등에서의
      소비자보호에 관한 법률)
      <br />
      6) 대금결제 및 재화 등의 공급에 관한 기록 : 5 년(전자상거래 등에서의
      소비자보호에 관한 법률)
      <br />
      7) 소비자의 불만∙분쟁 처리에 관한 기록 : 3 년(전자상거래법등에서의
      소비자보호에 관한 법률)
      <br />
      8) 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3 년(신용정보의 이용 및
      보호에 관한 법률)
      <br />
      9) 본인확인에 관한 기록 : 6 개월(정보통신망 이용촉진 및 정보보호 등에 관한
      법률)
      <br />
      <br />
      3. 개인정보의 취급위탁 회사는 서비스 향상을 위해 개인정보의 처리를 다른
      회사 등에 위탁할 수 있습니다. 회사는 개인정보 처리 위탁 시 피검사자의
      개인정보가 안전하게 처리될 수 있도록 관리·감독하며 다른 목적으로
      피검사자의 개인정보를 처리하지 않도록 제한합니다.
      <br />
      (1) 개인정보의 처리를 위탁하는 경우에는 미리 그 사실을 피검사자에게
      고지하겠습니다.
      <br />
      (2) 개인정보의 처리를 위탁하는 경우에는 위탁계약 등을 통하여 서비스
      제공자의 개인정보보호 관련지시 엄수, 개인정보에 관한 비밀유지, 제3자
      제공의 금지 및 사고시의 책임부담 등을 명확히 규정하고 당해 계약내용을 서면
      또는 전자적으로 보관하겠습니다.
      <br />
      <br />
      4. 개인정보 파기절차 및 방법 회사는 개인정보 보유기간의 경과 혹은
      개인정보의 수집 및 이용목적의 달성 등 개인정보가 불필요하게 되었을 때에는
      해당 개인정보를 지체 없이 파기합니다.
      <br />
      회사의 개인정보 파기절차 및 방법은 다음과 같습니다.
      <br />
      (1) 파기절차
      <br />
      가. 회사는 파기 사유가 발생한 개인정보를 개인정보보호책임자의 승인을 거쳐
      파기합니다.
      <br />
      나. 피검사자가 피검사자가입 또는 서비스 신청 등을 위해 입력한 정보는
      목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 잠금 장치가 있는
      서류 보관함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유
      및 이용기간 참조)일정 기간 저장된 후 파기됩니다.
      <br />
      다. 동 개인정보는 관련 법령에 의한 경우가 아니고서는 보유 목적 이외의 다른
      목적으로 이용되지 않습니다.
      <br />
      (2) 파기방법
      <br />
      가. 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적
      방법을 사용하여 파기합니다.
      <br />
      나. 종이에 출력된 개인정보는 분쇄기로 분쇄하여 파기합니다.
      <br />
      <br />
      5. 개인정보의 안정성 확보 대책 회사는 피검사자의 개인정보를 처리함에 있어
      안정성을 확보하기 위하여 다음과 같이 대책을 강구하고 있습니다.
      <br />
      (1) 개인정보의 암호화 피검사자의 개인정보는 비밀번호에 의해 보호되며
      중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을
      사용하는 등 다른 보안 기능을 사용하여 보호하고 있습니다.
      <br />
      (2) 해킹 등에 대비한 기술적 대책 회사는 해킹이나 컴퓨터 바이러스 등에 의해
      피검사자의 개인 정보가 유출되거나 훼손되는 것을 막기 위해, 보안프로그램을
      설치하고 주기적으로 갱신·점검하고 있습니다.
      <br />
      (3) 개인정보 처리 시스템 접근 제한 회사의 개인정보관련 취급 직원은 제한된
      소수의 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여
      수시로 변경하고 있습니다.
      <br />
      (4) 개인정보 취급 직원의 교육 회사는 개인정보 취급 직원에 대해 새로운 보안
      기술 습득 및 개인 정보 보호 의무에 대한 정기적인 교육을 실시하고 있습니다.
      <br />
      (5) 비인가자에 대한 출입 통제 개인정보를 보관하고 있는
      개인정보처리시스템의 물리적 보관 장소를 별도로 두고 이에 대한 출입통제
      절차를 수립, 운영하고 있습니다.
      <br />
      <br />
      6. 개인정보보호책임자 피검사자는 회사의 서비스를 이용하며 발생하는 모든
      개인정보보호 관련 민원을 개인정보보호책임자 또는 담당부서에 문의하실 수
      있습니다. 회사는 피검사자들의 문의사항에 대해 신속하고 충분한 답변을 드릴
      것입니다.
      <br />
      <br />
      ㆍ개인정보보호책임자 : 김미숙 <br />
      ㆍ전화번호 : 02-6925-6081 <br />
      ㆍ메일 : secure@exbody.com <br />
      <br />
      7. 개인정보침해에 대한 신고 및 상담 <br />
      개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기
      바랍니다.
      <br />
      <br />
      <table>
        <tr>
          <th>기관</th>
          <th>홈페이지</th>
          <th>전화번호</th>
        </tr>
        <tr>
          <td>개인정보침해신고센터</td>
          <td>http://privacy.kisa.or.kr/kor/main.jsp</td>
          <td>(국번없이) 118</td>
        </tr>
        <tr>
          <td>개인정보분쟁조정위원회</td>
          <td>https://www.kopico.go.kr/ </td>
          <td>1833-6972</td>
        </tr>
        <tr>
          <td>대검찰청 사이버수사과 </td>
          <td>http://www.spo.go.kr/spo/index.jsp</td>
          <td>(국번없이) 1301 </td>
        </tr>
        <tr>
          <td>경찰청 사이버안전국 </td>
          <td>http://cyberbureau.police.go.kr/index.d</td>
          <td>(국번없이) 182 </td>
        </tr>
      </table>
      <br />
      <br />
      8. 개인정보 처리 의무 <br />
      (1) 본 개인정보처리방침은 법령, 정부의 정책 또는 회사 내부 정책의 변경 등
      필요에 따라 변경될 수 있으며 내용의 추가, 삭제 및 수정이 있을 경우에는
      개정 최소 7일 전부터 엑스바디 서비스 홈페이지를 통해 고지합니다.
      <br />
      (2) 본 개인정보처리방침은 2023년 9월 7일 부터 적용됩니다.
      <br />
      <br />
      - 공고일자 : 2023년 9월 7일
      <br />- 시행일자 : 2023년 9월 7일
    </div>
  );
};

export default Terms3;
