import style from "./Terms.module.scss";

const Terms4En = () => {
  return (
    <div className={style.terms}>
      Since the collection and use of personal information is necessary for the
      smoother use of the services provided by exbody, even if you refuse to
      agree to the item, you can still use the remaining services except for the
      services that collect and use the above personal information.
      <br /> <br />
      ■ [Optional] Agree to the collection and use of personal information
      <br /> <br />
      <table>
        <tr>
          <th>Purpose of Collection and Use</th>
          <th>Items of personal information we collect and use</th>
          <th> Retaining Period</th>
        </tr>
        <tr>
          <td>
            Utilization for marketing and advertising
            <br />
            Developing new services and providing customized services, providing
            event and advertising information and participation opportunities,
            identifying access frequency or statistics on members' use of
            services, etc.
          </td>
          <td>Phone number, email address, nickname, username</td>
          <td>Until you withdraw your membership</td>
        </tr>
        <tr>
          <td>Use to provide personalized services</td>
          <td>
            Alcohol consumption, tobacco use, alcohol consumption, recent
            exercise history (duration, frequency, type of exercise),
            cardiovascular disease, pulmonary disease, hypertension, diabetes
            (type 2)
          </td>
          <td>Until you withdraw membership or withdraw your consent</td>
        </tr>
      </table>
    </div>
  );
};

export default Terms4En;
