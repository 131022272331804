import style from "./Terms.module.scss";

const Terms3Jp = () => {
  return (
    <div className={style.terms}>
      株式会社エックスボディ（以下「会社」という）は、個人情報保護法など関連法令を遵守し、関連法令に基づく個人情報・機密情報処理方針を定めて個人情報保護に最善を尽くしています。
      <br />
      個人情報処理方針は、利用者がいつでも簡単に閲覧できるようにサービス初期画面を通じて公開しており、関連法令、指針、告示及び会社サービス政策の変更によって変わることがあります。その内容をよく読んでから同意した場合にのみ検査を進めてください。
      <br />
      <br />
      1. 処理する個人情報項目及び目的
      <br />
      当社は、事業運営、製品及びサービスの提供及び改善、ユーザーへの案内、効果的な広告開発及び下記の各目的のために個人情報・機密情報項目を収集して処理しています。
      会社がすべての利用者にサービスを提供するためには以下の項目の個人情報が必要であり、これは加入申請者の同意または法令に基づいて収集されます。
      被験者は、本同意に対して拒否する権利がありますが、必須項目の収集及び利用同意を拒否される場合、サービスの利用に制限がある場合があります。
      <br />
      <table>
        <tr>
          <th>収集時期</th>
          <th>収集アイテム</th>
          <th>収集目的</th>
        </tr>
        <tr>
          <td>検査対象者登録時</td>
          <td>
            [必須] <br />
            氏名、生年月日、Eメールアドレス、携帯電話番号、性別、年齢、重複加入確認情報
            (DI)、,
            <br />
            [未成年者の場合、追加必須収集個人情報] <br />
            法定代理人の氏名、Eメールアドレス、携帯電話番号、重複加入確認情報
            (DI)
          </td>
          <td>
            参加意思確認、サービス購読/変更/終了、 <br />
            サービス提供可否決定（年齢確認等）及び重複加入可否確認、 <br />
            お知らせ配信、
            <br />
            被験者参加スペース運営、 <br />
            被験者アンケート、 <br />
            パーソナライズされたサービスの提供、
            <br />
            広告性情報・連携サービスの提供とご案内、
            <br />
            商品配送先住所と連絡先確認、
          </td>
        </tr>
        <tr>
          <td>被験者測定時</td>
          <td>[必須]検査会車、最初の検査日、検査結果の数値、検査結果の画像 </td>
          <td>パーソナライズされたサービスを提供</td>
        </tr>
        <tr>
          <td>コンテストイベントやプロモーションを利用する場合 </td>
          <td>
            [必須] <br />
            氏名、携帯電話番号、Eメール、居住地、口座情報
            (銀行名、預金住所名、口座番号)
          </td>
          <td>イベント当選及びコンテスト受賞時の商品発送</td>
        </tr>
      </table>
      <br />
      2. 個人情報の処理及び保有期間
      <br />
      <br />
      1.
      会社は、被検者の個人情報同意日から被検者が個人情報破棄を要請するまで保有し、被検者が個人情報破棄を要請する場合、個人情報保護法第39条の6により被検者の個人情報を遅滞なく破棄するか、別途分離して保管します。ただし、関係法令により保管される情報は例外とします。
      <br />
      <br />
      ㆍ関連法令に基づく個人情報の保有及び利用期間
      <br />
      1) 商業帳簿と営業に関する重要書類及び伝票：重要書類-10年/伝票-5年 (商法)
      <br />
      2) 取引に関する帳簿及び証明書類 : 5年
      (国税基本法、法人税法、付加価値税法等)
      <br />
      3) 通信事実確認資料提供時に必要なログ記録資料、IPアドレス等 : 3ヶ月
      (通信秘密保護法)
      <br />
      4) 表示/広告に関する記録 : 6ヶ月 (電子商取引等での消費者保護に関する法律)
      <br />
      5) 契約又は請約撤回等に関する記録 : 5年
      (電子商取引等における消費者保護に関する法律)
      <br />
      6) 代金決済及び財貨等の供給に関する記録 : 5年
      (電子商取引等における消費者保護に関する法律)
      <br />
      7) 消費者の苦情・紛争処理に関する記録 : 3年
      (電子商取引法等における消費者保護に関する法律)
      <br />
      8) 信用情報の収集/処理及び利用等に関する記録 : 3年
      (信用情報の利用及び保護に関する法律)
      <br />
      9) 本人確認に関する記録 : 6ヶ月
      (情報通信網利用促進及び情報保護等に関する法律)
      <br />
      <br />
      3.
      会社はサービス向上のために個人情報の処理を他の会社等に委託することができます。当社は、個人情報処理委託時に被検査者の個人情報が安全に処理されるように管理・監督し、他の目的で被検査者の個人情報を処理しないように制限します。
      <br />
      (1)
      個人情報の処理を委託する場合には、あらかじめその事実を被検査者に告知いたします。
      <br />
      (2)
      個人情報の処理を委託する場合には、委託契約等を通じてサービス提供者の個人情報保護関連指示厳守、個人情報に関する秘密保持、第三者提供の禁止及び事故時の責任負担等を明確に規定し、当該契約内容を書面または電子的に保管いたします。
      <br />
      <br />
      4. 個人情報の破棄手続及び方法 <br />
      <br />
      当社は、個人情報保有期間の経過もしくは個人情報の収集および利用目的の達成など、個人情報が不要になったときは、当該個人情報を遅滞なく破棄します。
      <br />
      会社の個人情報の破棄手続きおよび方法は次のとおりです。
      <br />
      (1) 破棄手順
      <br />
      行く。当社は、破棄事由が発生した個人情報を個人情報保護責任者の承認を受けて破棄します。
      <br />
      私。被検者が被検者が入又はサービス申請等のために入力した情報は、目的が達成された後、別のDBに移され
      (紙の場合は別途のロック装置がある書類保管)、内部方針及びその他関連法令による情報保護の事由により（保有および利用期間参照）一定期間保存された後破棄されます。
      <br />
      だ。同個人情報は関連法令による場合ではなく、保有目的以外の他の目的には利用されません。
      <br />
      (2) 破棄方法
      <br />
      行く。電子的ファイル形式で保存された個人情報は、記録を再生できない技術的な方法を使用して破棄します。
      <br />
      私。紙に出力された個人情報は粉砕機で粉砕して破棄します。
      <br />
      <br />
      5.
      会社は被検査者の個人情報を処理する上で安定性を確保するために、次のように対策を講じています。
      <br />
      (1) 個人情報の暗号化
      <br />
      <br />
      被験者の個人情報はパスワードで保護され、重要なデータはファイルや転送データを暗号化したり、ファイルロック機能を使用するなど、他のセキュリティ機能を使用して保護しています。
      <br />
      (2) ハッキング等に備えた技術的対策
      <br />
      <br />
      会社はハッキングやコンピュータウイルスなどにより被検者の個人情報が漏洩したり、破損することを防ぐため、セキュリティプログラムを設置して定期的に更新・点検しています。
      <br />
      (3) 個人情報処理システムのアクセス制限
      <br />
      <br />
      会社の個人情報関連取扱職員は限られた少数の担当者に限定しており、そのための別途のパスワードを付与して随時変更しています。
      <br />
      (4) 個人情報取扱職員の教育
      <br />
      <br />
      当社は、個人情報取扱職員に対して新たなセキュリティ技術の習得及びプライバシー義務について定期的な教育を行っています。
      <br />
      (5) 非認可者へのアクセス管理
      <br />
      <br />
      個人情報を保管している個人情報処理システムの物理的保管場所を別に置き、これに対する出入管理手続きを樹立、運営しています。
      <br />
      <br />
      6. 個人情報保護責任者
      <br />
      <br />
      被験者は会社のサービスを利用し、発生するすべての個人情報保護関連苦情を個人情報保護責任者または担当部署に問い合わせることができます。会社は、被験者の質問に迅速かつ十分な回答を提供します。
      <br />
      <br />
      ㆍ個人情報保護責任者 : キム・ミスク <br />
      ㆍ電話番号 : 02-6925-6081 <br />
      ㆍメール : secure@exbody.com <br />
      <br />
      7. 個人情報侵害に対する申告及び相談
      <br />
      個人情報侵害に関する届出や相談が必要な場合は、下記機関にお問い合わせください。
      <br />
      <br />
      <table>
        <tr>
          <th>機関</th>
          <th>ホームページ</th>
          <th>電話番号</th>
        </tr>
        <tr>
          <td>個人情報侵害報告センター</td>
          <td>http://privacy.kisa.or.kr/kor/main.jsp</td>
          <td>(局番なし) 118</td>
        </tr>
        <tr>
          <td>個人情報紛争調整委員会</td>
          <td>https://www.kopico.go.kr/ </td>
          <td>1833-6972</td>
        </tr>
        <tr>
          <td>大検察庁サイバー捜査</td>
          <td>http://www.spo.go.kr/spo/index.jsp</td>
          <td>(局番なし) 1301 </td>
        </tr>
        <tr>
          <td>警察庁サイバー安全局</td>
          <td>http://cyberbureau.police.go.kr/index.d</td>
          <td>(局番なし) 182 </td>
        </tr>
      </table>
      <br />
      <br />
      8. 個人情報処理義務
      <br />
      (1)
      本個人情報処理方針は、法令、政府の政策又は会社内部政策の変更等必要に応じて変更されることがあり、内容の追加、削除及び修正がある場合には、改正の少なくとも7日前からXボディサービスホームページを通じてお知らせします。
      <br />
      (2) 本個人情報処理方針は、2023年9月7日から適用されます。
      <br />
      <br />
      - 発表日 : 2023年9月7日
      <br />- 施行日 : 2023年9月7日
    </div>
  );
};

export default Terms3Jp;
