import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "context/store";
import { useQuery } from "@tanstack/react-query";
import { restfetcher, QueryKeys, getClient } from "utils/queryClient";
import { useTranslation } from "react-i18next";
import style from "./Dormant.module.scss";

import DefaultBtn from "components/btns/defaultBtn/DefaultBtn";

const Dormant = () => {
  const queryClient = getClient();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setConfirm, dormant } = useGlobalContext();

  const unlockApi = useQuery({
    queryKey: [QueryKeys.UNLOCK],
    queryFn: () =>
      restfetcher({
        method: "POST",
        path: "v1/user/unlock",
        params: {
          usridx: dormant?.usridx,
        },
      }),
  });

  useEffect(() => {
    const { isSuccess, data } = unlockApi;
    if (isSuccess) {
      if (data?.result === "success") {
        setConfirm({
          open: true,
          title: "",
          text: "Mobile_SignIn_InactiveMember_Pop_Reactivation_Pop_2_0",
          onCancel: () => {},
          onCheck: () => {
            navigate("/login");
          },
        });
      } else {
        setConfirm({
          open: true,
          title: "",
          text: "Mobile_SignIn_InactiveMember_Pop_Reactivation_Err_Pop_0",
          onCancel: () => {},
          onCheck: () => {
            navigate("/login");
          },
        });
      }
      queryClient.removeQueries({ queryKey: [QueryKeys.UNLOCK] });
    }
  }, [unlockApi.isSuccess, unlockApi.data]);

  const handleSubmit = () => {
    setConfirm({
      open: true,
      title: "",
      text: "Mobile_SignIn_InactiveMember_Pop_Reactivation_Pop_1_0",
      onCancel: () => {},
      onCheck: () => {
        unlockApi.refetch();
      },
    });
  };

  return (
    <div className={style.dormant}>
      <h1 className={style.title}>
        {t("Mobile_SignIn_InactiveMember_Pop_Title_0")}
      </h1>
      <p className={style.desc}>
        {t("Mobile_SignIn_InactiveMember_Pop_Text_1_0")}
      </p>
      <p className={style.desc2}>
        {t("Mobile_SignIn_InactiveMember_Pop_Text_2_0")}
      </p>
      <section>
        <p>
          {t("Mobile_UserInfo_UserName_0")} <b>{dormant?.usrname}</b>
        </p>
        <p>
          {t("Mobile_SignIn_InactiveMember_Pop_RegistrationDate_0")}{" "}
          <b>{dormant?.joindate}</b>{" "}
        </p>
        <p>
          {t("Mobile_UserInfo_Email_0")} <b>{dormant?.usremail}</b>
        </p>
        <p>
          {t("Mobile_SignIn_InactiveMember_Pop_InactiveDate_0")}{" "}
          <b>{dormant?.dormantdate}</b>
        </p>
      </section>
      <p className={style.btns}>
        <DefaultBtn
          text={t("Mobile_SignIn_InactiveMember_Pop_Reactivation_Button_0")}
          onClick={handleSubmit}
        />
        <DefaultBtn
          text={t("Mobile_Common_Back_Button_0")}
          onClick={() => navigate("/login")}
        />
      </p>
    </div>
  );
};

export default Dormant;
