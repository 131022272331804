import style from "./DeleteReportModal.module.scss";
import { useTranslation } from "react-i18next";
import ModalLayout from "../ModalLayout";
import { useMutation } from "@tanstack/react-query";
import { restfetcher } from "utils/queryClient";

interface DeleteReportModalProps {
  reportId: string;
  handleClose: () => void;
}
const DeleteReportModal = ({
  reportId,
  handleClose,
}: DeleteReportModalProps) => {
  const { t } = useTranslation();

  const requetDeleteReport = useMutation({
    mutationFn: () =>
      restfetcher({
        method: "POST",
        path: "v1/app/report/delete",
        params: {
          reportidx: reportId,
        },
      }),
    onSuccess: (data: any) => {
      if (data?.result === "success") {
        handleClose();
        alert(t("Mobile_Report_Delete_Success_0"));
        window.location.reload();
      } else if (data?.errcode) {
        if (data?.errcode === 403) {
          alert(t("Server_Err_Code_403_0"));
        }
      }
    },
  });

  const handleClickDeleteReport = () => {
    requetDeleteReport.mutate();
  };

  return (
    <ModalLayout
      leftBtnLabel="Common_Text_Cancel_0"
      rightBtnLabel="Common_Button_Delete_0"
      handleClose={handleClose}
      title=""
      handleSubmit={handleClickDeleteReport}
      // rightAction={}
    >
      <div className={style.deleteReportModal}>
        <div className={style.section}>
          <p className={style.itemTitle}>{t("Mobile_Report_Delete_Title_0")}</p>
          <p className={style.itemContents}>
            *{t("Mobile_Report_Delete_Warning_0")}
          </p>
        </div>
      </div>
    </ModalLayout>
  );
};

export default DeleteReportModal;
