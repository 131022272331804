import { useState, useEffect, useLayoutEffect } from "react";
import style from "./Home.module.scss";
import { useQuery } from "@tanstack/react-query";
import {
  restfetcher,
  fetchCookie,
  QueryKeys,
  getClient,
} from "utils/queryClient";
import { useGlobalContext } from "context/store";
import IsData from "./IsData";
import NoData from "./NoData";
import { useTranslation } from "react-i18next";

const Home = () => {
  const queryClient = getClient();
  const { i18n } = useTranslation();

  const { dormant, setDormant } = useGlobalContext();
  const [datas, setDatas] = useState<
    null | BannerLogoutDatasType | BannerLoginDatasType
  >(null);

  const authtokenApi = useQuery({
    queryKey: [QueryKeys.AUTHTOKEN],
    queryFn: () => fetchCookie(),
    initialData: null,
  });

  const bannerloginApi = useQuery({
    queryKey: [QueryKeys.BANNERLOGOUT],
    queryFn: () =>
      restfetcher({
        method: "POST",
        path: "v1/app/bannerlogin",
        params: {
          language: i18n.language || "ko",
        },
      }),
  });

  useLayoutEffect(() => {
    authtokenApi.refetch();
    return () => {
      queryClient.removeQueries({ queryKey: [QueryKeys.AUTHTOKEN] });
    };
  }, []);

  useEffect(() => {
    const { isSuccess, data } = authtokenApi;
    if (isSuccess) {
      if (data?.result === "success") {
        const { authenticatetoken, userinfo } = data;
        sessionStorage?.setItem("token", authenticatetoken?.jwt);
        localStorage?.setItem("refresh", authenticatetoken?.refresh);

        setDormant({
          usridx: userinfo?.usridx,
          usrname: userinfo?.usrname,
          usremail: userinfo?.usremail,
          joindate: userinfo?.joindate,
          logindate: userinfo?.logindate,
          dormantdate: userinfo?.dormantdate,
        });
      } else {
        setTimeout(() => {
          bannerloginApi.refetch();
        }, 500);
      }
    }
  }, [authtokenApi.isSuccess, authtokenApi.data]);

  useEffect(() => {
    const { isSuccess, data } = bannerloginApi;
    if (isSuccess) {
      if (data?.result === "success") {
        setDatas(data?.data);
      } else {
        console.log(data?.errcode);
      }
    } else {
      console.log(data?.errcode);
    }
  }, [bannerloginApi.isSuccess, bannerloginApi.data]);

  useEffect(() => {
    if (dormant.usridx !== 0) {
      bannerloginApi.refetch();
    }
  }, [dormant]);

  return (
    <div className={style.home}>
      {sessionStorage?.getItem("token") &&
      bannerloginApi?.data?.bannerType === "Login" ? (
        <IsData isLoading={bannerloginApi?.isLoading} datas={datas} />
      ) : (
        <NoData isLoading={bannerloginApi?.isLoading} datas={datas} />
      )}
    </div>
  );
};

export default Home;
