import React, { useState } from "react";
import { useTranslation } from "react-i18next";
//style
import style from "./LinePWInput.module.scss";

//icon
import { ReactComponent as TextAltSVG } from "assets/icon/ic_text_alt_lined.svg";
import { ReactComponent as EyeSVG } from "assets/icon/eye-alt.svg";
import { ReactComponent as EyeOffSVG } from "assets/icon/eye-close.svg";

type PropsType = {
  label?: string;
  state?: string;
  undertext?: string;
  name?: string;
  value: number | string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: () => void;
  type?: string;
  onKeyDown?: (event: React.KeyboardEvent) => void;
};

const LinePWInput = ({
  label = "Mobile_Common_InsertBox_Text_1_0",
  state = "",
  name,
  value,
  handleChange,
  handleBlur,
  onKeyDown,
}: PropsType) => {
  const { t } = useTranslation();
  const [isFocus, setFocus] = useState(false);
  const [pwLook, setPwLook] = useState(false);
  const [capsLockFlag, setCapsLockFlag] = useState(false);

  const onBlur = () => {
    setFocus(false);
    setCapsLockFlag(false);
    handleBlur && handleBlur();
  };

  const handelKeyDown = (e: React.KeyboardEvent) => {
    onKeyDown && onKeyDown(e);
    let capsLock = e.getModifierState("CapsLock");
    setCapsLockFlag(capsLock);
  };

  return (
    <div
      className={
        state === "fail" ? `${style.joinInput} ${style.fail}` : style.joinInput
      }
    >
      <div
        className={
          isFocus
            ? `${style.isfocus} ${style.joinBox}`
            : value?.toString().length > 0
            ? `${style.isValue} ${style.joinBox}`
            : style.joinBox
        }
      >
        <p className={style.label}>{t(label)}</p>
        <p className={style.inputArea}>
          <input
            className={style.input}
            name={name}
            onFocus={() => setFocus(true)}
            onBlur={onBlur}
            onChange={handleChange}
            type={pwLook ? "text" : "password"}
            value={t(value?.toString())}
            onKeyDown={handelKeyDown}
          />
          {pwLook ? (
            <EyeOffSVG onClick={() => setPwLook(!pwLook)} />
          ) : (
            <EyeSVG onClick={() => setPwLook(!pwLook)} />
          )}
        </p>
      </div>
      {capsLockFlag && (
        <p className={style.underText}>
          <TextAltSVG />
          {t("Mobile_SignIn_CapsLock_Text_0")}
        </p>
      )}
    </div>
  );
};

export default React.memo(LinePWInput);
