import style from "./Header.module.scss";
import { useNavigate } from "react-router-dom";

import { ReactComponent as XSVG } from "assets/icon/ic_x.svg";
import { ReactComponent as BackIcon } from "assets/icon/ic_arrow_left.svg";
import { ReactComponent as TrashIcon } from "assets/icon/trash.svg";
import { ReactComponent as CheckIcon } from "assets/icon/ic_chevron_check_mode.svg";

import Select from "components/selects/Select";
import { useTranslation } from "react-i18next";

interface Props {
  title: string;
  menus?: DefaultKeyValue[];
  select?: DefaultKeyValue | null;
  onClick?: (() => void) | ((select: DefaultKeyValue) => void) | any;
  isSelect?: boolean | null;
  isClose?: boolean;
  isBack?: boolean;
  rightIcon?: boolean;
  mode?: boolean;
  setMode?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header = ({
  title = "",
  menus,
  select,
  onClick,
  isSelect = true,
  isClose = false,
  isBack = true,
  rightIcon = false,
  mode,
  setMode,
}: Props) => {
  const { t } = useTranslation();
  const headerType = localStorage.getItem("headerType");
  const navigate = useNavigate();

  const handleClickChangeMode = () => {
    setMode && setMode(!mode);
  };

  return (
    <header
      className={
        isClose ? `${style.header} ${style.closeHeader}` : style.header
      }
      style={{ paddingTop: `${headerType}` }}
    >
      {isBack && <BackIcon onClick={() => navigate(-1)} />}
      <h1>{t(title)}</h1>
      {isSelect && rightIcon && (
        <div className={style.icon} onClick={handleClickChangeMode}>
          {mode ? <CheckIcon /> : <TrashIcon />}
        </div>
      )}
      {isSelect && menus && select && onClick && (
        <div className={style.select}>
          <Select menus={menus} select={select && select} onClick={onClick} />
        </div>
      )}
      {isClose && onClick && (
        <p className={style.close} onClick={onClick}>
          <XSVG />
        </p>
      )}
    </header>
  );
};

export default Header;
