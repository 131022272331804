import style from "./RecommendedEX.module.scss";
import { ReactComponent as PlayButtonSVG } from "assets/icon/ic_play_button.svg";
import { secondsToHMS } from "utils/common";
import { useTranslation } from "react-i18next";

interface Props {
  onClick: () => void;
  data: any;
}

const RecommendedEXCard = ({ onClick, data }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={style.recommendedEXCard}>
      <p className={style.icon}>
        <img src={data?.image} alt="" />
      </p>
      <div className={style.infoArea}>
        <h4>{t(data?.name) || "-"}</h4>
        <p>
          {t("Mobile_Exercise_Details_ExerciseTime_0")}{" "}
          <b>{secondsToHMS(data?.time) || "00:00"}</b>
        </p>
      </div>
      <p className={style.playBtn} onClick={() => onClick()}>
        <PlayButtonSVG />
      </p>
    </div>
  );
};

export default RecommendedEXCard;
