import { Link } from "react-router-dom";
import style from "pages/join/step1/Step1.module.scss";
import DefaultBtn from "components/btns/defaultBtn/DefaultBtn";
import { ReactComponent as UserSvg2 } from "assets/icon/ic_user_verified_lined.svg";

const Step4 = () => {
  return (
    <div className={style.step1}>
      <section className={style.step1_1}>
        <p className={style.icon}>
          <UserSvg2 />
        </p>
        <p className={style.btn}>
          <Link to={"/login"}>
            <DefaultBtn text="Mobile_SignIn_SignIn_Button_0" />
          </Link>
        </p>
      </section>
    </div>
  );
};

export default Step4;
