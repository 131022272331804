import { useState, useEffect } from "react";
import style from "./UpBtn.module.scss";
import { ReactComponent as UpSVG } from "assets/icon/ic_chevron_up.svg";

const UpBtn = () => {
  const wh = typeof window !== "undefined" ? window.innerHeight || 1000 : 0;
  const [position, setPosition] = useState(0);

  const onScroll = () => {
    if (typeof window !== "undefined") setPosition(window?.scrollY);
  };

  const handelUp = () => {
    if (typeof window !== "undefined") window?.scroll(0, 0);
  };

  useEffect(() => {
    if (typeof window !== "undefined")
      window?.addEventListener("scroll", onScroll);
    return () => {
      if (typeof window !== "undefined")
        window?.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <>
      {position > wh ? (
        <p className={style.upBtn} onClick={handelUp}>
          <UpSVG />
        </p>
      ) : (
        <></>
      )}
    </>
  );
};

export default UpBtn;
