import { useState, useEffect } from "react";
import style from "./ChangeEmailModal.module.scss";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { restfetcher, QueryKeys, getClient } from "utils/queryClient";
import { validateEmail } from "utils/validate";
import { emails } from "datas/common";

import { ReactComponent as SuccessSVG } from "assets/icon/ic_chevron_check.svg";
import { ReactComponent as FailSVG } from "assets/icon/ic_warning_circle_lined.svg";

import ModalLayout from "../ModalLayout";
import TextInput from "components/inputs/textInput/TextInput";
import DefaultBtn from "components/btns/defaultBtn/DefaultBtn";
import Select from "components/selects/Select";

interface Props {
  email: string;
  handleClose: () => void;
  onChange: (type: string, value: string) => void;
}

const ChangeEmailModal = ({ email, handleClose, onChange }: Props) => {
  const queryClient = getClient();

  const { t } = useTranslation();
  const error = {
    type: "none",
    text: "",
  };
  const [selectEmail, setSelectEmail] = useState<DefaultKeyValue>(emails[0]);
  const [changeEmail, setChangeEmail] = useState({
    id: "",
    email: "",
  });
  const [errorState, setErrorState] = useState(error);
  const [sendEmial, setSendEmail] = useState("");

  const duplicatecheckEmailApi = useQuery({
    queryKey: [QueryKeys.DUPLICATECHECKEMAIL],
    queryFn: () =>
      restfetcher({
        method: "POST",
        path: "api/user/duplicatecheck",
        params: {
          type: "EMAIL",
          data: sendEmial,
        },
      }),
  });

  const updateemailApi = useQuery({
    queryKey: [QueryKeys.UPDATEEMAIL],
    queryFn: () =>
      restfetcher({
        method: "POST",
        path: "api/user/updateemail",
        params: {
          usremail: sendEmial,
        },
      }),
  });

  useEffect(() => {
    const { isSuccess, data } = duplicatecheckEmailApi;
    if (isSuccess && data?.result === "success") {
      const { isexist } = data;
      if (!isexist) {
        setErrorState({
          type: "success",
          text: "Mobile_UserInfo_Email_Validation_1_Text_0",
        });
      } else {
        setErrorState({
          type: "fail",
          text: "Mobile_UserInfo_Email_Validation_2_Text_0",
        });
      }
      queryClient.removeQueries({ queryKey: [QueryKeys.DUPLICATECHECKEMAIL] });
    }
  }, [duplicatecheckEmailApi.isSuccess, duplicatecheckEmailApi.data]);

  useEffect(() => {
    const { isSuccess, data } = updateemailApi;
    if (isSuccess && data?.result === "success") {
      const { usremail } = data;
      queryClient.removeQueries({ queryKey: [QueryKeys.UPDATEEMAIL] });

      setErrorState(error);
      onChange("usremail", usremail);
      setSendEmail("");
      setChangeEmail({
        id: "",
        email: "",
      });
      alert(t("Mobile_Common_Save_Pop_0"));
      handleClose();
    }
  }, [updateemailApi.isSuccess, updateemailApi.data]);

  useEffect(() => {
    setSendEmail(`${changeEmail.id}@${changeEmail.email}`);
    setErrorState(error);
  }, [selectEmail, changeEmail]);

  const handleDoubleCheckEmail = () => {
    const validate = validateEmail(sendEmial);
    let errorEmail = error;
    if (!validate) {
      errorEmail = {
        type: "fail",
        text: "Mobile_UserInfo_Email_Validation_2_Text_0",
      };
      return setErrorState(errorEmail);
    } else {
      duplicatecheckEmailApi.refetch();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "email") {
      if (selectEmail?.key === 0) {
        setChangeEmail({ ...changeEmail, email: value });
      } else {
        setChangeEmail({ ...changeEmail, email: selectEmail?.value });
      }
    } else {
      setChangeEmail({ ...changeEmail, id: value });
    }
  };

  const handleSubmit = () => {
    if (errorState.type === "success") {
      if (
        typeof window !== "undefined" &&
        window?.confirm(t("Mobile_UserInfo_Common_Edit_Pop_0"))
      ) {
        updateemailApi.refetch();
      }
    }
  };

  return (
    <ModalLayout
      handleClose={handleClose}
      title="Mobile_UserInfo_NewEmail_Pop_Title_0"
      handleSubmit={handleSubmit}
      rightAction={errorState.type === "success"}
    >
      <div className={style.changeEmailModal}>
        <div className={style.section}>
          <div className={style.item}>
            <p className={style.itemTitle}>
              {t("Mobile_UserInfo_CurrentEmail_0")}
            </p>
            <p className={style.nowEmail}>{email}</p>
          </div>
          <div>
            <div className={style.item}>
              <p
                className={
                  errorState.type === "fail"
                    ? `${style.itemTitle} ${style.failtext}`
                    : style.itemTitle
                }
              >
                {t("Mobile_UserInfo_NewEmail_0")} *
              </p>
              <TextInput
                value={changeEmail.id}
                name="select"
                handleChange={handleChange}
                placeholder="Mobile_UserInfo_Email_InsertBox_Text_0"
                parentStyle={{
                  backgroundColor: "rgba(0,0,0,0)",
                  height: "36px",
                }}
              />
              <div className={style.selectEmail}>
                <span className={style.emailIcon}>@</span>
                <TextInput
                  value={changeEmail.email}
                  name="email"
                  handleChange={handleChange}
                  placeholder="Mobile_Common_InsertBox_Text_2_0"
                  parentStyle={{
                    backgroundColor: "rgba(0,0,0,0)",
                    height: "36px",
                    width: "137px",
                  }}
                />
                <div className={`selectBackWhite ${style.select}`}>
                  <Select
                    menus={emails}
                    select={selectEmail}
                    onClick={(select: DefaultKeyValue) => {
                      setSelectEmail(select);
                      setChangeEmail({
                        ...changeEmail,
                        email: select.key === 0 ? "" : select.value,
                      });
                    }}
                  />
                </div>
              </div>
              <p className={`${style.emailText} ${style[errorState.type]}`}>
                {errorState.type === "success" && <SuccessSVG />}
                {errorState.type === "fail" && <FailSVG />}
                {t(errorState.text)}
              </p>
            </div>
            <p className={style.checkBtn}>
              <DefaultBtn
                text="Mobile_UserInfo_DuplicatiateCheck_Button_0"
                onClick={handleDoubleCheckEmail}
              />
            </p>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default ChangeEmailModal;
