import { restfetcher } from "./queryClient";

export const passCertification = async () => {
    const pathname = window.location.pathname;
    await restfetcher({
        method: "POST",
        path: "v1/auth/pass",
        params: {
            path: "/join/step1"
        },
    }).then(response => {
        if(response){
            const { trcert, trurl, tradd } = response;
            if (trcert && trurl && tradd) {
                passView(trcert, trurl, tradd);
            }
        }
    }
    );
}

let kmcWindow: any = null;
export const passView = async (trcert: string, trurl: string, tradd: string) => {
    var UserAgent = navigator.userAgent;
    var kmcForm = document.createElement("form");
    kmcForm.className = "kmcForm"
    // if (UserAgent.match(
    //     /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i
    // ) != null ||
    //     UserAgent.match(/LG|SAMSUNG|Samsung/) != null) {
    //     kmcForm.target = "";
    // } else {
    //     kmcForm.target = "KMCISWindow";
    // }

    kmcForm.method = "POST";
    kmcForm.action = "https://www.kmcert.com/kmcis/web/kmcisReq.jsp";

    var trcertInput = document.createElement("input");
    var trurlInput = document.createElement("input");
    var traddInput = document.createElement("input");

    trcertInput.type = "hidden";
    trcertInput.name = "tr_cert";
    trcertInput.value = trcert;

    trurlInput.type = "hidden";
    trurlInput.name = "tr_url";
    trurlInput.value = trurl;

    traddInput.type = "hidden";
    traddInput.name = "tr_add";
    traddInput.value = tradd;
    // kmcInput.value = data;
    kmcForm.appendChild(trcertInput);
    kmcForm.appendChild(trurlInput);
    kmcForm.appendChild(traddInput);

    document.body.appendChild(kmcForm);


    var target = "kmcIframe"//new Date().getTime()??.toString();

    const kmcIframeWrap = document.getElementById("kmcIframeWrap");
    
    if (kmcIframeWrap) {
        kmcIframeWrap.innerHTML = `
      <iframe id="kmcIframe" name=${target} style="width:100%; height:693px;">
      </iframe>`;
      
    //   kmcIframeWrap.addEventListener('message', (e) => {
    //     console.log('2. iframe DOMContentLoaded',  e)
    //   })
        kmcForm.target = target;
        kmcForm.submit();
    }


    // if (UserAgent.match(
    //     /iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i
    // ) != null ||
    //     UserAgent.match(/LG|SAMSUNG|Samsung/) != null) {

    //     var target = new Date().getTime()??.toString();
    //     document.getElementById("#kmcIframeWrap")?.append('<iframe name="' + target + '" style="width:100%; height:693px;"></iframe>');
    //     kmcForm.target = target;
    //     kmcForm.submit();
    // } else {
    //     kmcWindow = window.open("", "KMCISWindow", "status=0,title=0,height=660,width=400,scrollbars=1");
    //     kmcForm.submit();
    // }

}

export function postMessage(data: any) {
    const result = document.getElementById("result");
    if (result) result.textContent = '값: ' + data;
}



export const closeWindow = () => {
    if (kmcWindow) {
        kmcWindow.close();
    }
}
