import { useState, useEffect, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { restfetcher, QueryKeys, getClient } from "utils/queryClient";
import style from "./Exercise.module.scss";
import RecommendedEX from "components/resultCards/recommendedEX/RecommendedEX";
import Nodata from "components/nodata/Nodata";
import VideoView from "./VideoView";
import InfiniteScroll from "components/infiniteScroll/InfiniteScroll";
import UpBtn from "components/btns/upBtn/UpBtn";
import VideoExCard from "components/resultCards/videoExCard/VideoExCard";
import useBodyScrollLock from "hooks/useBodyScrollLock";

const VideoEx = ({ select = -1 }) => {
  const queryClient = getClient();

  const listRef = useRef<HTMLLIElement>(null);
  const token =
    typeof sessionStorage !== "undefined"
      ? sessionStorage?.getItem("token")
      : null;
  const [page, setPage] = useState(1);
  const [totalpage, setTotalpage] = useState(0);
  const [datas, setDatas] = useState<any[] | null>(null);
  const [video, setVideo] = useState<null | any>(null);
  const { lockScroll, openScroll } = useBodyScrollLock();

  const kemaexerciseApi = useQuery({
    queryKey: [QueryKeys.KEMALIST],
    queryFn: () =>
      restfetcher({
        method: "POST",
        path: "v1/app/exercise",
        params: {
          sort: select,
          page: page,
        },
      }),
  });

  useEffect(() => {
    if (
      page > 0 &&
      !kemaexerciseApi.isLoading &&
      (totalpage > page || totalpage === page) &&
      token
    ) {
      kemaexerciseApi.refetch();
    }
  }, [page]);

  useEffect(() => {
    setDatas(null);
    if (token) {
      queryClient.removeQueries({ queryKey: [QueryKeys.KEMALIST] });
    }
    if (page === 1) {
      if (token) kemaexerciseApi.refetch();
    } else {
      setPage(1);
    }
  }, [select]);

  useEffect(() => {
    const { isSuccess, data } = kemaexerciseApi;
    if (isSuccess && data?.result === "success") {
      const { exerciseList, totalpage } = data;
      let newData = datas === null ? exerciseList : datas?.concat(exerciseList);
      setTotalpage(totalpage);
      setDatas(newData);
      queryClient.removeQueries({ queryKey: [QueryKeys.KEMALIST] });
    }
  }, [kemaexerciseApi.isSuccess, kemaexerciseApi.data]);

  const rowChildren = (data: any) => {
    return (
      <RecommendedEX
        data={data}
        onClick={setVideo}
        key={`${data.bodypart}_${data.measureidx}`}
        liLength={1}
        liHeight={260}
      >
        <li className={style.list} ref={listRef} onClick={() => setVideo(data)}>
          <VideoExCard data={data} />
        </li>
      </RecommendedEX>
    );
  };

  useEffect(() => {
    if (video) {
      lockScroll();
    } else {
      openScroll();
    }
  }, [video]);

  return (
    <div className={style.recommend}>
      {token ? (
        datas ? (
          datas?.length > 0 ? (
            <section>
              <InfiniteScroll
                datas={datas}
                page={page}
                setPage={setPage}
                refetch={() => kemaexerciseApi.refetch()}
                rowChildren={rowChildren}
              />
              {video && (
                <VideoView
                  programIdx={video?.programIdx}
                  handleClose={() => setVideo(null)}
                  libraryGroup={video?.libraryGroup}
                />
              )}
              <UpBtn />
            </section>
          ) : (
            <Nodata text="Mobile_Exercise_Details_0_Announcement_1_0" />
          )
        ) : (
          <section></section>
        )
      ) : (
        <Nodata text="Mobile_Exercise_Details_0_Announcement_1_0" />
      )}
    </div>
  );
};

export default VideoEx;
