import { useEffect } from "react";
import style from "./Pass.module.scss";
import { useLocation } from "react-router-dom";
import { passCertification } from "utils/certification";
import BackHeader from "components/header/Backheader";
import { useGlobalContext } from "context/store";
import { defaultPassData } from "datas/defaultdata";

const Pass = () => {
  const { setPassUserInfo } = useGlobalContext();
  const { pathname } = useLocation();
  const headerType = localStorage.getItem("headerType");

  useEffect(() => {
    if (pathname !== "/") {
      passCertification();
      setPassUserInfo(defaultPassData);
    }

    return () => {
      const kmcIframeWrap = document.getElementById("kmcIframeWrap");
      const kmcForms = document.querySelectorAll(".kmcForm");
      if (kmcIframeWrap) {
        kmcIframeWrap.innerHTML = ``;
      }
      if (kmcForms) {
        kmcForms.forEach((form) => {
          form.remove();
        });
      }
    };
  }, []);

  return (
    <div
      className={style.pass}
      style={{ marginTop: `${headerType === "60px" ? "101px" : "61px"}` }}
    >
      <BackHeader />
    </div>
  );
};

export default Pass;
