import { useState } from "react";
import style from "./EquipmentInfo.module.scss";
import { useTranslation } from "react-i18next";
import { ReactComponent as AddSVG } from "assets/icon/ic_add_circle_lined.svg";
import { ReactComponent as RemoveSVG } from "assets/icon/ic_remove_circle_lined.svg";

const EquipmentInfo = ({ data }: { data: EquipmentDataType | null }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <div className={style.equipmentInfo}>
      {data ? (
        <>
          <div className={style.topArea}>
            <p className={style.icon}>
              <img src={data?.icon} alt="" />
            </p>
            <div className={style.title}>
              <h4>{t(data?.title)}</h4>
              <p>{t(data.subtitle)}</p>
            </div>
            <p className={style.openIcon} onClick={() => setOpen(!open)}>
              {open ? <RemoveSVG /> : <AddSVG />}
            </p>
          </div>
          <div
            className={style.bottomArea}
            style={{
              minHeight: open ? "106px" : "0px",
              paddingTop: open ? "1em" : "0px",
            }}
          >
            <p
              className={style.img}
              style={{
                visibility: open ? "visible" : "hidden",
                height: open ? "auto" : "0px",
              }}
            >
              <img src={data?.img} alt="" />
            </p>
            {open && <p className={style.text}>{t(data?.text)}</p>}
          </div>
        </>
      ) : (
        <div className={style.topArea}>
          <p className={`${style.icon} loadingBack`}></p>
          <div className={style.title}>
            <h4></h4>
            <p></p>
          </div>
          <p className={style.openIcon} onClick={() => setOpen(!open)}>
            {open ? <RemoveSVG /> : <AddSVG />}
          </p>
        </div>
      )}
    </div>
  );
};

export default EquipmentInfo;
