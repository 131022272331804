import { useRef } from "react";
import style from "./Select.module.scss";
import { ReactComponent as CheckSVG } from "assets/icon/ic_nav_arrow_down.svg";
import { useTranslation } from "react-i18next";

interface Props {
  menus: DefaultKeyValue[];
  select: DefaultKeyValue | null;
  onClick: (() => void) | ((select: DefaultKeyValue) => void) | any;
}

const Select = ({ menus, select, onClick }: Props) => {
  const { t } = useTranslation();
  const selectRef = useRef<HTMLDivElement>(null);

  const onSelect = (value: string) => {
    const select = menus.filter((m) => m.value === value)[0];
    onClick(select);
  };

  return (
    <div className={style.select} ref={selectRef}>
      <CheckSVG />
      <select
        placeholder={t("Mobile_Common_Select_Text_0")}
        value={select?.value}
        onChange={(event) => onSelect(event.target.value)}
      >
        {menus?.map((data) => (
          <option key={data.key} value={data.value}>
            {t(data.value)}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
