import style from "./Terms.module.scss";

const Terms1Jp = () => {
  return (
    <div className={style.terms}>
      <h2>第1章 総則</h2>
      <br />
      <h3>第1条 (目的)</h3> <br />
      この約款は、株式会社エクスボディ（以下「会社」）が提供するモバイルアプリサービス（以下、「サービス」）の利用条件に関連して会社と会員間の権利、義務及び責任事項、その他必要事項を規定することを目的とします。
      <br />
      <h3>第2条 (用語の定義)</h3> <br />
      ①この約款で使用する定義は次のとおりです。
      <br />
      1. 「会社」とは、モバイルアプリサービスを提供する事業者を意味します。
      <br />
      2.「利用者」とは、本サービスにアクセスし、本規約に従って会社が提供するサービスを利用する会員および非会員を意味します。
      <br />
      3.「会員」とは、本規約に同意し、サービス利用資格を与えられた者を意味します。
      <br />
      4.「サービス」とは、会社が会員にモバイルアプリを通じて提供する諸サービスを意味します。
      <br />
      5.「アカウント
      (ID)」とは、会員の識別とサービス利用のために会員が選定し、会社が付与する文字、数字または特殊文字の組み合わせを意味します。
      <br />
      6.
      「アカウント情報」とは、会員のアカウント、パスワード、氏名など会員が会社に提供した一般情報およびサービスを利用するための利用情報、利用料金決済状態など生成情報を通称します。
      <br />
      7.「パスワード」とは、会員が付与されたアカウントと一致する会員であることを確認し、会員の情報および権益保護のために会員自身が選定して秘密に管理する文字、数字または特殊文字の組み合わせを意味します。
      <br />
      8.
      「ポイント」とは、会員が別途購入費用を納付することなく保有することになった仮想通貨を意味し、会社が進行するイベント条件を達成して付与することができます。
      <br />
      9.
      「有料コンテンツ」とは、利用者がサービス内で使用または所持して特定の効果または効能を享受できるオンラインコンテンツのうち有料で購入したことを意味します。
      <br />
      10.「掲示物」とは、会員がサービスを利用する際にサービスに掲示した文字、文書、絵、音声、映像、またはこれらの組み合わせからなるすべての情報を意味します。
      <br />
      11.
      「提携サービス」とは、会社の「サービス」と連動するサービスであり、「提供者」が提供する個別サービスなどのように会社と提携関係にある第三者が運営するサービスを意味します。
      <br />
      ②
      この約款で使用する用語のうち第1項で定めないものは、関係法令及び一般相関例に従います。
      <br />
      <br />
      <h3>第3条 (約款の明示と改正)</h3> <br />
      ①
      会社は、この約款の内容と相互及び代表者氏名、営業所所在地住所（消費者の不満を処理できる所の住所を含む）、電話番号・模写伝送番号・電子郵便住所、事業者登録番号、通信販売業申告番号、個人情報取扱方針などを会員がわかりやすくするためにサービス初期画面に掲示します。ただし、約款の内容及び個人情報取扱方針は、会員が連結画面を通じて見ることができるようにすることができます。
      <br />
      ②
      会社は、会員が会社とこの約款の内容について質疑及び応答を行うことができるように措置をとります。
      <br />
      ③
      会社は、利用者が約款に同意する前に約款に定められている内容のうち、請約撤回、過誤納金の還付、契約解除・解除、会社の免責事項及び会員に対する被害補償などの重要な内容を利用者が容易に理解できるように太字などで処理したり、別途の接続画面やポップアップ画面などを提供して利用者の同意を得るようにします。
      <br />
      ④
      会社は「電子商取引等における消費者保護に関する法律」、「約款の規制に関する法律」、「情報通信網利用促進及び情報保護等に関する法律」、「コンテンツ産業振興法」、「消費者基本法」など関連法令に違反しない範囲でこの規約を改訂することができます。
      <br />
      ⑤
      会社が約款を改正する場合には、適用日時及び改正内容、改正理由等を明示し、その適用日から少なくとも7日前(会員に不利又は重大な事項の変更は30日前)からその適用日経過後相当期間が経過するまで初期画面または初期画面との接続画面でお知らせします。この場合、会社は改訂前の内容と改訂後の内容を明確に比較し、会員がわかりやすく表示する。
      <br />
      ⑥
      会社が約款を改正する場合には、改正約款告知後、改正約款の適用に対する会員の同意を確認します。改正約款告知の際、会員が同意または拒否の意思表示をしなければ承諾したとみなすという内容も一緒に公知した場合、会員が約款施行日まで拒否の意思を表示しなければ改正約款に同意したものとみなすことができます。
      <br />
      ⑦
      会員が改正約款の適用に同意しない場合、会社または会員はいつでもサービス利用契約を解除することができます。
      <br />
      <br />
      <h3>第4条 (約款外準則)</h3> <br />
      この約款で定めない事項及びこの約款の解釈に関しては、
      「電子商取引等における消費者保護に関する法律」、
      「約款の規制に関する法律」、 「ゲーム産業振興に関する法律」 、
      「情報通信網利用促進及び情報保護等に関する法律」、
      「コンテンツ産業振興法」など関連法令又は相関例に従います。
      <br />
      <br />
      <h3>第5条 (運営政策)</h3> <br />
      ①
      約款を適用するために必要な事項と会員の権益を保護し、サービス秩序維持のために会社は約款で具体的な範囲を定めて委任した事項をサービス運営政策（以下「運営政策」）で決めることができます。
      <br />
      ②
      会社は、運営政策の内容を会員が知ることができるように初期画面やサービスホームページに掲示したり、連結画面を提供する方法で会員に公知しなければなりません。
      <br />
      ③
      会員の権利又は義務に重大な変更をもたらしたり、約款内容を変更するのと同じ効力が発生する運営政策改正の場合には、第3条の手続に従います。ただし、運営政策改正が次の各号のいずれかに該当する場合には、第2項の方法で事前に公知します。
      <br />
      1. 約款で具体的に範囲を定めて委任した事項を改正する場合
      <br />
      2. 会員の権利・義務と関係のない事項を改正する場合
      <br />
      3.
      運営政策の内容が約款で定めた内容と根本的に異なっておらず、会員が予測可能な範囲内で運営政策を改正する場合
      <br />
      <br />
      <br />
      <h2>第2章 利用契約の締結</h2>
      <br />
      <h3>第6条 (利用申請及び方法)</h3> <br />
      ①
      利用者は、会社が定める様式に従って利用申請書を作成する方法で利用申請をしなければなりません。
      <br />
      ②
      利用者は、利用申請時に携帯電話番号、氏名、電子メールアドレスなどその他の会社が必要と認める情報を提供しなければなりません。
      <br />
      ③
      利用者は、第1項の利用申請時に本人の実名及び実際の情報を記載しなければなりません。実名または識別情報を虚偽で記載したり、他人の名義を盗用した場合、この約款による会員の権利を主張することができず、会社は利用契約を取り消したり解除することができます。
      <br />
      ④ 青少年
      (18歳未満の者として「小・中等教育法」第2条の規定による高校に在学中の学生を含む)
      が利用申請をする場合には、法定代理人の同意を得なければならず、具体的な同意手続は関連法令に従って会社が提供する方法に従うようにします。
      <br />
      <br />
      <h3>第7条 (利用申請の承諾と制限)</h3> <br />
      ①
      会社は、会社が利用者に要求する情報に対して、利用者が実名及び実際の情報を正確に記載して利用申請をした場合に相当な理由がない限り、利用申請を承諾します。
      <br />
      ②
      会社は、次の各号のいずれかに該当する利用申請については承諾をしないことがあります。
      <br />
      1.第6条に違反して利用申請をする場合
      <br />
      2. 利用料金を納付しなかったり、誤って納付して確認できない場合
      <br />
      3. 青少年
      (第7条第4項の青少年と同じ)が法定代理人の同意を得なかったり、同意を得たことを確認できない場合
      <br />
      4. 最近3ヶ月以内に利用制限記録がある利用者が利用申請をする場合
      <br />
      5. 既に利用契約が締結された会員の場合
      <br />
      6.
      第三者のクレジットカード、有/無線電話、銀行口座等を無断で利用又は盗用してサービス利用料金を決済する場合
      <br />
      7.
      大韓民国以外の国のうち、会社がまだサービスを提供すると決定していない国でサービスを利用する場合で、会社が海外サービス業者と締結した契約や特定国で接続する会員に対するサービス提供に関連してサービス提供を制限する必要がある場合
      <br />
      8.
      「ゲーム産業振興に関する法律」、「情報通信網利用促進及び情報保護等に関する法律」及びその他の関係法令で禁止する違法行為をする目的で利用申請をする場合
      <br />
      9. その他1号～7号に準ずる事由として承諾が不適切と判断される場合
      <br />
      ③会社は、次の各号のいずれかに該当する場合には、その事由が解消されるまで承諾を留保することができます。
      <br />
      1. 会社の設備に余裕がない、または技術的障害がある場合
      <br />
      2. サービス上の障害またはサービス利用料金決済手段の障害が発生した場合
      <br />
      3. その他、上記各号に準ずる事由として利用申請の承諾が困難な場合
      <br />
      ④ 利用契約の成立時期は、会社の承諾が会員に到達した時点とします。
      <br />
      <br />
      <h3>第8条 (会員アカウント及びパスワード)</h3> <br />
      ①
      会社は会員に対して会員の情報保護、サービス利用案内等の便宜のために会員が選定した一定の文字、数字又は特殊文字の組合せを口座に付与します。
      <br />
      ②
      会社はアカウント情報を通じて当該会員のサービス利用可否等の諸会員管理業務を遂行します。
      <br />
      ③
      会員は、自分のアカウント情報を善良した管理者としての注意義務を果たして管理しなければなりません。会員が本人のアカウント情報を無視して管理したり、第三者に利用を承諾したことにより発生する損害については、会員に責任があります。
      <br />
      ④
      パスワードの管理責任は会員にあり、会員が望む場合にはセキュリティ上の理由などでいつでも変更が可能です。
      <br />
      ⑤ 会員は定期的にパスワードを変更しなければなりません。
      <br />
      <br />
      <h3>第9条 (9会員情報の提供及び変更)</h3> <br />
      ①
      会員は、この約款により会社に情報を提供しなければならない場合には、真実な情報を提供しなければならず、虚偽情報提供により発生した不利益については保護されません。
      <br />
      ②
      会員は個人情報管理画面を通じていつでも自分の個人情報を閲覧して修正することができます。ただし、サービス管理のために必要な本名、アカウント
      (ID)等は、会社の同意がない限り修正が不可能です。
      <br />
      ③
      会員は、会員登録申請時に記載した事項が変更された場合、オンラインで修正をしたり、その他の方法で会社に対してその変更事項を知らせなければなりません。
      <br />
      ④
      第2項の変更事項を会社に知らせず発生した不利益に対して会社は責任を負いません。
      <br />
      <br />
      <h3>第10条 (個人情報の保護及び管理)</h3> <br />
      ①
      会社は、個人情報保護法等関係法令が定めるところにより会員の個人情報を保護するために努力します。会員個人情報の保護及び使用については、関係法令及び会社が別途告知する個人情報取扱方針が適用されます。
      <br />
      ②
      サービスの一部として提供される個別サービスを除いたものとして、ホームページ及びサービス別ウェブサイトで単にリンクされた第三者提供のサービスについては、会社の個人情報取扱方針が適用されません。
      <br />
      ③
      会社は会員の帰責事由により露出された会員のアカウント情報を含むすべての情報に対して一切の責任を負いません。
      <br />
      <br />
      <br />
      <h2>第3章 契約当事者の義務</h2>
      <br />
      <h3>第11条 (会社の義務)</h3> <br />
      ①
      会社は関連法令を遵守し、この約款が定める権利の行使と義務の履行を信義により誠実にします。
      <br />
      ②
      会社は会員が安全にサービスを利用できるように個人情報（信用情報を含む）保護のためにセキュリティシステムを備えなければならず、個人情報取扱方針を公示して遵守します。当社は、本規約及び個人情報取扱方針で定めた場合を除き、会員の個人情報が第三者に開示又は提供されないようにします。
      <br />
      ③会社は継続的かつ安定的なサービスの提供のためにサービス改善を行っている間、設備に障害が生じたり、データ等が滅失したときは、天災地変、緊急事態、現在の技術では解決できない欠陥及び障害などやむを得ない事由がない限り遅滞なくこれを修理または修復するために最善の努力を尽くします。
      <br />
      <br />
      <h3>第12条 (会員の義務)</h3> <br />
      ①
      会員は、次の行為をしてはならない。会員が次の各号の行為をする場合、会社はこの約款及び法令等に応じて適切な措置を取ることができます。
      <br />
      1. 申請又は変更時の虚偽内容の記載
      <br />
      2. 他人の情報盗用
      <br />
      3. 会社の役職員、運営者、その他関係者を詐称する行為
      <br />
      4. 会社が掲示した情報の変更
      <br />
      5. 会社が禁止した情報（コンピュータプログラム等）の送信又は掲示
      <br />
      6.
      会社が提供または承認しないコンピュータプログラムまたは機器または装置を製作、配布、利用、広告する行為
      <br />
      7. 会社及びその他第三者の著作権等知的財産権に対する侵害
      <br />
      8. 会社及びその他第三者の名誉を損なったり、業務を妨害する行為
      <br />
      9.
      わいせつ又は暴力的な言葉や文、火傷、音響、その他公序良俗に反する情報を公開又は掲示する行為
      <br />
      10.
      会社の同意なしに営利、営業、広告、政治活動などを目的にサービスを使用する行為
      <br />
      11. その他関連法令で禁止または善良な風俗その他社会通念上、許されない行為
      <br />
      ②
      会員は、この約款の規定、利用案内及びサービスに関連して公知の注意事項、会社が通知する事項等を確認し、遵守する義務があります。
      <br />
      ③
      会社は、第1項、第2項及び次の各号のいずれかに該当する行為の具体的な種類を運営政策で定めることができ、会員はこれを遵守する義務があります。
      <br />
      1. 会員のアカウント名、キャラクター名に対する制限
      <br />
      2. チャット内容と方法に関する制限
      <br />
      3. 掲示板の利用に関する制限
      <br />
      4. サービスの利用方法に関する制限
      <br />
      5.
      他の会員のサービス利用に対する本質的権利を侵害しない範囲内で会社がサービス運営上必要と認められる事項
      <br />
      <br />
      <br />
      <h2>第4章 サービス利用</h2>
      <br />
      <h3>第13条 (サービスの変更及び内容修正)</h3> <br />
      ①
      会員は、会社が提供するサービスをこの約款、運営政策及び会社が設定した規則に従って利用することができます。
      <br />
      ②
      会社がサービス内容の製作、変更、維持、保守に関する包括的な権限を有します。
      <br />
      ③会社が相当な理由がある場合に運営上、技術上の必要に応じてサービス修正（パッチ）を行うことができ、サービス修正（パッチ）をする場合には変更後該当サイト等を通じて公知します。
      <br />
      <br />
      <h3>第14条 (サービスの提供及び中断等)</h3> <br />
      ①
      サービスは、会社の営業方針により定められた時間の間提供します。会社はサービス提供時間をサービスホームページに適切な方法で案内します。
      <br />
      ②
      第1項にもかかわらず、次の各号のいずれかに該当する場合には、一定の時間の間サービスが提供されないことがあり、当該時間の間、会社はサービスを提供する義務がありません。
      <br />
      1.
      コンピュータ等情報通信設備の保守点検、交換、定期点検又はサービス修正のために必要な場合
      <br />
      2.
      ハッキング等の電子的侵害事故、通信事故、会員の異常なサービス利用行動、未だ予期しないサービスの不安定性に対応するために必要な場合
      <br />
      3. 関連法令で特定の時間又は方法でサービス提供を禁止する場合
      <br />
      4.
      天災地変、緊急事態、停電、サービス設備の障害又はサービス利用の暴走等で正常なサービス提供が不可能な場合
      <br />
      5.
      会社の分割、合併、営業譲渡、営業の廃止、当該サービスの収益の悪化など会社の経営上重大な必要による場合
      <br />
      ③
      会社は第2項第1号の場合、毎週または隔週単位で一定時間を定めてサービスを中止することができます。この場合、会社は少なくとも24時間前にその事実を会員に初期画面に通知します。
      <br />
      ④
      第2項第2号の場合、会社は事前告知なしにサービスを一時停止することができます。当社は、そのような場合、その事実をサービスホームページに事後告知することができます。
      <br />
      ⑤
      会社は、会社が提供する無料サービスの利用に関して、利用者に発生したいかなる損害についても責任を負いません。ただし、会社の故意または重大な過失により生じた損害の場合は除きます。
      <br />
      ⑥
      会社は、会社が提供する有料サービスの利用に関連して会社の帰責事由で事前告知なしに1日4時間
      (累積時間)
      以上連続してサービスが中断または障害が発生した場合、継続的利用契約アカウントに限り、サービス中止・障害時間の3倍に該当する利用時間を無料で延長し、利用者は会社に対して別途の損害賠償を請求することはできません。ただし、会社がサーバ点検等の事由でサービス中止・障害を事前に告知したが、サービス中止・障害時間が10時間を超える場合には、その超過時間だけ利用時間を無料で延長し、利用者は会社に別途損害賠償を請求することはできません。
      <br />
      ⑦
      第2項第3号～第5号の場合において、会社は技術上・運営上の必要によりサービスすべてを中断することができ、30日前にホームページにこれを公知してサービスの提供を中断することができます。事前に通知できないやむを得ない事情がある場合は、事後に通知することができます。
      <br />
      ⑧
      会社が第7項によりサービスを終了する場合、会員は無料サービス及び使用期間が残っていない有料サービス、継続的有料利用契約及び期間制有料アイテムに対して損害賠償を請求することはできません。
      <br />
      <br />
      <h3>第15条 (情報の提供)</h3> <br />
      会社は、次の事項をサービス初期画面やインターネットウェブサイトであるサービスホームページに会員がわかりやすく表示します。
      <br />
      1. 相互
      <br />
      2. 利用等級
      <br />
      3. 等級分類番号
      <br />
      4. 製作年月日
      <br />
      5. その他会社が必要と認める事項
      <br />
      <br />
      <h3>第16条 (情報の収集等)</h3> <br />
      ①
      会社は会員の同意の下、サービス内で会員間で行われるチャット内容を保存・保管することができます。当社は、会員間の紛争調整、苦情処理またはサービス秩序維持のために会社が必要と判断した場合に限り、本情報を閲覧するようにし、本情報は会社のみが保有し、法令で権限を与えられない第三者は決して閲覧できません。
      <br />
      ②
      会社は該当情報を閲覧する前にチャット情報の閲覧が必要な事由及び閲覧範囲を個人に事前告知することとします。
      <br />
      ③
      第2項にもかかわらず、会員のアカウント盗用、現金取引、言語暴力、詐欺等の欺瞞行為、バグ悪用、その他現行法令違反行為及びこの約款第12条で定める重大な約款違反行為の調査、処理、確認及び異議申し立てに関して会員のチャット情報を閲覧する必要がある場合には、事後にチャット情報が閲覧された個人に対して閲覧した事由と閲覧した情報のうち、本人に関連する部分を告知するものとします。
      <br />
      ④
      会社はサービス運営及びプログラム安定化などサービス品質改善のために会員PC等端末設定及び仕様情報を収集・活用することができます。
      <br />
      <h3>第17条 (利用商品) </h3>
      ①
      会社は、会員が別途費用支給なく利用できるサービス（以下「無料サービス」）と、会社があらかじめ策定した料金を支払って利用するサービス（以下「有料サービス」）を提供することができる会員はサービスを選択して利用できます。
      <br />
      ②
      サービスの利用に対する代金支払方法は、会社があらかじめ定めた方法を通じて行うことができます。
      <br />
      ③
      第1項の有料サービス利用に関連して、会員が選択した有料サービス利用申請後、次の各号に該当する事項を遵守しなければなりません。
      <br />
      1.
      有料サービスの利用を申請した会員は、サービス利用料金を誠実に納付しなければなりません。
      <br />
      2.
      有料サービスの利用を申請した会員は、サービス利用料金を申請後、会社が提供する方法で支給しなければなりません。
      <br />
      ④
      会社は未成年者である会員が決済が必要な有料サービスを利用しようとする場合、親等法定代理人の同意を得なければならず、同意なしに行われた有料サービスの利用は、法定代理人が取り消すことができるという内容を有料サービス利用のための決済前に告知してください。
      <br />
      <br />
      <h3>第18条 (無料ポイント)</h3> <br />①
      会社は会員のコンテンツ利用実績により無料ポイントを提供することができ、会社が定めた有効期間内に使用されていない無料ポイントは消滅することがあります。
      <br />
      ②
      無料ポイントの獲得に誤りがある場合、会員は相当期間内に訂正申請をすることができ、会社は確認手続きを経た後、結果について告知し、必要な場合は無料ポイントに訂正措置を取ることができます。
      <br />
      ③
      会社は経営上、技術上の理由で事前告知後無料ポイントサービスを終了することができ、会社は事前告知を少なくとも1ヶ月前にしなければなりません。この場合、事前に通知されたサービスの終了日までに使用されなかった予期された無料ポイントは消滅します。
      <br />
      <br />
      <h3>第19条 (知的財産権の帰属及び利用制限)</h3> <br />①
      サービス内会社が製作したコンテンツに対する著作権その他知的財産権は会社の所有です。
      <br />
      ②
      会員は、サービスを利用して得た情報のうち、会社又は提供業者に知的財産権が帰属した情報を会社又は提供業者の事前承諾なしに複製、転送、出版、配布、放送その他の方法により営利目的に利用し、又は第3者に利用させてはいけません。
      <br />
      ③
      会員は、サービスに関連して会員または他の利用者がサービスを通じてアップロードまたは送信する会話テキストを含むコミュニケーション、画像、サウンドおよびすべての資料および情報（以下「利用者コンテンツ」）について、会社が以下の方法および条件で利用することを許します。
      <br />
      1. 当該利用者コンテンツを利用、編集形式の変更及びその他変形するもの
      (公表、複製、公演、配信、配信、放送、二次的著作物作成等、いかなる形態でも利用可能であり、利用期間及び地域に制限はない。
      )
      <br />
      2.
      利用者コンテンツを製作した利用者の事前同意なしに取引を目的に利用者コンテンツを販売、貸与、譲渡行為をしない
      <br />
      ④
      サービスと一体化されていない会員の利用者コンテンツ（例えば、一般掲示板等への掲示物）について
      会社は会員の明示的な同意なしに商業的に利用せず、会員はいつでもこれらの利用者コンテンツを削除することができます。
      <br />
      ⑤
      会社は会員が掲示又は登録するサービス内の掲示物、掲示内容について第12条で規定する禁止行為に該当すると判断される場合、事前通知なくこれを削除したり、移動又は登録を拒否することができます。
      <br />
      ⑥
      会社が運営する掲示板等に掲示された情報により法律上利益が侵害された会員は、会社に当該情報の削除又は反泊内容の掲載を要請することができます。この場合、会社は迅速に必要な措置を講じ、それを申請者に通知します。
      <br />
      ⑦
      第3項は、会社がサービスを運営している間有効であり、会員脱退後も継続的に適用されます。
      <br />
      <br />
      <br />
      <h2>第5章 請約撤回、契約解除・解除及び利用制限</h2>
      <br />
      <h3>第20条 (請約の撤回)</h3> <br />①
      会社と有料サービス利用に関する契約を締結した会員は、購入日又は有料サービス利用可能日から7日以内には、約束の撤回をすることができます。
      <br />
      ②
      会員は、次の各号のいずれかに該当する場合には、会社の意思に反して第1項による請約撤回等をすることはできません。
      <br />
      1. 会員に責任のある事由で財貨等が滅失又は毀損された場合
      <br />
      2. 会員が財を使用または一部消費した場合
      <br />
      3. 時間が経って再び販売が困難な場合
      <br />
      4. 複製可能な財などの包装を毀損した場合
      <br />
      5. 他の取引の安全のために法令で定める場合
      <br />
      ③
      会社は、第2項第2号から4号まで規定により請約撤回等が不可能な財貨等の場合には、その事実を財貨等の包装やその他会員が容易に知ることができる所に明確に適時したり、試験使用商品を提供するなどの方法で請約撤回などの権利行使が妨げられないように措置しなければなりません。もし会社がこのような措置をしなかった場合には、第2項第2号～第4号の請約撤回制限事由にもかかわらず、会員は請約撤回をすることができます。
      <br />
      ④
      会員は、第1項から第3項までの規定にかかわらず、有料サービスの内容が表示・広告内容と異なったり、契約内容と異なって履行された場合には、購入日又は有料サービス利用可能日から3月以内、その事実を知らない日または分かった日から30日以内に請約撤回をすることができます。
      <br />
      ⑤
      会員は口頭または書面（電子文書を含む）、電子メールで請約撤回をすることができます。
      <br />
      <br />
      <h3>第21条(請約撤回等の効果)</h3>
      <br />
      <br />
      ①
      会員が請約撤回をした場合、会社は遅滞なく会員の有料サービスを回収または削除し、有料サービスを回収または削除した日から3営業日以内に支払われた代金を返金します。
      <br />
      ②
      この場合、会社が会員に還付を遅延したときは、その遅延期間に対して電子商取引等における消費者保護に関する法律及び施行令で定める利率を乗じて算定した遅延利子を支給します。
      <br />
      ③
      会社は上記代金を還付するにあたり、会員がクレジットカードやその他電子商取引等での消費者保護に関する法律施行令で定める決済手段で代金を支給したときは、遅滞なく当該決済手段を提供した事業者に代金の請求を停止またはキャンセルするように要求します。ただし、会社が決済業者から既に代金を支給されたときは、これを決済業者に返金し、これを消費者に通知します。
      <br />
      ④
      会社は既に財貨等が一部使用又は一部消費された場合には、その財貨等の一部使用又は一部消費により会員が得た利益又はその財貨等の供給にかかる費用に相当する金額を会員に請求することができます。
      <br />
      ⑤
      請約撤回をした場合財貨等の返還に必要な費用は会員が負担し、会社は会員に請約撤回を理由に違約金又は損害賠償を請求することができません。ただし、財貨等の内容が表示・広告内容と異なったり、契約内容と異なって移行して請約撤回をする場合、財貨等の返還に必要な費用は会社が負担します。
      <br />
      <br />
      <h3>第22条 (会員の解除及び解約)</h3> <br />①
      会員は、サービス利用契約を解除（以下「会員脱退」）することができます。会員が会員脱退を申請した場合、会社は会員本人であるかどうかを確認することができ、当該会員が本人で確認された場合に会員の申請に応じた措置をとります。
      <br />
      ②
      会員が会員脱退を希望する場合には、顧客センターおよびサービス内の会員脱退手続きを通じて会員脱退を行うことができます。
      <br />
      <br />
      <h3>第23条 (会社の解除及び解約)</h3> <br />①
      会社は、会員がこの約款で定めた会員の義務に違反した場合には、会員に対する事前通知後に契約を解除することができます。ただし、会員が現行法違反及び故意又は重大な過失で会社に損害を与えた場合には、事前の通知なく利用契約を解除することができます。
      <br />
      ②
      会社が利用契約を解除する場合、会社は会員に書面、電子メール又はこれに準ずる方法で次の各号の事項を会員に通知します。
      <br />
      1. 終了理由
      <br />
      2. 終了日
      <br />
      ③
      第1項ただし書の場合、会員は有料サービスの使用権を喪失し、これによる返金及び損害賠償を請求することができません。
      <br />
      <br />
      <h3>第24条 (会員に対するサービス利用制限)</h3> <br />①
      会社は、会員に次の各号の区分により会員のサービス利用を制限することができます。利用制限がなされる具体的な会員の義務違反事由は、第27条により個別運営政策で定めることとします。
      <br />
      1. アカウント利用制限：一定期間または永久に会員アカウントの利用を制限
      <br />
      2. 会員利用制限：一定期間または永久に会員のサービス利用を制限
      <br />
      ②
      会社の利用制限が正当な場合に会社は利用制限により会員が被った損害を賠償しません。
      <br />
      <br />
      <h3>第25条 (暫定措置としての利用制限)</h3> <br />①
      会社は、次の各号に該当する問題に対する調査が完了するまでアカウントを停止することができます。
      <br />
      1.
      アカウントがハッキングまたは盗用されたという正当な申告が受け付けられた場合
      <br />
      2. 違法プログラムユーザー、作業場など違法行為者と合理的に疑われる場合
      <br />
      3. 他に上記各号に準ずる事由でアカウントの暫定措置が必要な場合
      <br />
      ②
      第1項の場合、会社は調査が完了した後、サービス利用期間に比例して一定額を支給して利用する会員に停止された期間だけ会員のサービス利用期間を延長します。ただし、第1項による違法行為者と判明した場合には、この限りでありません。
      <br />
      <br />
      <h3>第26条 (利用制限の事由と手続)</h3> <br />①
      会社は、違反行為の内容、程度、回数、結果など諸般事情を考慮して利用制限がなされる具体的な事由及び手続を運営政策として定めます。
      <br />
      ②
      会社が第25条で定めた利用制限をする場合には、会員に書面又は電子メールや初期画面又はサービスホームページに掲載する方法で次の各号の事項を会員に通知します。
      <br />
      1. 利用制限の理由
      <br />
      2. 利用制限の種類及び期間
      <br />
      3. 利用制限に対する異議申請方法
      <br />
      <br />
      <h3>第27条 (利用制限に対する異議申請手続)</h3> <br />①
      会員が会社の利用制限に不服しようとするときは、通知を受けた日から15日以内に会社の利用制限に不服する理由を記載した異議申請書を書面、電子メールまたはこれに準ずる方法で会社に提出しなければなりません。
      <br />
      ②
      第1項の異議申請書を受理した会社は、受理した日から15日以内に会員の不服理由について書面、電子メール又はこれに準ずる方法で回答しなければなりません。ただし、会社は15日以内に回答が困難な場合、会員にその理由と処理日程を通知します。
      <br />
      ③ 会社は上記の回答内容に応じて対応する措置を講じなければなりません。
      <br />
      <br />
      <br />
      <h2>第6章 損害賠償及び払い戻し等</h2>
      <br />
      <h3>第28条 (損害賠償)</h3> <br />①
      会社が故意又は重過失で会員に損害を与えた場合、損害に対して賠償する責任があります。
      <br />
      ②
      会員が本約款及び関係法令に違反して会社に損害を与えた場合、会員は会社に対してその損害に対して賠償する責任があります。
      <br />
      <h3>第29条 (返金)</h3> <br />①
      払い戻し対象は無償で支給された場合は除外され、会員が直接購入した有料サービス購入費用に限ります。
      <br />
      ②
      会員が直接購入した有料サービスに対して会員が払い戻しを要請すれば、これを払い戻すことができます。払い戻しの際には、銀行振込及び決済代行手数料等の事由により現在残っている残額の10％以内の金額又は残額が10,000ウォン以内の場合は、一定金額を控除して残りの金額を返金いたします。一定金額を控除する場合、控除後に残った金額が控除金額より小さい場合は返金できません。
      <br />
      ③
      継続利用契約の場合には、商品価額で利用者が既に使用した部分に該当する金額（各商品別使用金額算定基準は商品購入時に別途告知する）を除き、残った金額から残った金額の10％以内の金額又は残った金額この10,000ウォン以内の場合は、一定金額を控除した後、残りの金額を返金いたします。一定金額を控除する場合、控除後に残った金額が控除金額より小さい場合は返金できません。
      <br />
      ④
      現行法令及び重大な約款違反など会員の帰責事由で利用契約を解除する場合、払い戻しが制限されることがあります。
      <br />
      <br />
      <h3>第30条 (会社の免責)</h3> <br />①
      会社は、展示、事変、天災地変、非常事態、現在の技術では解決が不可能な技術的欠陥及びこれに準ずるその他不可抗力的事由でサービスを提供できない場合には責任が免除になります。
      <br />
      ②
      会社は会員の帰責事由によるサービスの中止、利用障害及び契約解除に対して責任が免除されます。
      <br />
      ③
      会社は、期間通信事業者が電気通信サービスを中止したり、正常に提供しないことで会員に損害が発生した場合について会社の故意または重大な過失がない限り責任が免除されます。
      <br />
      ④
      会社は、事前に公知のサービス用設備の保守、交換、定期点検、工事等やむを得ない事由でサービスが中止されたり、障害が発生した場合について会社の故意又は重大な過失がない限り責任が免除されます。
      <br />
      ⑤
      会社は会員のコンピュータ環境により発生する諸問題又は会社の故意又は重大な過失がないネットワーク環境により発生する問題に対して責任が免除されます。
      <br />
      ⑥
      会社は、会員または第三者がサービス内またはウェブサイト上に掲示または転送した情報、資料、事実の信頼度、正確性などの内容については、会社の故意または重大な過失がない限り責任が免除されます。
      <br />
      ⑦
      会社は会員相互間または会員と第三者間でサービスを媒介で発生した紛争に対して介入する義務がなく、これによる損害を賠償する責任もありません。
      <br />
      ⑧
      会社が提供するサービスのうち無料サービスの場合、会社の故意または重大な過失がない限り、会社は損害賠償をしません。
      <br />
      ⑨
      本サービスの一部は、他の事業者が提供するサービスを通じて提供することができ、会社は他の事業者が提供するサービスにより発生した損害等については、会社の故意又は重大な過失がない限り責任が免除されます。
      <br />
      ⑩
      会社は、会員がサービスを利用し、期待する結果を得られなかったり、喪失したことに対して責任を負わず、サービスに対する炊事の選択または利用で発生する損害等について会社の故意または重大な過失がない限り責任が免除になります。
      <br />
      ⑪
      会社は会員のサイバー資産（有料サービスまたは無料ポイント）の損失に対して会社の故意または重大な過失がない限り責任が免除されます。
      <br />
      ⑫
      会社は会員のコンピュータエラーによる損害が発生した場合、または身元情報および電子メールアドレスを不正確に記載または未記載して損害が発生した場合について会社の故意または重大な過失がない限り責任が免除されます。
      <br />
      ⑬
      会社は関連法令、政府政策等によりサービス又は会員によりサービス利用時間等を制限することができ、これらの制限事項及び制限により発生するサービス利用関連諸般事項については責任が免除されます。
      <br />
      ⑭
      会社の故意又は重大な過失による債務不履行又は不法行為により会員に発生した損害を賠償する場合、会員が保有する金額を上限とします。
      <br />
      <br />
      <h3>第31条 (会員の苦情処理及び紛争解決)</h3> <br />①
      会社は会員の便宜を考慮して会員の意見や不満を提示する方法を初期画面やサービスホームページで案内します。当社は、これらの会員の意見や苦情を処理するための専任組織を運営しています。
      <br />
      ②
      会社は会員から提起される意見や不満が正当であると客観的に認められる場合には、合理的な期間内にこれを迅速に処理します。ただし、処理に長期間かかる場合には、会員にその理由と処理日程をサービスホームページに知らせたり、電子メール、電話又は書面等で通知します。
      <br />
      ③
      会社と会員との間に紛争が発生し、第3の紛争調整機関が調整する場合、会社は利用制限など会員に措置した事項を誠実に証明し、調整機関の調整に従うことができます。
      <br />
      <br />
      <h3>第32条 (会員に対する通知)</h3> <br />①
      会社が会員に通知する場合、会員が指定した電子メールアドレス、電子メモ等で行うことができます。
      <br />
      ②
      会社は会員全体に通知をする場合、7日以上会社のサイトの初期画面に掲示したり、ポップアップ画面などを提示することにより、第1項の通知に代えることができます。
      <br />
      <h3>第33条 (管轄及び準拠法)</h3> <br />
      本約款は大韓民国法律により規律され解釈され、会社と会員間に発生した紛争で訴訟が提起される場合、会員又は会社の住所地管轄裁判所を第1審管轄裁判所とします。ただし、提訴当時会員の住所又は居所が明確でない、又は外国居住者の場合には民事訴訟法上管轄裁判所に提起します。
    </div>
  );
};

export default Terms1Jp;
