import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { GlocalContextProvider } from "context/store";
import { QueryClientProvider } from "@tanstack/react-query";

import "assets/lang/i18n";
import "assets/style/globals.scss";
import "assets/style/font.scss";
import "assets/style/color.scss";
import { getClient } from "utils/queryClient";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = getClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <GlocalContextProvider>
        <App />
      </GlocalContextProvider>
      {/* <ReactQueryDevtools initialIsOpen={true} /> */}
    </QueryClientProvider>
  </React.StrictMode>
);
