import style from "./Terms.module.scss";

const Terms4Jp = () => {
  return (
    <div className={style.terms}>
      (株)エクスボディが提供するサービスをよりスムーズに利用できるようにするために、その収集及び利用が必要な個人情報なので、該当項目に同意を拒否された場合でも、上記個人情報を収集及び利用するサービスを除き、残りのサービスについては利用可能です。
      <br />
      <br />
      ■ [オプション] 個人情報の収集と利用の同意
      <br />
      <br />
      <table>
        <tr>
          <th>収集・利用目的</th>
          <th>収集・利用する個人情報項目</th>
          <th>保持期間</th>
        </tr>
        <tr>
          <td>
            マーケティングや広告への活用
            新規サービスの開発とカスタムサービスの提供、イベントや広告性情報の提供と参加機会の提供、アクセス頻度の把握、または会員のサービス利用に関する統計など
          </td>
          <td>携帯電話番号、Eメールアドレス、ニックネーム、ID</td>
          <td>会員脱退時まで</td>
        </tr>
        <tr>
          <td>カスタムサービスの提供に利用</td>
          <td>
            飲酒回数、喫煙量、飲酒量、最近の運動関連記録
            (持続期間、運動回数、運動種類)、心血管疾患、肺疾患、高血圧、糖尿
            (第2型)
          </td>
          <td>会員脱退時までまたは同意撤回時まで</td>
        </tr>
      </table>
    </div>
  );
};

export default Terms4Jp;
