import style from "./Terms.module.scss";

const Terms4 = () => {
  return (
    <div className={style.terms}>
      ㈜엑스바디가 제공하는 서비스를 보다 원활하게 이용하도록 하기 위해서 그
      수집 및 이용이 필요한 개인정보이므로 해당 항목에 동의를 거부하시는
      경우에도 위 개인정보를 수집 및 이용하는 서비스를 제외하고 나머지 서비스에
      대해서는 이용이 가능합니다.
      <br />
      <br />
      ■ [선택] 개인정보 수집 및 이용 동의
      <br />
      <br />
      <table>
        <tr>
          <th>수집·이용 목적</th>
          <th>수집·이용하는 개인정보 항목</th>
          <th>보유기간</th>
        </tr>
        <tr>
          <td>
            마케팅 및 광고에의 활용 신규 서비스 개발 및 맞춤 서비스 제공, 이벤트
            및 광고성 정보 제공 및 참여기회 제공, 접속빈도 파악 또는 회원의
            서비스 이용에 대한 통계 등{" "}
          </td>
          <td>휴대폰번호, 이메일주소, 닉네임, 아이디</td>
          <td>회원 탈퇴시까지</td>
        </tr>
        <tr>
          <td>맞춤형 서비스 제공에 이용 </td>
          <td>
            음주횟수, 흡연량, 음주량, 최근 운동관련 기록(지속기간, 운동횟수,
            운동종류), 심혈관질환, 폐질환, 고혈압, 당뇨(제2형){" "}
          </td>
          <td>회원 탈퇴시까지 또는 동의 철회시까지</td>
        </tr>
      </table>
    </div>
  );
};

export default Terms4;
