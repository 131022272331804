import { genderDatas } from "datas/common";

export function getToday(date: string) {
  var newDate = new Date(date);
  var year = newDate.getFullYear();
  var month = ("0" + (1 + newDate.getMonth())).slice(-2);
  var day = ("0" + newDate.getDate()).slice(-2);

  return year + "-" + month + "-" + day;
}

export function secondsToHMS(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = hours?.toString().padStart(2, "0");
  const formattedMinutes = minutes?.toString().padStart(2, "0");
  const formattedSeconds = remainingSeconds?.toString().padStart(2, "0");

  let HMS = `${formattedMinutes}:${formattedSeconds}`;
  if (formattedHours !== "00") {
    HMS = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
  return HMS;
}

export function moveBottom() {
  const bodyHeight = document.getElementsByTagName("body")[0].clientHeight;
  typeof window !== "undefined" && window?.scroll(0, bodyHeight);
}

//선택한 데이터 있으면 빼고, 없으면 넣고
export const selectDatasFunc = (select: any, datas: any) => {
  let newAddTagArr: any = [];
  const selectArr = datas;
  if (
    datas &&
    datas.length > 0 &&
    selectArr?.findIndex((find: any) => find === select) > -1
  ) {
    newAddTagArr = selectArr.filter(
      (element: any) => JSON.stringify(element) !== JSON.stringify(select)
    );
  } else {
    newAddTagArr = [...datas, select];
  }
  return newAddTagArr;
};

export const getGender = (g: string) => {
  const gender =
    genderDatas.filter((gender) => gender.key.includes(g))[0]?.value || "-";
  return gender;
};

export const stringKeyReplace = (stringkey: string, replaceList: string[]) => {
  let newString = stringkey;

  replaceList.forEach((text, index) => {
    newString = newString.replace(`{${index}}`, text);
  });
  return newString;
};

export const formatTime = (timeInSeconds: number) => {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = timeInSeconds % 60;

  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}:${String(seconds).padStart(2, "0")}`;
};

export const isPastDate = (targetDate: string, updateDate: string): boolean => {
  // 업데이트 날짜를 가져옵니다.
  const currentDate = new Date(updateDate);

  // 특정 날짜를 생성합니다.
  const targetD = new Date(targetDate);

  // 대상 날짜와 업데이트 날짜를 밀리초 단위로 비교합니다.
  return targetD.getTime() < currentDate.getTime();
};

//휴대폰번호 자동 하이픈 추가
export const phonenumberFunc = (phonenumber: string) => {
  return phonenumber
    .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
    .replace(/\-{1,2}$/g, "");
};

//생년월일 자동 하이픈 추가
export const birthFunc = (date: string) => {
  return date
    .replace(/^(\d{0,4})(\d{0,2})(\d{0,2})$/g, "$1-$2-$3")
    .replace(/\-{1,2}$/g, "");
};

export const checkBirth = (date: string) => {
  // YYMMDD 형식을 확인하는 정규표현식
  const regex = /^(?:19|20)?[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/;

  // 입력값이 정규표현식과 일치하는지 확인
  if (!regex.test(date)) {
    return false;
  } else {
    return true;
  }
};
