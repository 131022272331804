import style from "./Terms.module.scss";

const Terms2En = () => {
  return (
    <div className={style.terms}>
      As the collection and use of personal information is the minimum necessary
      to enable you to use the services provided by exbody. you must agree to
      the items to use the service.
      <br />
      I agree to collect/use my personal information as follows and provide it
      to a third party only in the cases specified in this agreement.
      <br />
      <br />
      <h2>
        ■ [Required] Consent to Collection and Use of Personal Information
      </h2>
      <br />
      <table>
        <tr>
          <th>Purpose of Collection and Use</th>
          <th>Items of personal information we collect and use</th>
          <th>Retention period</th>
        </tr>
        <tr>
          <td>Registration and management of the Examinee</td>
          <td>
            Name, date of birth, email address, cell phone number, gender, age,
            and duplicate identification information (DI),
          </td>
          <td>
            1. in principle: until examinee requests to destroy <br />
            2. However, in the case of the following reasons, until the end of
            the relevant period <br />- Records of consumer complaints or
            dispute handling: 3 years (Act on Consumer Protection in Electronic
            Commerce, etc.)
          </td>
        </tr>
        <tr>
          <td>Delivering personalized services</td>
          <td>
            Test Interval, First Test Date, Test Result Numeric, Test Result
            Image
          </td>
          <td>
            1. in principle: until the examinee withdraws <br />
            2. However, in the case of the following reasons, until the end of
            the relevant period <br />- Records of consumer complaints or
            dispute handling: 3 years (Act on Consumer Protection in Electronic
            Commerce, etc.)
          </td>
        </tr>
        <tr>
          <td>For promotional, contest, or event use</td>
          <td>
            [Required]
            <br />
            Name, phone number, email, residence, account information (bank
            name, account holder, account number)
          </td>
          <td>
            Until event, promotion, contest’s conclusion, or until shipping of
            prizes.’
          </td>
        </tr>
      </table>
    </div>
  );
};

export default Terms2En;
