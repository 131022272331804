import { useState, useEffect } from "react";
import style from "./SecessionModal.module.scss";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { restfetcher, QueryKeys, getClient } from "utils/queryClient";
import { useTranslation } from "react-i18next";
import ModalLayout from "../ModalLayout";

import TextInput from "components/inputs/textInput/TextInput";
import { setErrorCode } from "utils/error";
import { validatePassword } from "utils/validate";

interface Props {
  handleClose: () => void;
}

const error = {
  type: "none",
  text: "",
};

const SecessionModal = ({ handleClose }: Props) => {
  const queryClient = getClient();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errorState, setErrorState] = useState(error);
  const [passwords, setPasswords] = useState({
    pw: "",
    repw: "",
  });

  const withdrawalApi = useQuery({
    queryKey: [QueryKeys.WITHDRAWAL],
    queryFn: () =>
      restfetcher({
        method: "POST",
        path: "v1/user/withdrawal",
        params: {
          usrpw: passwords.pw,
        },
      }),
  });

  useEffect(() => {
    const { isSuccess, data } = withdrawalApi;
    if (isSuccess) {
      if (data?.result === "success") {
        let alertText = t(
          "Mobile_UserInfo_Withdrawal_Pop_Warning_Text_0"
        ).replaceAll("<span>", "");
        alertText = alertText.replaceAll("</span>", "");
        alert(alertText);
        if (typeof sessionStorage !== "undefined") {
          sessionStorage?.clear();
        }
        navigate("/");
      } else {
        alert(t(setErrorCode(data?.errcode).toString()));
      }
      queryClient.removeQueries({ queryKey: [QueryKeys.WITHDRAWAL] });
    }
  }, [withdrawalApi.isSuccess, withdrawalApi.data]);

  const handleSubmit = () => {
    if (passwords.pw === passwords.repw && validatePassword(passwords.repw)) {
      if (
        typeof window !== "undefined" &&
        window?.confirm(t("Mobile_UserInfo_Withdrawal_Pop_0"))
      ) {
        withdrawalApi.refetch();
      }
    } else {
      setErrorState({
        type: "fail",
        text: "Mobile_UserInfo_NewPassword_Validation_4_Text_0",
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPasswords({ ...passwords, [name]: value });
    setErrorState(error);
  };

  return (
    <ModalLayout
      handleClose={handleClose}
      title="Mobile_UserInfo_Withdrawal_Pop_Title_0"
      handleSubmit={handleSubmit}
      rightBtnLabel="Mobile_UserInfo_Withdrawal_Pop_Button_0"
      rightAction={passwords.pw !== "" && passwords.pw === passwords.repw}
    >
      <div className={style.secessionModal}>
        <div className={style.item}>
          <TextInput
            label="Mobile_UserInfo_Password_0"
            value={passwords.pw}
            name="pw"
            handleChange={handleChange}
            type="password"
          />
        </div>
        <div className={style.item}>
          <TextInput
            label={`${t("Mobile_UserInfo_PasswordReconfirm_0")} *`} //"비밀번호 재확인 *"
            value={passwords.repw}
            name="repw"
            handleChange={handleChange}
            state={errorState.type}
            msg={errorState.text}
            type="password"
          />
        </div>
        <p
          className={style.desc}
          dangerouslySetInnerHTML={{
            __html: t("Mobile_UserInfo_Withdrawal_Pop_Warning_Text_0"),
          }}
        >
          {/* *회원 탈퇴 후 <span className={style.red}>30일</span> 동안 동일한 아이디, 휴대폰 번호로 재가입이
          불가능합니다. */}
        </p>
      </div>
    </ModalLayout>
  );
};

export default SecessionModal;
