import style from "./ReportCard.module.scss";
import { useTranslation } from "react-i18next";
import { getToday } from "utils/common";

import { ReactComponent as IconMore } from "assets/icon/ic_nav_arrow_right.svg";
import { ReactComponent as IconDelete } from "assets/icon/ic_delete_lined.svg";

interface Props {
  data: ReportType | null;
  onClickMoreReport: (reportfileList: string[]) => void;
  onClickOpenModal: () => void;
  icons: { [key: string]: string };
  reportRemoveMode: boolean;
}

const ReportCard = ({
  data,
  onClickMoreReport,
  onClickOpenModal,
  reportRemoveMode,
}: Props) => {
  const { t } = useTranslation();

  const handleClickMoreAndDelete = () => {
    reportRemoveMode
      ? onClickOpenModal()
      : data?.reportfileList && onClickMoreReport(data?.reportfileList);
  };

  return (
    <div className={style.reportCard}>
      {data ? (
        <>
          <div className={style.topArea}>
            <div className={style.date}>
              {t("Mobile_Report_Details_InspectionDate_0")}
              <span>{getToday(data?.examdate)}</span>
            </div>

            <div className={style.moreBtn} onClick={handleClickMoreAndDelete}>
              {reportRemoveMode ? (
                <div className={style.delete}>
                  <IconDelete />
                </div>
              ) : (
                <div>
                  <p>{t("Mobile_Report_Details_View_Button_0")}</p>
                  <IconMore />
                </div>
              )}
            </div>
          </div>
          <h3 className={style.companyname}>{data?.companyname || "-"}</h3>
          <div className={style.resultArea}>
            <h4 className={style.resultTite}>
              {t("Mobile_Report_Details_InspectionItem_0")}
            </h4>
            <ul className={style.resultList}>
              {data?.measurementList?.map((measurement: any, index) => (
                <li className={style.li} key={`${data?._id}_${index}`}>
                  <p className={style.icon}>
                    <img
                      alt=""
                      src={measurement && Object?.values(measurement)[0]}
                    />
                  </p>
                  <i className={style.line}></i>
                  {t(Object.keys(measurement)[0])}
                </li>
              ))}
            </ul>
          </div>
        </>
      ) : (
        <>
          <div className={style.topArea}>
            <p className={style.date}>
              {t("Mobile_Report_Details_InspectionDate_0")}
            </p>
            <p className={style.moreBtn}>
              {t("Mobile_Report_Details_View_Button_0")}
              <IconMore />
            </p>
          </div>
          <h3 className={`${style.companyname} loadingBack`}></h3>
          <div className={style.resultArea}>
            <h4 className={style.resultTite}>
              {t("Mobile_Report_Details_InspectionItem_0")}
            </h4>
            <ul className={style.resultList}>
              <li className={`${style.li} loadingBack`}></li>
              <li className={`${style.li} loadingBack`}></li>
              <li className={`${style.li} loadingBack`}></li>
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default ReportCard;
