import { useState, useEffect } from "react";
import style from "./ChangeNicknameModal.module.scss";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { restfetcher, QueryKeys, getClient } from "utils/queryClient";
import { validateNickname } from "utils/validate";
import ModalLayout from "../ModalLayout";
import TextInput from "components/inputs/textInput/TextInput";
import DefaultBtn from "components/btns/defaultBtn/DefaultBtn";

interface Props {
  nickname: string;
  handleClose: () => void;
  onChange: (type: string, value: string) => void;
}

const ChangeNicknameModal = ({ nickname, handleClose, onChange }: Props) => {
  const queryClient = getClient();
  const { t } = useTranslation();
  const error = {
    type: "none",
    text: "",
  };
  const [changeNickname, setChangeNickname] = useState("");
  const [errorState, setErrorState] = useState(error);

  const duplicatecheckNicknameApi = useQuery({
    queryKey: [QueryKeys.DUPLICATECHECKNICKNAME],
    queryFn: () =>
      restfetcher({
        method: "POST",
        path: "api/user/duplicatecheck",
        params: {
          type: "NICKNAME",
          data: changeNickname,
        },
      }),
  });

  const updatenicknameApi = useQuery({
    queryKey: [QueryKeys.UPDATENICKNAME],
    queryFn: () =>
      restfetcher({
        method: "POST",
        path: "api/user/updatenickname",
        params: {
          usrnickname: changeNickname,
        },
      }),
  });

  useEffect(() => {
    const { isSuccess, data } = duplicatecheckNicknameApi;
    if (isSuccess && data?.result === "success") {
      const { isexist } = data;
      if (!isexist) {
        setErrorState({
          type: "success",
          text: "Mobile_UserInfo_Nickname_Validation_1_Text_0",
        });
      } else {
        setErrorState({
          type: "fail",
          text: "Mobile_UserInfo_Nickname_Validation_2_Text_0",
        });
      }
      queryClient.removeQueries({
        queryKey: [QueryKeys.DUPLICATECHECKNICKNAME],
      });
    }
  }, [duplicatecheckNicknameApi.isSuccess, duplicatecheckNicknameApi.data]);

  useEffect(() => {
    const { isSuccess, data } = updatenicknameApi;
    if (isSuccess && data?.result === "success") {
      const { usrnickname } = data;
      queryClient.removeQueries({ queryKey: [QueryKeys.UPDATENICKNAME] });
      setErrorState(error);
      onChange("usrnickname", usrnickname);
      setChangeNickname("");
      alert(t("Mobile_Common_Save_Pop_0"));
      handleClose();
    }
  }, [updatenicknameApi.isSuccess, updatenicknameApi.data]);

  const handleDoubleCheckNickname = () => {
    const validate = validateNickname(changeNickname);
    if (validate) {
      duplicatecheckNicknameApi.refetch();
    } else {
      setErrorState({
        type: "fail",
        text: "Mobile_UserInfo_NewNickname_InsertBox_Text_0",
      });
    }
  };

  const handleSubmit = () => {
    if (errorState.type === "success") {
      if (
        typeof window !== "undefined" &&
        window?.confirm(t("Mobile_UserInfo_Common_Edit_Pop_0"))
      ) {
        updatenicknameApi.refetch();
      }
    }
  };

  const handleChange = (Nickname: string) => {
    setErrorState(error);
    setChangeNickname(Nickname);
  };

  return (
    <ModalLayout
      handleClose={handleClose}
      title="Mobile_UserInfo_NewNickname_Pop_Title_0"
      handleSubmit={handleSubmit}
      rightAction={errorState.type === "success"}
    >
      <div className={style.changeNicknameModal}>
        <div className={style.section}>
          <div className={style.item}>
            <p className={style.itemTitle}>
              {t("Mobile_UserInfo_CurrentNickname_0")}
            </p>
            <p className={style.nownickname}>{nickname}</p>
          </div>
          <div className={style.item}>
            <TextInput
              label={`${t("Mobile_UserInfo_NewNickname_0")} *`} //"변경 닉네임 *"
              value={changeNickname}
              name="changeNickname"
              handleChange={(event) => {
                handleChange(event.target.value);
              }}
              msg={errorState.text}
              state={errorState.type}
              placeholder="Mobile_UserInfo_NewNickname_InsertBox_Text_0"
              parentStyle={{
                backgroundColor: "rgba(0,0,0,0)",
                height: "36px",
              }}
            />
          </div>
          <p className={style.checkBtn}>
            <DefaultBtn
              text="Mobile_UserInfo_DuplicatiateCheck_Button_0"
              onClick={handleDoubleCheckNickname}
            />
          </p>
        </div>
      </div>
    </ModalLayout>
  );
};

export default ChangeNicknameModal;
