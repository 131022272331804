import style from "./Header.module.scss";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from "assets/icon/ic_arrow_left.svg";

const BackHeader = () => {
  const navigate = useNavigate();
  const headerType = localStorage.getItem("headerType");

  return (
    <header
      className={`${style.header} ${style.backHeader}`}
      style={{ paddingTop: `${headerType}` }}
    >
      <BackIcon onClick={() => navigate(-1)} />
    </header>
  );
};

export default BackHeader;
