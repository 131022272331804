export const validateEmail = (email: string) => {
  const emailCheck =
    /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return emailCheck.test(email);
};

export const validatePassword = (password: string) => {
  const charTypes = [
    /[A-Z]/,
    /[a-z]/,
    /\d/,
    /[!@#$%^&*()\-_=+{}[\]|;:'",.<>/?]/,
  ];
  const validCharTypes = charTypes.filter((regex) =>
    regex.test(password)
  ).length;

  return validCharTypes > 1;
};

export const validateNickname = (nickname: string) => {
  const pattern = /^[가-힣a-zA-Z0-9]{2,10}$/;
  return pattern.test(nickname);
};

export const validatename = (name: string) => {
  const pattern = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g;
  return pattern.test(name);
};

export const validateBirth = (birth: string) => {
  const pattern =
    /^(19[0-9][0-9]|20\d{2})-(0[0-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
  return pattern.test(birth);
};

//휴대폰번호 자동 하이픈 추가
export const onlyNumberFunc = (number: string) => {
  const regex = /[^0-9]/g;
  const result = number.replace(regex, "");
  return result;
};

export const onlyNumbers = (input: string) => {
  const halfWidthInput = input.replace(/[０-９]/g, (ch) =>
    String.fromCharCode(ch.charCodeAt(0) - 0xfee0)
  );

  return halfWidthInput.replace(/[^0-9]/g, "");
};
