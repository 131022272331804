import style from "./VideoExCard.module.scss";
import { ReactComponent as PlayIcon } from "assets/icon/ic_play_button_b.svg";
import { formatTime } from "utils/common";

interface Props {
  data: {
    exerciseImage: string;
    registerDate: string;
    title: string;
    totalTime: number;
  };
}

const VideoExCard = ({ data }: Props) => {
  return (
    <div className={style.videoExCard}>
      <p className={style.imgArea}>
        <img
          src={
            data.exerciseImage.includes("https")
              ? data.exerciseImage
              : `/exercise/${data?.exerciseImage}.png`
          }
          alt="exerciseImage"
        />
        <PlayIcon />
      </p>
      <p className={`${style.descArea}`}>{data.title}</p>
      <p className={style.timeArea}>
        소요시간
        <span>{formatTime(data?.totalTime)}</span>
      </p>
    </div>
  );
};

export default VideoExCard;
