import style from "./DefaultBtn.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  text: string;
  onClick?: () => void;
  activate?: boolean;
}

const DefaultBtn = ({ text = "button", onClick, activate = true }: Props) => {
  const { t } = useTranslation();

  return (
    <button
      className={style.defaultBtn}
      onClick={onClick}
      style={{
        opacity: activate ? "1" : "0.2",
        cursor: activate ? "pointer" : "default",
      }}
    >
      {t(text)}
    </button>
  );
};

export default DefaultBtn;
