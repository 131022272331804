import { useState, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { restfetcher, QueryKeys, getClient } from "utils/queryClient";
import style from "./Login.module.scss";
import { useGlobalContext } from "context/store";
import { setErrorCode } from "utils/error";
import { defaultPassData } from "datas/defaultdata";
import { useTranslation } from "react-i18next";
import { ReactComponent as LogoSVG } from "assets/icon/logo_exbody.svg";
import { ReactComponent as LightSVG } from "assets/icon/ic_arrow_right.svg";
import { ReactComponent as LightSVG2 } from "assets/icon/ic_nav_arrow_right.svg";

import MoreBtn from "components/btns/moreBtn/MoreBtn";
import LineInput from "components/inputs/lineInput/LineInput";
import DefaultBtn from "components/btns/defaultBtn/DefaultBtn";
import EmailFindModal from "components/modal/emailFindModal/EmailFindModal";
import PWFindModal from "components/modal/pwFindModal/PwFindModal";
import Footer from "components/footer/Footer";
import LinePWInput from "components/inputs/lineInput/LinePWInput";
import SmsModal from "components/modal/smsModal/SmsModal";
import CheckInput from "components/inputs/checkInput/CheckInput";

const Login = () => {
  const queryClient = getClient();

  const { t } = useTranslation();
  const {
    setConfirm,
    setDormant,
    passUserInfo,
    setPassUserInfo,
    setUsridx,
    userInfo,
    setUserInfo,
  } = useGlobalContext();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [pw, setPW] = useState("");
  const [emailFindOpen, setEmailFindOpen] = useState(false);
  const [pwFindOpen, setPWFindOpen] = useState(false);
  const [activate, setActivate] = useState(false);
  const [matchState, setMatchState] = useState(false);
  const [smsModalOpen, setSmsModalOpen] = useState({
    open: false,
    type: "",
  });

  const [isSaveCheckBox, setIsSaveCheckBox] = useState({
    email: false,
    isAuto: false,
  });

  const loginApi = useQuery({
    queryKey: [QueryKeys.LOGIN],
    queryFn: () =>
      restfetcher({
        method: "POST",
        path: "api/auth/login",
        params: {
          usremail: email,
          usrpw: pw,
          fb: "",
        },
      }),
  });

  const isjoinApi = useQuery({
    queryKey: [QueryKeys.ISJOIN],
    queryFn: () =>
      restfetcher({
        method: "POST",
        path: "api/user/isjoin",
        params: {
          authidx: passUserInfo.authidx,
          authkey: passUserInfo.authkey,
          usrphoneno: passUserInfo.usrphoneno,
          countryno: passUserInfo.countryno,
        },
      }),
  });

  const userupdateloginApi = useMutation({
    mutationFn: () =>
      restfetcher({
        method: "POST",
        path: "v1/user/updatelogin",
        params: {
          isauto: isSaveCheckBox.isAuto,
        },
      }),

    onSuccess: (data: any) => {
      if (data?.result === "success") {
        setUserInfo({ ...userInfo, isauto: isSaveCheckBox.isAuto });
      }
    },
  });

  useEffect(() => {
    return () => {
      setPassUserInfo(defaultPassData);
    };
  }, []);

  useEffect(() => {
    const { isSuccess, data } = loginApi;
    if (isSuccess) {
      if (data?.result === "success") {
        if (isSaveCheckBox.email) {
          localStorage.setItem("savedEmail", email);
        } else {
          localStorage.removeItem("savedEmail");
        }

        localStorage.setItem(
          "emailChecked",
          JSON.stringify(isSaveCheckBox.email)
        );

        localStorage.setItem(
          "autoChecked",
          JSON.stringify(isSaveCheckBox.isAuto)
        );

        const { authenticatetoken, userinfo } = data;
        sessionStorage?.setItem("token", authenticatetoken?.jwt);
        localStorage?.setItem("refresh", authenticatetoken?.refresh);
        setUsridx(userinfo?.usridx);
        navigate("/");

        setTimeout(() => {
          userupdateloginApi.mutate();
        });
      } else if (data?.errcode === 609) {
        const { usridx, usrname, usremail, joindate, logindate, dormantdate } =
          data;
        setDormant({
          usridx: usridx,
          usrname: usrname,
          usremail: usremail,
          joindate: joindate,
          logindate: logindate,
          dormantdate: dormantdate,
        });
        navigate("/dormant");
      } else if (data?.errcode === 602) {
        setConfirm({
          open: true,
          title: "Mobile_SignIn_Page_Title_0",
          text: "Mobile_SignIn_0_Pop_Text_0",
          onCheck: () => {
            navigate("/join/step1");
          },
          onCancel: () => {},
        });
      } else {
        alert(t(setErrorCode(data?.errcode)?.toString()));
      }
      queryClient.removeQueries({ queryKey: [QueryKeys.LOGIN] });
    }
  }, [loginApi.isSuccess, loginApi.data]);

  useEffect(() => {
    const { isSuccess, data } = isjoinApi;
    if (isSuccess) {
      if (data?.result === "success") {
        const { userInfo } = data;
        if (userInfo?.joindate && userInfo?.usremail) {
          setSmsModalOpen({
            open: false,
            type: "",
          });
          setPassUserInfo({
            ...userInfo,
            usremail: userInfo?.usremail,
          });
          setEmailFindOpen(true);
        }
      } else {
        alert(t(setErrorCode(data?.errcode).toString()));
        setMatchState(false);
        setPassUserInfo(defaultPassData);
      }
      queryClient.removeQueries({ queryKey: [QueryKeys.ISJOIN] });
    }
  }, [isjoinApi.isSuccess, isjoinApi.data]);

  useEffect(() => {
    if (pw !== "" && email !== "") {
      setActivate(true);
    } else {
      setActivate(false);
    }
  }, [email, pw]);

  useEffect(() => {
    if (smsModalOpen.open) {
      if (smsModalOpen.type === "email") {
        // isjoinApi.refetch();
      } else if (smsModalOpen.type === "pw") {
        setPWFindOpen(true);
      }
    }
  }, [passUserInfo]);

  const handleCertification = (type: string) => {
    setSmsModalOpen({
      type: type,
      open: true,
    });
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
      loginApi.refetch();
    }
  };

  const sendPW = () => {
    setSmsModalOpen({
      open: false,
      type: "",
    });
    setPWFindOpen(true);
    setMatchState(false);
  };

  const sendEMail = () => {
    isjoinApi.refetch();
    setMatchState(false);
  };

  const handleCheckboxChange = (field: "email" | "isAuto") => {
    setIsSaveCheckBox((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  useEffect(() => {
    const savedEmail = localStorage.getItem("savedEmail") || "";

    const savedEmailChecked: boolean =
      localStorage.getItem("emailChecked") !== null
        ? JSON.parse(localStorage.getItem("emailChecked")!)
        : false;

    const savedAutoChecked: boolean =
      localStorage.getItem("autoChecked") !== null
        ? JSON.parse(localStorage.getItem("autoChecked")!)
        : false;

    setEmail(savedEmail);
    setIsSaveCheckBox({
      email: savedEmailChecked,
      isAuto: savedAutoChecked,
    });
  }, []);

  return (
    <>
      <div className={style.login}>
        <h1>
          <LogoSVG />
        </h1>
        <section>
          <h2>{t("Mobile_SignIn_Page_Title_0")}</h2>
          <div className={style.emailArea}>
            <LineInput
              label="Mobile_SignIn_Email_InsertBox_Text_0"
              value={email}
              name="email"
              handleChange={(event: ChangeEvent<HTMLInputElement>) => {
                setEmail(event?.target?.value);
              }}
            />
          </div>
          <div className={style.pwArea}>
            <LinePWInput
              label="Mobile_SignIn_Password_InsertBox_Text_0"
              value={pw}
              name="pw"
              handleChange={(event: ChangeEvent<HTMLInputElement>) => {
                setPW(event.target.value);
              }}
              type="password"
              onKeyDown={onKeyDown}
            />
          </div>
          <p className={style.loginBtn}>
            <DefaultBtn
              text="Mobile_SignIn_SignIn_Button_0"
              onClick={() => {
                activate && loginApi.refetch();
              }}
              activate={activate}
            />
          </p>
        </section>
        <section className={style.checkBox}>
          <CheckInput
            checked={isSaveCheckBox.email}
            onChange={() => handleCheckboxChange("email")}
          >
            <p>{t("Mobile_Login_Save_Email")}</p>
          </CheckInput>
          <CheckInput
            checked={isSaveCheckBox.isAuto}
            onChange={() => handleCheckboxChange("isAuto")}
          >
            <p> {t("Mobile_UserInfo_AutoSignIn_0")}</p>
          </CheckInput>
        </section>
        <section className={style.useService}>
          <h3>{t("Mobile_SignIn_Join_Text_1_0")}</h3>
          <p className={style.desc}>{t("Mobile_SignIn_Join_Text_2_0")}</p>
          <div className={style.useBtn}>
            <MoreBtn
              text="Mobile_SignIn_Join_Button_2_0"
              icon={<LightSVG />}
              onClick={() => {
                navigate("/join/step1");
              }}
            />
          </div>
        </section>
        <section className={style.findArea}>
          <h3>{t("Mobile_SignIn_Find_Text_0")}</h3>
          <div className={style.findBtns}>
            <MoreBtn
              text="Mobile_SignIn_FindEmail_Button_0"
              icon={<LightSVG2 />}
              onClick={() => handleCertification("email")}
            />
            <MoreBtn
              text="Mobile_SignIn_FindPassword_Button_0"
              icon={<LightSVG2 />}
              onClick={() => handleCertification("pw")}
            />
          </div>
        </section>
        {emailFindOpen && (
          <EmailFindModal
            info={{
              name: passUserInfo?.usrname,
              joinDate: passUserInfo?.joindate,
              email: passUserInfo?.usremail,
            }}
            handleClose={() => {
              setEmailFindOpen(false);
              setPassUserInfo(defaultPassData);
              setSmsModalOpen({
                type: "",
                open: false,
              });
            }}
          />
        )}
        {pwFindOpen && (
          <PWFindModal
            handleClose={() => {
              setPWFindOpen(false);
              setPassUserInfo(defaultPassData);
              setSmsModalOpen({
                type: "",
                open: false,
              });
            }}
          />
        )}
        {smsModalOpen.open && (
          <SmsModal
            setMatchState={setMatchState}
            matchState={matchState}
            rightBtnLabel={
              smsModalOpen.type === "pw"
                ? "다음"
                : "Mobile_Common_Change_Button_0"
            }
            handleClose={() =>
              setSmsModalOpen({
                type: "",
                open: false,
              })
            }
            type={smsModalOpen.type}
            handleSubmit={smsModalOpen.type === "pw" ? sendPW : sendEMail}
          />
        )}
      </div>
      <Footer />
    </>
  );
};

export default Login;
