import style from "./MoreBtn.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  icon: any;
  text: string;
  onClick: () => void;
}

const MoreBtn = ({ icon, text = "", onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <p className={style.moreBtn}>
      <span className={style.text} onClick={onClick}>
        {t(text)}
      </span>
      <i onClick={onClick}>{icon}</i>
    </p>
  );
};

export default MoreBtn;
