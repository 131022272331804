import style from "./GradeCard.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  data?: string;
  title: string;
  states?: { [key: string]: string };
}

const GradeCard = ({ data, title, states }: Props) => {
  const { t } = useTranslation();

  const StateRender = ({ data }: { data: string }) => {
    if (states) {
      let colorState = "";
      for (let [key, value] of Object.entries(states)) {
        if (value === data) {
          colorState = key;
        }
      }
      return <p className={`${style.state} ${colorState}`}>{t(data)}</p>;
    }

    return <p className={`${style.state}`}>{t(data)}</p>;
  };

  return (
    <div className={style.gradeCard}>
      <p className={style.text}>{t(title)}</p>
      {data && states ? (
        <StateRender data={data} />
      ) : (
        <p className={`${style.state} loadingBack`}></p>
      )}
    </div>
  );
};

export default GradeCard;
