import style from "./PrevBtn.module.scss";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "context/store";
import { defaultPassData } from "datas/defaultdata";
import { useTranslation } from "react-i18next";

const PrevBtn = ({ path }: { path?: string }) => {
  const { t } = useTranslation();
  const { setPassUserInfo } = useGlobalContext();
  const navigate = useNavigate();

  const onClick = () => {
    path ? navigate(path) : navigate(-1);
    setPassUserInfo(defaultPassData);
  };

  return (
    <button className={style.prevBtn} onClick={onClick}>
      {t("Mobile_Common_Back_Button_0")}
    </button>
  );
};

export default PrevBtn;
