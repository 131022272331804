import style from "./GradeCard.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  data?: string[];
  title: string;
  states?: { [key: string]: string };
}

const GradeCard2 = ({ data, title, states }: Props) => {
  const { t } = useTranslation();
  const StateRender = ({ data }: { data: string }) => {
    if (states) {
      let colorState = "";
      for (let [key, value] of Object.entries(states)) {
        if (t(value) === data) {
          colorState = key;
        }
      }
      return <span className={`${style.state} ${colorState}`}>{t(data)}</span>;
    }

    return <span className={`${style.state}`}>{t(data)}</span>;
  };

  return (
    <div className={style.gradeCard2}>
      <p className={style.text}>{t(title)}</p>
      <div className={style.result}>
        <p>
          {t("Mobile_Main_InspectionResult_Index_L_0")}
          {data ? (
            <StateRender data={t(data[0])} />
          ) : (
            <span className={`${style.state} loadingBack`}></span>
          )}
        </p>
        <p>
          {t("Mobile_Main_InspectionResult_Index_R_0")}
          {data ? (
            <StateRender data={t(data[1])} />
          ) : (
            <span className={`${style.state} loadingBack`}></span>
          )}
        </p>
      </div>
    </div>
  );
};

export default GradeCard2;
