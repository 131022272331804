import style from "./Terms.module.scss";

const Terms3En = () => {
  return (
    <div className={style.terms}>
      exbody (hereinafter referred to as the "Company") complies with relevant
      laws and regulations, including the Personal Information Protection Act,
      and is committed to protecting personal information by establishing a
      personal information and sensitive information processing policy in
      accordance with relevant laws and regulations.
      <br />
      The Privacy Policy is disclosed through the service initialization screen
      of so that users can easily always access it, and is subject to changes in
      relevant laws, guidelines, notices, and company service policies. Please
      read it carefully and only proceed with the test if you agree to it.
      <br />
      <br />
      1. what personal data we process and for what purposes
      <br />
      The Company collects and processes personal and sensitive information
      items for business operations, provision and improvement of products and
      services, guidance to users, development of effective advertisements, and
      each of the following purposes. <br />
      For the Company to provide services to all users, the following personal
      information is required, which is collected with the consent of the
      applicant or based on laws and regulations. <br />
      Examinee has the right to refuse this consent, but if the Examinee refuses
      to consent to the collection and use of the required items, there may be
      restrictions on the use of the service.
      <br />
      <table>
        <tr>
          <th>When to collect</th>
          <th>Collections</th>
          <th>Purpose of Collection</th>
        </tr>
        <tr>
          <td>Upon Examinee registration</td>
          <td>
            [Required]. <br />
            Full name, date of birth, email address, cell phone number, gender,
            age, and duplicate identification information (DI),
            <br />
            <br />
            [additional required personal information for minors].
            <br />
            Name of legal representative , email address, mobile phone number,
            duplicate identification information (DI)
          </td>
          <td>
            Confirmation of subscription intent, subscription/change/termination
            of service,
            <br />
            determining whether to provide services (such as age verification)
            and checking for duplicate subscriptions, and delivering notices,
            <br />
            Examinee engagement spaces, <br />
            Surveying Examinee, <br />
            Providing personalized services, <br />
            Provide and guide advertising information and affiliate services,
            and confirm product delivery address and contact information,
          </td>
        </tr>
        <tr>
          <td>When measuring Examinee</td>
          <td>
            [Required] Test Interval, Initial Test Date, Test Result Numeric,
            Test Result Image{" "}
          </td>
          <td>Provide personalized service </td>
        </tr>
        <tr>
          <td>For contests and promotions</td>
          <td>
            [Required]. <br />
            Full name, phone number, email, residence, account information (bank
            name, account holder, account number)
          </td>
          <td>Shipping prizes for event and contest wins</td>
        </tr>
      </table>
      <br />
      2. Processing and retention period of personal information
      <br />
      <br />
      1. The Company retains the personal information of the Examinee from the
      date of the Examinee’s consent to the personal information until the
      Examinee requests the destruction of the personal information, and if the
      Examinee requests the destruction of the personal information, the Company
      destroys the personal information of the test subject without delay or
      keeps it separately in accordance with Article 39(6) of the Personal
      Information Protection Act. However, information kept in accordance with
      relevant laws is an exception.
      <br />
      <br />
      ㆍPeriod of retention and use of personal information in accordance with
      relevant laws and regulations
      <br />
      1) Important documents and slips related to commercial books and sales:
      Important documents - 10 years / slips - 5 years (Commercial Code)
      <br />
      2) Books and documents related to transactions: 5 years (National Tax Act,
      Corporate Tax Act, Value Added Tax Act, etc.)
      <br />
      3) Log record data, IP address, etc. required for providing communication
      fact verification data: 3 months (Communication Secrets Protection Act)
      <br />
      4) Records on display/advertising: 6 months (Act on Consumer Protection in
      Electronic Commerce, etc.)
      <br />
      5) Records on contract or withdrawal of subscription: 5 years (Act on
      Consumer Protection in Electronic Commerce, etc.)
      <br />
      6) Records on payment and supply of goods, etc.: 5 years (Act on Consumer
      Protection in Electronic Commerce, etc.)
      <br />
      7) Records of consumer complaints and dispute handling: 3 years (Act on
      Consumer Protection in the Electronic Commerce Act, etc.)
      <br />
      8) Records on collection/processing and use of credit information: 3 years
      (Act on the Use and Protection of Credit Information)
      <br />
      9) Records on identification: 6 months (Act on Promotion of Information
      and Communications Network Utilization and Information Protection, etc.)
      <br />
      <br />
      3. Entrustment of personal information
      <br />
      Company may entrust the processing of personal information to other
      companies to improve its services. When entrusting the processing of
      personal information, the Company shall manage and supervise the test
      subject's personal information to ensure that it is processed safely and
      shall restrict the processing of the test subject's personal information
      for other purposes.
      <br />
      (1) If we entrust the processing of personal information, we will notify
      the test subject in advance. <br />
      (2) In the case of entrusting the processing of personal information, we
      will clearly stipulate the service provider's compliance with the
      instructions related to personal information protection, confidentiality
      of personal information, prohibition of providing it to a third party, and
      responsibility in the event of an accident through an entrustment contract
      and keep the contents of the contract in writing or electronically.
      <br />
      <br />
      4. Procedures and methods for destroying personal information <br />
      The Company shall destroy personal information without delay when it
      becomes unnecessary, such as the expiration of the personal information
      retention period or the achievement of the purpose of collecting and using
      personal information.
      <br />
      The Company's personal information destruction procedures and methods are
      as follows.
      <br />
      (1) Destruction Procedure
      <br />
      A. The Company shall destroy the personal information for which the reason
      for destruction has occurred through the approval of the person in charge
      of personal information protection.
      <br />
      B. The information entered by the test subject for the purpose of joining
      the test or applying for the service is transferred to a separate Data
      Base (in the case of paper, a filing cabinet with a separate lock) after
      the purpose is achieved and stored for a certain period of time in
      accordance with the information protection reasons under the internal
      policy and other relevant laws (see Retention and Use Period), and then
      destroyed.
      <br />
      c. The personal information will not be used for any purpose other than
      the purpose for which it is held, unless otherwise required by applicable
      laws.
      <br />
      (2) Destruction method
      <br />
      a. Personal information stored in the form of electronic files is
      destroyed using technical methods that make the records unrecoverable.
      <br />
      b. Personal information printed on paper will be destroyed by shredding.
      <br />
      <br />
      5. Measures to ensure the stability of personal information <br />
      The Company takes the following measures to ensure the stability of the
      personal information of the test subjects.
      <br />
      (1) Encryption of personal information The personal information of the
      test subject is protected by a password, and important data is protected
      using other security features, such as encrypting file and transmission
      data or using a file lock function.
      <br />
      (2) Technical measures against hacking, etc. The Company installs security
      programs and periodically updates and inspects them to prevent the leakage
      or damage of personal information of test subjects due to hacking or
      computer viruses.
      <br />
      (3) Restriction of access to the personal information processing system
      The Company's employees who handle personal information are limited to a
      limited number of people and are given separate passwords for this
      purpose, which are changed from time to time.
      <br />
      (4) Training of Personal Information Handling Employees The Company
      conducts regular training for personal information handling employees on
      acquiring new security skills and on their obligations to protect personal
      information.
      <br />
      (5) Access control for unauthorized persons Separate physical storage
      locations for personal information processing systems that store personal
      information and establish and operate access control procedures for them.
      <br />
      <br />
      6. Personal Information Protection Officer <br />
      Test subjects may contact the person in charge of personal information
      protection or the department in charge of personal information protection
      for any complaints related to personal information protection arising from
      using the Company's services. The Company will provide prompt and
      sufficient answers to the test subjects' inquiries.
      <br />
      <br />
      ㆍPrivacy Officer: Misook Kim <br />
      ㆍPhone number : 02-6925-6081 <br />
      ㆍEmail : secure@exbody.com <br />
      <br />
      7. Reporting and counseling on privacy violations <br />
      If you need to report or consult about personal information infringement,
      please contact the following organizations.
      <br />
      <br />
      <table>
        <tr>
          <th>Organizations</th>
          <th>Homepage</th>
          <th>Phone number</th>
        </tr>
        <tr>
          <td>Privacy Reporting Center</td>
          <td>http://privacy.kisa.or.kr/kor/main.jsp</td>
          <td>(without area code) 118</td>
        </tr>
        <tr>
          <td>Privacy Shield Board</td>
          <td>https://www.kopico.go.kr/ </td>
          <td>1833-6972</td>
        </tr>
        <tr>
          <td>Cyber Investigations Division, Office of the Attorney General</td>
          <td>http://www.spo.go.kr/spo/index.jsp</td>
          <td>1301 (without area code)</td>
        </tr>
        <tr>
          <td>National Police Agency Cyber Safety Bureau</td>
          <td>http://cyberbureau.police.go.kr/index.d</td>
          <td>(without area code) 182</td>
        </tr>
      </table>
      <br />
      <br />
      8. Personal Information Processing Obligations <br />
      (1) This Privacy Policy may be changed as necessary, such as changes in
      laws, government policies, or internal company policies, and any
      additions, deletions, or modifications to the contents will be notified
      through the exbody Service homepage at least 7 days prior to the revision.
      <br />
      (2) This Privacy Policy is effective as of September 7, 2023.
      <br />
      <br />
      - Announcement date: September 7, 2023
      <br />- Effective Date: September 7, 2023
    </div>
  );
};

export default Terms3En;
